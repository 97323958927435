<div class="panel-block p-4">
  <div class="is-fullwidth">
    <div class="columns">
      <div class="column is-4 is-offset-4 px-0">
        <div class="level mb-2">
          <div class="lefel-left">
            <div class="level-item">
              <span
                class="is-uppercase has-text-dark has-text-weight-medium is-size-8"
                >Imputations</span
              >
            </div>
          </div>
          <div class="lefel-right">
            <div class="level-item">
              <a (click)="export()" class="button is-link is-small">Exporter</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="columns is-size-8">
      <div class="column is-4 is-offset-4 list--bordered">
        <ul *ngFor="let user of users">
          <li (click)="toggleExpandedUser(user.id)">
            <div class="columns imputation-list">
              <!-- User thumbnail -->
              <div class="column">
                <div class="is-flex is-align-items-center mb-2">
                  <case-image
                    class="avatar avatar-sm"
                    [path]="user.imageObjects[0].image"
                    [title]="user.name"
                  ></case-image>
                  <div
                    class="is-flex is-flex-direction-column is-align-items-flex-start pl-2"
                  >
                    <span>{{ user.name }}</span>
                  </div>
                </div>
              </div>

              <div
                class="column flex is-justify-content-center is-align-items-center has-text-right"
              >
                {{ user.timeSlotCount * 2 }} h
              </div>
              <div
                class="column flex is-justify-content-flex-end is-align-items-center has-text-right"
              >
                <i
                  [ngClass]="{
                    'icon-chevron-down': expandedUserId !== user.id,
                    'icon-chevron-up': expandedUserId === user.id
                  }"
                  class="icon px-3"
                ></i>
              </div>
            </div>

            <div class="columns" *ngIf="expandedUserId === user.id">
              <div class="column">
                <div
                  class="columns imputation-item"
                  *ngFor="let timeSlotDate of user.timeSlotDates"
                >
                  <div class="column is-6 has-text-right">
                    {{ timeSlotDate.date | date }}
                  </div>
                  <div class="column is-1 has-text-right">
                    {{ timeSlotDate.timeSlotCount * 2 }} h
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
