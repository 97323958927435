import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import {
  AuthService,
  BreadcrumbService,
  CaseConfig,
  CaseListComponent,
  EurosPipe,
  Filter,
  FilterService,
  FlashMessageService,
  InputType,
  ResourceDefinition,
  ResourceService
} from '@case-app/angular-library'
import { Chart, ChartData, ChartTooltipItem } from 'chart.js'
import * as moment from 'moment'

import { appConstants } from '../../../../../shared/constants/app.constants'
import { User } from '../../../src/resources/user/user.entity'
import { ChartItem } from '../chart-item.interface'
import { monitoringTurnoverDefinition } from './monitoring-turnover.definition'

@Component({
  selector: 'app-monitoring-turnover',
  templateUrl: './monitoring-turnover.component.html',
  styleUrls: ['./monitoring-turnover.component.scss']
})
export class MonitoringTurnoverComponent
  extends CaseListComponent
  implements OnInit
{
  @ViewChild('chart') canvas: ElementRef
  chart: Chart

  definition: ResourceDefinition = monitoringTurnoverDefinition

  filters: Filter[] = [
    {
      label: 'Date',
      properties: {
        dateFrom: 'dateFrom',
        dateTo: 'dateTo'
      },
      value: {
        dateFrom: moment().subtract(1, 'y').format('YYYY-MM-DD'),
        dateTo: moment().format('YYYY-MM-DD')
      },
      inputType: InputType.DateRange,
      className: 'is-4'
    },
    {
      label: 'Source',
      property: 'source',
      inputType: InputType.Select,
      required: true,
      selectOptions: [
        {
          label: 'Factures (€ TTC)',
          value: 'invoice'
        },
        {
          label: 'Relevés prévisionnels (€ HT)',
          value: 'estimatedStatement'
        },
        {
          label: 'Relevés contradictoires (€ HT)',
          value: 'contradictoryStatement'
        }
      ],
      value: 'invoice',
      className: 'is-4'
    },
    {
      label: `Chargé d'affaire`,
      property: 'businessEngineerId',
      placeholder: `Tous les CA`,
      inputType: InputType.Select,
      className: 'is-4',
      selectOptions: () =>
        this.componentResourceService.listSelectOptions('users', {
          roleNames: [
            appConstants.ROLES.ADMIN_ROLE_NAME,
            appConstants.ROLES.BUSINESS_ENGINEER_ROLE_NAME
          ],
          orderBy: 'name'
        })
    }
  ]

  forcedUser: User

  landmark: number
  chartItems: ChartItem[]
  barChartOptions: any = {
    tooltips: {
      callbacks: {
        title: (item: ChartTooltipItem[], data: ChartData) =>
          moment(this.chartItems[item[0].index].label, 'YY-MM').format(
            'MMMM YYYY'
          ),
        label: (tooltipItem: ChartTooltipItem) =>
          this.eurosPipe.transform(tooltipItem.yLabel as number)
      },
      backgroundColor: '#FFF',
      titleFontSize: 16,
      titleFontColor: '#0066ff',
      bodyFontColor: '#000',
      bodyFontSize: 14,
      displayColors: false
    },
    scales: {
      yAxes: [
        {
          gridLines: {
            borderDash: [8, 4],
            color: '#e9eeff'
          },
          ticks: {
            callback: (value) => this.eurosPipe.transform(value as number),
            fontColor: '#b0bac9',
            beginAtZero: true
          }
        }
      ],
      xAxes: [
        {
          offset: true,
          gridLines: {
            borderDash: [8, 4],
            color: '#e9eeff'
          },
          ticks: {
            callback: (value) => moment(value, 'YY-MM').format('MMMM YYYY'),
            fontColor: '#b0bac9'
          }
        }
      ]
    },
    responsive: true
  }

  constructor(
    resourceService: ResourceService,
    activatedRoute: ActivatedRoute,
    router: Router,
    breadcrumbService: BreadcrumbService,
    flashMessageService: FlashMessageService,
    filterService: FilterService,
    authService: AuthService,
    @Inject('CASE_CONFIG_TOKEN') config: CaseConfig,
    private eurosPipe: EurosPipe,
    private componentResourceService: ResourceService,
    private componentActivatedRoute: ActivatedRoute,
    private componentAuthService: AuthService,
    private componentRouter: Router
  ) {
    super(
      router,
      activatedRoute,
      breadcrumbService,
      resourceService,
      flashMessageService,
      authService,
      filterService,
      config
    )
    moment.locale('fr')
  }

  async ngOnInit() {
    this.componentAuthService.currentUser.subscribe(
      async (currentUser: User) => {
        if (!(await this.componentAuthService.can('browseAnalytics'))) {
          this.forcedUser = currentUser
        }

        const businessEngineerId = FilterService.formatQueryParam(
          this.componentActivatedRoute.snapshot.queryParams.businessEngineerId
        ) as number

        if (this.forcedUser && !businessEngineerId) {
          this.componentRouter.navigate(['/monitoring', 'turnover'], {
            queryParams: {
              businessEngineerId: this.forcedUser.id
            },
            queryParamsHandling: 'merge'
          })
        }

        if (this.forcedUser) {
          const businessEngineerFilter: Filter = this.filters.find(
            (f) => f.property === 'businessEngineerId'
          )
          businessEngineerFilter.readonly = true
        }

        this.chart = new Chart(this.canvas?.nativeElement, {
          type: 'line',
          data: {
            datasets: [],
            labels: []
          },
          options: this.barChartOptions
        })

        await this.initListView()

        this.paginator$.subscribe(async (paginator: any) => {
          this.chartItems = paginator as ChartItem[]

          const businessEngineerId = FilterService.formatQueryParam(
            this.componentActivatedRoute.snapshot.queryParams.businessEngineerId
          ) as number

          const landmark = await this.getLandMark(businessEngineerId)

          // Fix: Allows display of chart values and annotation.
          setTimeout(() => {
            this.chart.data.labels = this.chartItems.map(
              (cI: ChartItem) => cI.label
            )
            this.chart.data.datasets = [
              {
                type: 'line', // Landmark.
                data: this.chartItems.map((cI: ChartItem) => landmark),
                label: `Objectif ${this.eurosPipe.transform(landmark)}`,
                borderColor: '#f0c154',
                borderWidth: 2,
                fill: false,
                pointRadius: 0
              },
              {
                type: 'bar',
                data: this.chartItems.map((cI: ChartItem) => cI.value),
                label: `Chiffre d'affaires`,
                backgroundColor: '#5065ff',
                hoverBackgroundColor: '#5065ff'
              }
            ]
            this.chart.update()
          }, 0)
        })
      }
    )
  }

  async getLandMark(businessEngineerId?: number): Promise<number> {
    if (this.forcedUser) {
      const me: User = await this.componentResourceService.show(
        'users',
        this.forcedUser.id
      )

      return me.minTurnover
    } else if (businessEngineerId) {
      const businessEngineer: User = await this.componentResourceService.show(
        'users',
        businessEngineerId
      )

      return businessEngineer.minTurnover
    }

    const setting = await this.componentResourceService.show('settings', 'get')

    return setting.globalMinTurnover
  }
}
