import { Yield, YieldType } from '@case-app/angular-library'

export const expenseYields: Yield[] = [
  {
    label: 'Libellé',
    property: 'reference'
  },
  {
    label: 'Date',
    property: 'date',
    type: YieldType.Date
  },
  {
    label: 'Montant (HT)',
    property: 'amount',
    type: YieldType.Currency
  }
]
