import { Component, Inject, Input, OnChanges, OnInit } from '@angular/core'
import {
  Action,
  ActionService,
  ActionType,
  CaseConfig,
  Field,
  InputType,
  ResourceDefinition,
  ResourceMode,
  ResourceService,
  Yield,
  YieldType
} from '@case-app/angular-library'
import * as moment from 'moment'

import { StatementType } from '../../../../../../../shared/enums/statement-type.enum'
import { Market } from '../../../../../src/resources/market/market.entity'
import { Project } from '../../../../../src/resources/project/project.entity'
import { Statement } from '../../../../../src/resources/statement/statement.entity'
import { statementDefinition } from '../../../statement/statement.definition'
import { ProjectDetailComponent } from '../project-detail.component'

@Component({
  selector: 'app-project-tab-technical',
  templateUrl: './project-tab-technical.component.html',
  styleUrls: ['./project-tab-technical.component.scss']
})
export class ProjectTabTechnicalComponent implements OnInit, OnChanges {
  @Input() project: Project
  @Input() createContradictoryStatementAction: Action
  @Input() createEstimatedStatementAction: Action
  @Input() downloadLastStatement: boolean

  estimatedStatements: Statement[]
  contradictoryStatements: Statement[]

  statementDefinition: ResourceDefinition = statementDefinition

  statementYields: Yield[] = [
    {
      label: 'Date',
      property: 'date',
      type: YieldType.Date
    },
    {
      label: 'Nom du relevé',
      property: 'title'
    },
    {
      label: 'Marché',
      property: 'market.name',
      link: (s) => `/marches/${s.market.id}`
    },
    {
      label: 'Montant',
      property: 'amount',
      type: YieldType.Currency
    }
  ]

  constructor(
    @Inject('CASE_CONFIG_TOKEN') private config: CaseConfig,
    private resourceService: ResourceService,
    private actionService: ActionService
  ) {}

  ngOnInit(): void {
    this.statementDefinition.dropdownLinks.unshift({
      label: 'Modifier',
      permission: 'editStatements',
      action: (statement) => ({
        type: ActionType.OpenCreateEditModal,
        openCreateEditModal: {
          title: 'Modifier un relevé prévisionnel',
          definition: statementDefinition,
          redirectTo: `/projets/${this.project.id}`,
          redirectToQueryParams: {
            downloadLastStatement: '1'
          },
          mode: ResourceMode.Edit,
          item: statement,
          helpText:
            'Remplissez les champs pour télécharger le relevé. Vous pourrez ensuite entrer le montant calculé.',
          fields: [
            {
              label: 'Projet',
              hidden: true,
              required: true,
              property: 'projectId',
              forcedValue: this.project.id,
              inputType: InputType.Number
            },
            {
              label: 'type',
              hidden: true,
              required: true,
              property: 'type',
              inputType: InputType.Number
            },
            {
              label: 'Nom de relevé',
              property: 'title',
              required: true,
              inputType: InputType.Text,
              className: 'is-12'
            },
            {
              label: 'Date du relevé',
              property: 'date',
              inputType: InputType.Datepicker,
              required: true,
              className: 'is-12'
            },
            {
              label: 'Marché',
              property: 'marketId',
              retrievedItemProperties: {
                marketId: 'market.id'
              },
              inputType: InputType.Select,
              selectOptions: () =>
                this.resourceService.listSelectOptions('markets', {
                  orderBy: 'name'
                }),
              required: true,
              className: 'is-12',
              onChange: async (newValue: number, fields: Field[]) => {
                const market: Market = await this.resourceService
                  .show('markets', newValue)
                  .then((res) => res)

                const seriesField = fields.find((f) => f.id === 'series')

                seriesField.selectOptions =
                  ProjectDetailComponent.getMarketSeries(market)
              }
            },
            {
              id: 'series',
              label: 'Série',
              placeholder: 'Choisir la série',
              property: 'seriesNumber',
              inputType: InputType.Select,
              selectOptions: ProjectDetailComponent.getMarketSeries(
                statement.market
              ),
              required: true,
              className: 'is-12'
            }
          ]
        }
      })
    })
  }

  ngOnChanges(changes): void {
    this.estimatedStatements = this.project.statements.filter(
      (s) => s.type === StatementType.Estimated
    )
    this.contradictoryStatements = this.project.statements.filter(
      (s) => s.type === StatementType.Contradictory
    )

    // We download the last statement file and open a new modal to edit its amount.
    if (changes.project && this.downloadLastStatement) {
      const lastStatement: Statement = this.project.statements.reduce(
        (prev, curr) =>
          moment(prev.updatedAt).isAfter(moment(curr.updatedAt)) ? prev : curr
      )

      if (lastStatement.path) {
        window.open(`${this.config.storagePath}/${lastStatement.path}`)
      }

      this.actionService.triggerAction({
        type: ActionType.OpenCreateEditModal,
        openCreateEditModal: {
          title: `Ajouter un montant pour le relevé ${lastStatement.title}`,
          definition: statementDefinition,
          mode: ResourceMode.Patch,
          item: lastStatement,
          redirectTo: `/projets/${this.project.id}`,
          redirectToQueryParams: {
            downloadLastStatement: '0'
          },
          patchURL: `/statements/${lastStatement.id}/add-amount`,
          fields: [
            {
              label: 'Montant HT',
              property: 'amount',
              inputType: InputType.Number,
              required: true
            }
          ]
        }
      })
    }
  }
}
