import { Component, Input, OnChanges } from '@angular/core'
import { AuthService, Field } from '@case-app/angular-library'
import {
  ActionService,
  ActionType,
  FieldSpecialRule,
  InputType,
  ResourceMode,
  ResourceService
} from '@case-app/angular-library'
import { CalendarEvent } from 'angular-calendar'
import * as moment from 'moment'

import { HourSlot } from '../../../../../../../shared/enums/hour-slot.enum'
import { TimeSlot } from '../../../../../src/resources/time-slot/time-slot.entity'
import { User } from '../../../../../src/resources/user/user.entity'
import { projectDefinition } from '../../../project/project.definition'
import { timeSlotDefinition } from '../../../time-slot/time-slot.definition'

@Component({
  selector: 'app-user-calendar',
  templateUrl: './user-calendar.component.html',
  styleUrls: ['./user-calendar.component.scss']
})
export class UserCalendarComponent implements OnChanges {
  @Input() user: User

  canReadUsers: boolean
  events: CalendarEvent[]
  timeSlots: TimeSlot[]

  viewDate: Date = new Date()
  selectedDate: moment.Moment = moment()
  formRules: FieldSpecialRule[] = []

  constructor(
    private resourceService: ResourceService,
    private actionService: ActionService,
    private authService: AuthService
  ) {}

  async ngOnChanges() {
    this.getTimeSlots()

    this.canReadUsers = await this.authService.can('readUsers')
  }

  getTimeSlots() {
    this.resourceService
      .list('time-slots', {
        userId: this.user.id,
        dateReference: this.selectedDate.format('YYYY-MM-DD')
      })
      .then((res: TimeSlot[]) => {
        this.events = this.formatTimeSlotsIntoCalendarEvents(res)
        this.timeSlots = res
      })
  }

  formatTimeSlotsIntoCalendarEvents(timeSlots: TimeSlot[]): CalendarEvent[] {
    return timeSlots.map((timeSlot: TimeSlot) => {
      return {
        id: timeSlot.id,

        start: new Date(timeSlot.date),
        title: timeSlot.project.number,
        color: {
          primary: timeSlot.project.type.color,
          secondary: timeSlot.project.type.color
        },
        meta: {
          projectId: timeSlot.project && timeSlot.project.id,
          projectNumber: timeSlot.project && timeSlot.project.number,
          date: timeSlot.date,
          hourSlot: timeSlot.hourSlot
        }
      }
    })
  }

  goToMonth(event: Date) {
    this.selectedDate = moment(event)
    this.getTimeSlots()
  }

  openCreateModal(event: {
    date: Date
    events: { meta: { hourSlot: HourSlot } }[]
  }) {
    const hourSlotFields: Field[] = []
    Object.keys(HourSlot)
      .filter((hS) => isNaN(Number(hS)))
      .forEach((hourSlot: string) => {
        if (!event.events.find((e) => e.meta.hourSlot === HourSlot[hourSlot])) {
          hourSlotFields.push({
            label: HourSlot[hourSlot],
            property: hourSlot,
            className: 'is-6',
            initialValue: false,
            inputType: InputType.Checkbox
          })
        }
      })

    if (!hourSlotFields.length) {
      return
    }

    this.actionService.triggerAction({
      type: ActionType.OpenCreateEditModal,
      openCreateEditModal: {
        title: 'Nouvelle imputation',
        definition: timeSlotDefinition,
        mode: ResourceMode.Create,
        redirectTo: `/collaborateurs/${this.user.id}`,
        redirectToQueryParams: {
          selectedTab: 'calendar'
        },
        fields: (
          [
            {
              id: 'userId',
              label: 'userId',
              property: 'userId',
              inputType: InputType.Text,
              forcedValue: this.user.id,
              hidden: true
            },
            {
              id: 'date',
              label: 'date',
              property: 'date',
              inputType: InputType.Text,
              forcedValue: moment(event.date).format('YYYY-MM-DD'),
              hidden: true
            },
            {
              id: 'projectId',
              label: 'Choisir un projet',
              placeholder: 'Projet',
              property: 'projectId',
              inputType: InputType.MultiSearch,
              maxSelectedItems: 1,
              searchResources: [projectDefinition],
              searchParams: {
                onlyActive: true
              },
              className: 'is-12',
              required: true
            },
            {
              label: 'Taux horraire',
              property: 'hourlyRate',
              inputType: InputType.Number,
              permission: 'addTimeSlots',
              className: 'is-12'
            }
          ] as Field[]
        ).concat(hourSlotFields)
      }
    })

    this.formRules = []
  }

  openEditModal(clickEvent: MouseEvent, event: CalendarEvent): void {
    clickEvent.stopPropagation()
    this.formRules = [
      {
        fieldId: 'userId',
        forcedValue: {
          userId: this.user.id
        },
        hidden: true
      },
      {
        fieldId: 'date',
        forcedValue: event.meta.date,
        hidden: true
      }
    ]
  }

  deleteTimeSlot(clickEvent: MouseEvent, event: CalendarEvent) {
    clickEvent.stopPropagation()
    ;(event as any).displayName = `le créneau du ${moment(
      event.meta.date
    ).format('DD/MM/YY')} sur ${event.title}`

    this.actionService.triggerAction({
      type: ActionType.Delete,
      delete: {
        itemToDelete: event,
        definition: timeSlotDefinition,
        redirectTo: `/collaborateurs/${this.user.id}`,
        redirectToQueryParams: {
          selectedTab: 'calendar'
        }
      }
    })
  }
}
