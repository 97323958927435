import { Route } from '@angular/router'
import { AuthGuard, PermissionGuard } from '@case-app/angular-library'
import { MonitoringPaymentForecast } from './monitoring-estimation/monitoring-payment-forecast.component'
import { MonitoringLatenessComponent } from './monitoring-lateness/monitoring-lateness.component'
import { MonitoringProfitabilityComponent } from './monitoring-profitability/monitoring-profitability.component'
import { MonitoringSubtractionComponent } from './monitoring-subtraction/monitoring-subtraction.component'
import { MonitoringTurnoverComponent } from './monitoring-turnover/monitoring-turnover.component'

export const monitoringRoutes: Route[] = [
  {
    path: 'monitoring/turnover',
    component: MonitoringTurnoverComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permissions: ['browseAnalytics', 'browseOwnAnalytics']
    }
  },

  {
    path: 'monitoring/estimation',
    component: MonitoringPaymentForecast,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permissions: ['browseAnalytics', 'browseOwnAnalytics']
    }
  },

  {
    path: 'monitoring/profitability',
    component: MonitoringProfitabilityComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permissions: ['browseAnalytics', 'browseOwnAnalytics']
    }
  },

  {
    path: 'monitoring/subtraction',
    component: MonitoringSubtractionComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permissions: ['browseAnalytics', 'browseOwnAnalytics']
    }
  },

  {
    path: 'monitoring/lateness',
    component: MonitoringLatenessComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permissions: ['browseAnalytics', 'browseOwnAnalytics']
    }
  }
]
