import { Component, Input, OnInit } from '@angular/core'
import { EurosPipe, ResourceService } from '@case-app/angular-library'
import { ChartOptions } from 'chart.js'
import { Color } from 'ng-chartjs'

import { appConstants } from '../../../../../../../shared/constants/app.constants'
import { StatementType } from '../../../../../../../shared/enums/statement-type.enum'
import { Expense } from '../../../../../src/resources/expense/expense.entity'
import { Invoice } from '../../../../../src/resources/invoice/invoice.entity'
import { Project } from '../../../../../src/resources/project/project.entity'
import { TimeSlot } from '../../../../../src/resources/time-slot/time-slot.entity'

@Component({
  selector: 'app-project-time-slot-chart',
  templateUrl: './project-time-slot-chart.component.html',
  styleUrls: ['./project-time-slot-chart.component.scss']
})
export class ProjectTimeSlotChartComponent implements OnInit {
  @Input() project: Project

  timeSlots: TimeSlot[]
  expenses: Expense[]
  invoices: Invoice[]

  pieChartLabels: string[] = ['CA', 'CE', 'Total des charges']
  pieChartData: number[]
  pieChartColors: Color[] = [
    { backgroundColor: ['#825ef6', '#5065ff', '#ffb319'] }
  ]
  pieChartOptions: ChartOptions = {
    responsive: false,
    tooltips: {
      callbacks: {
        label: (tooltipItem, data) =>
          `${data.labels[tooltipItem.index]}: ${this.eurosPipe.transform(
            data.datasets[0].data[tooltipItem.index] as number
          )}`
      }
    }
  }

  businessEngineerCost: number
  studyManagerCost: number

  contradictoryStatementsAmount: number
  totalHours: number
  totalTimeSlotCost: number

  totalExpenses: number
  totalInvoices: number

  constructor(
    private resourceService: ResourceService,
    private eurosPipe: EurosPipe
  ) {}

  async ngOnInit(): Promise<void> {
    this.timeSlots = await this.resourceService
      .list('time-slots', { projectId: this.project.id })
      .then((res) => res)

    this.invoices = await this.resourceService
      .list('invoices', { projectId: this.project.id, withoutPagination: true })
      .then((res) => res)

    // As a timeSlot covers 2 hours we multiple it by 2 to get its equivalent in hours.
    this.businessEngineerCost = this.timeSlots
      .filter(
        (tS: TimeSlot) =>
          // Admin count as BusinessEngineers.
          tS.user.role.name ===
            appConstants.ROLES.BUSINESS_ENGINEER_ROLE_NAME ||
          tS.user.role.name === appConstants.ROLES.ADMIN_ROLE_NAME
      )
      .reduce((sum, curr) => sum + curr.hourlyRate * 2, 0)

    this.studyManagerCost = this.timeSlots
      .filter(
        (tS: TimeSlot) =>
          tS.user.role.name === appConstants.ROLES.STUDY_MANAGER_ROLE_NAME
      )
      .reduce((sum, curr) => sum + curr.hourlyRate * 2, 0)

    this.totalHours = this.timeSlots.length * 2

    this.totalTimeSlotCost = this.timeSlots.reduce(
      (prev, curr) => prev + curr.hourlyRate * 2,
      0
    )

    this.contradictoryStatementsAmount = this.project.statements
      .filter((s) => s.type === StatementType.Contradictory)
      .reduce((sum, curr) => sum + curr.amount, 0)

    this.expenses = await this.resourceService
      .list('expenses', { projectId: this.project.id, withoutPagination: true })
      .then((res) => res)

    this.totalExpenses = this.expenses.reduce(
      (sum: number, e: Expense) => sum + e.amount,
      0
    )

    this.totalInvoices = this.invoices.reduce(
      (sum: number, i: Invoice) => sum + i.amount,
      0
    )

    this.pieChartData = [
      this.businessEngineerCost,
      this.studyManagerCost,
      this.totalExpenses
    ]
  }
}
