import { Gender, LinkType, ResourceDefinition } from '@case-app/angular-library'

export const settingDefinition: ResourceDefinition = {
  title: 'Configuration',
  nameSingular: 'Configuration',
  namePlural: 'Configuration',
  gender: Gender.Feminine,
  mainIdentifier: 'id',
  slug: 'settings',
  path: 'configuration',
  className: 'Setting',
  icon: 'icon-setting',
  hasDetailPage: false,
  hasListPage: false,
  buttons: [],
  defaultLink: LinkType.EDIT,
  childrenThatPreventDelete: [],
  dropdownLinks: []
}
