import { ResourceMode } from '@case-app/angular-library'
import { InputType } from '@case-app/angular-library'
import { ActionType } from '@case-app/angular-library'
import { ProjectStatus } from '../../../../../shared/enums/project-status.enum'
import { Project } from '../../../src/resources/project/project.entity'
import { projectDefinition } from './project.definition'

export const editStatus = (project: Project) => ({
  type: ActionType.OpenCreateEditModal,
  openCreateEditModal: {
    title: `Modifier le statut du projet ${project.label}`,
    definition: projectDefinition,
    mode: ResourceMode.Patch,
    patchURL: `/projects/${project.id}/status`,
    item: project,
    fields: [
      {
        id: 'status',
        label: ' Statut du projet',
        property: 'status',
        inputType: InputType.Select,
        required: true,
        selectOptions: Object.values(ProjectStatus).map((projectStatus) => ({
          label: projectStatus,
          value: projectStatus
        }))
      }
    ]
  }
})
