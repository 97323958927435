import { Yield, YieldType } from '@case-app/angular-library'

export const customerYields: Yield[] = [
  {
    label: 'Nom',
    property: 'name'
  },
  {
    label: 'Adresse',
    property: 'address'
  },
  {
    label: 'Groupe',
    property: 'corporateGroup.name'
  }
]
