<nav class="level">
  <!-- Left side -->
  <div class="level-left">
    <div class="level-item">
      <span class="icon is-large">
        <i class="icon icon-bar-chart is-size-2 has-text-link"></i>
      </span>
    </div>
    <div class="level-item">
      <h1 class="title is-2 has-text-weight-light">Monitoring</h1>
    </div>
  </div>
</nav>
<!-- Filters -->
<section>
  <div class="card mb-6 mt-4">
    <app-monitoring-tabs [selectedTab]="'subtraction'"></app-monitoring-tabs>
    <div class="p-4">
      <div class="columns">
        <div class="column">
          <h2
            class="title is-6 has-text-weight-bold is-uppercase has-text-grey"
          >
            Filtres
          </h2>
        </div>
      </div>
      <div
        class="columns is-multiline is-align-items-flex-end"
        *ngIf="isFilterSelectOptionsFetched"
      >
        <div
          class="column"
          [ngClass]="filter.className"
          *ngFor="let filter of filters"
        >
          <case-input
            [type]="filter.inputType"
            [initialValue]="filter.value"
            [selectOptions]="filter.selectOptions"
            [readonly]="filter.readonly"
            [label]="filter.label"
            [searchResources]="filter.searchResources"
            [required]="filter.required"
            [placeholder]="filter.placeholder"
            (valueChanged)="onFilterValueChanged($event, filter)"
          ></case-input>
        </div>
      </div>
    </div>
  </div>
</section>

<div class="columns">
  <div class="column is-3">
    <div
      class="card p-4 is-fullheight is-flex is-align-item-center is-justify-content-flex-start"
    >
      <div>
        <i
          class="icon icon--big icon-percent has-text-success is-size-4 mb-4"
        ></i>
        <h2 class="title is-2">{{ total | euros }}</h2>
        <h4 class="title is-6 has-text-weight-bold is-uppercase has-text-grey">
          Montant total
        </h4>
      </div>
    </div>
  </div>
  <div class="column is-9">
    <div class="card p-4 is-fullheight">
      <div style="display: block">
        <h2 class="title is-6 has-text-weight-bold is-uppercase has-text-grey">
          RC sans PVR
        </h2>
        <p>Pour chaque projet : Σ RC - Σ PVR</p>

        <div style="display: block">
          <canvas
            ngChartjs
            [data]="barChartData"
            [labels]="barChartLabels"
            [options]="barChartOptions"
            chartType="horizontalBar"
            [legend]="false"
            *ngIf="barChartData"
            (chartClick)="onChartClick($event)"
          >
          </canvas>
        </div>
      </div>
    </div>
  </div>
</div>
