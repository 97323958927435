import {
  ActionType,
  Gender,
  LinkType,
  ResourceDefinition
} from '@case-app/angular-library'

export const invoiceDefinition: ResourceDefinition = {
  title: 'Factures',
  nameSingular: 'facture',
  namePlural: 'factures',
  gender: Gender.Feminine,
  mainIdentifier: 'number',
  slug: 'invoices',
  path: 'factures',
  className: 'Invoice',
  icon: 'icon-file-text',
  hasDetailPage: false,
  hasListPage: true,
  buttons: [LinkType.CREATE, LinkType.EXPORT],
  defaultLink: null,
  childrenThatPreventDelete: [],
  dropdownLinks: [
    {
      label: 'Supprimer',
      permission: 'deleteInvoices',
      action: (invoice) => ({
        type: ActionType.Delete,
        delete: {
          itemToDelete: invoice,
          definition: invoiceDefinition
        }
      })
    }
  ],
  keyNumbers: [
    {
      label: 'Montant total facturé TTC',
      extraParams: {
        calculate: 'totalAmounts'
      }
    }
  ]
}
