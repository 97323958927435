import { Component, OnInit } from '@angular/core'
import { FormBuilder } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import {
  BreadcrumbService,
  CaseCreateEditComponent,
  caseCreateEditTemplate,
  Field,
  FlashMessageService,
  InputType,
  ResourceDefinition,
  ResourceService
} from '@case-app/angular-library'

import { customerDefinition } from '../../customer/customer.definition'
import { referentDefinition } from '../referent.definition'

@Component({ template: caseCreateEditTemplate })
export class ReferentCreateEditComponent
  extends CaseCreateEditComponent
  implements OnInit
{
  definition: ResourceDefinition = referentDefinition
  fields: Field[] = [
    {
      label: 'Referent name',
      placeholder: 'Enter referent name...',
      property: 'name',
      inputType: InputType.Text,
      required: true
    },
    {
      id: 'client',
      label: 'Client',
      placeholder: `Associer un client ...`,
      property: 'customerId',
      retrievedItemProperties: {
        customerId: 'customer.id'
      },
      searchResources: [customerDefinition],
      maxSelectedItems: 1,
      required: true,
      inputType: InputType.MultiSearch,
      className: 'is-6'
    }
  ]

  constructor(
    formBuilder: FormBuilder,
    router: Router,
    activatedRoute: ActivatedRoute,
    resourceService: ResourceService,
    breadcrumbService: BreadcrumbService,
    flashMessageService: FlashMessageService
  ) {
    super(
      formBuilder,
      router,
      breadcrumbService,
      resourceService,
      flashMessageService,
      activatedRoute
    )
  }

  ngOnInit() {
    this.initCreateEditView()
  }
}
