<ng-container *ngIf="item">
  <!-- Main container -->
  <nav class="level">
    <!-- Left side -->
    <div class="level-left">
      <div class="level-item">
        <span class="icon is-large">
          <i class="icon icon-grid is-size-2 has-text-link"></i>
        </span>
      </div>
      <div class="level-item">
        <h1 class="title is-2 has-text-weight-light">{{ item.number }}</h1>
        <span class="tag is-dark" *ngIf="!item.isActive">Inactif</span>
      </div>
    </div>

    <!-- Right side -->
    <div class="level-right">
      <div class="level-item">
        <div class="dropdown is-active" #createMenu>
          <div class="dropdown-trigger">
            <button
              class="button is-link-light"
              aria-haspopup="true"
              aria-controls="dropdown-menu"
              (click)="showCreateMenu = !showCreateMenu"
            >
              <span>Générer</span>
              <span class="icon is-small">
                <i class="icon icon-chevron-down" aria-hidden="true"></i>
              </span>
            </button>
          </div>
          <div
            class="dropdown-menu"
            id="dropdown-menu"
            role="menu"
            *ngIf="showCreateMenu"
          >
            <div class="dropdown-content">
              <a
                class="dropdown-item"
                *caseHasPermission="'addStatements'; addOwnStatements"
                [caseAction]="createEstimatedStatementAction"
                >Un relevé prévisionnel
              </a>
              <a
                class="dropdown-item"
                [caseAction]="createContradictoryStatementAction"
                *caseHasPermission="'addStatements'; addOwnStatements"
                >Un relevé contradictoire
              </a>
              <a
                routerLink="/factures/create"
                [queryParams]="createRelationForProjectQueryParams"
                *caseHasPermission="'addStatements'; addOwnStatements"
                class="dropdown-item"
              >
                Une facture</a
              >
              <a
                [caseAction]="createAcceptanceReportAction"
                class="dropdown-item"
                >Un PV de réception
              </a>
              <a
                (click)="showProjectFrameworkModal = true"
                class="dropdown-item"
                >Un dossier .zip</a
              >
            </div>
          </div>
        </div>
      </div>
      <p
        class="level-item"
        *caseHasPermission="'editProjects'; editOwnProjects"
      >
        <a
          class="button is-link px-5"
          [routerLink]="['/projets', item.id, 'edit']"
        >
          Modifier
        </a>
      </p>
    </div>
  </nav>
  <div class="columns is-multiline">
    <div class="column is-9">
      <div class="card is-fullheight">
        <div class="card-content">
          <div class="columns is-multiline">
            <div class="column is-8">
              <div class="columns is-multiline">
                <div class="column is-6">
                  <p class="is-uppercase has-text-grey has-text-weight-medium">
                    Adresse
                  </p>
                  <p>
                    <case-address-yield
                      [stringAddress]="item.address"
                    ></case-address-yield>
                  </p>
                </div>
                <div class="column is-6">
                  <p class="is-uppercase has-text-grey has-text-weight-medium">
                    Type
                  </p>
                  <p>{{ item.type.name }}</p>
                </div>

                <div class="column is-6">
                  <p class="is-uppercase has-text-grey has-text-weight-medium">
                    Total des factures
                  </p>
                  <p>{{ item.invoiceAmount | euros }} TTC</p>
                </div>
                <div class="column is-6">
                  <p class="is-uppercase has-text-grey has-text-weight-medium">
                    Quantitatif
                  </p>
                  <p>{{ item.cableLength }}</p>
                </div>
                <div class="column is-6">
                  <p class="is-uppercase has-text-grey has-text-weight-medium">
                    Date de création
                  </p>
                  <p>{{ item.startDate | date }}</p>
                </div>
                <div class="column is-6">
                  <p class="is-uppercase has-text-grey has-text-weight-medium">
                    Date de rendu souhaitée / réelle
                  </p>
                  <p>
                    <span
                      [ngClass]="{
                        'has-text-danger':
                          !item.endDate && item.daysOfLateness >= 1
                      }"
                    >
                      {{ item.estimatedEndDate | date }} /
                    </span>
                    <span
                      *ngIf="item.endDate"
                      [ngClass]="{
                        'has-text-danger': item.daysOfLateness >= 1
                      }"
                    >
                      {{ item.endDate | date }}
                    </span>
                    <span *ngIf="!item.endDate">-</span>
                  </p>
                </div>
              </div>
            </div>
            <div class="column is-4">
              <div class="columns is-multiline">
                <div class="column is-12" [caseAction]="editStatus">
                  <p class="is-uppercase has-text-grey has-text-weight-medium">
                    Statut
                  </p>
                  <app-status-bar
                    [status]="item.status"
                    [statusNumber]="item.statusNumber"
                    [showStatusName]="true"
                  ></app-status-bar>
                </div>
                <div class="column is-12">
                  <p class="is-uppercase has-text-grey has-text-weight-medium">
                    Membres affectés au projet
                  </p>
                  <div>
                    <div
                      class="is-flex is-align-items-center mb-2"
                      [routerLink]="[
                        '/collaborateurs/',
                        item.businessEngineer.id
                      ]"
                    >
                      <case-image
                        class="avatar avatar-sm"
                        [path]="item.businessEngineer.imageObjects[0].image"
                        [title]="item.businessEngineer.name"
                      ></case-image>
                      <div
                        class="is-flex is-flex-direction-column is-align-items-flex-start pl-2"
                      >
                        <span>{{ item.businessEngineer.name }}</span>
                        <strong>CA</strong>
                      </div>
                    </div>
                    <div
                      class="is-flex is-align-items-center mb-2"
                      *ngIf="item.studyManager"
                      [routerLink]="['/collaborateurs/', item.studyManager.id]"
                    >
                      <case-image
                        class="avatar avatar-sm"
                        [path]="item.studyManager.imageObjects[0].image"
                        [title]="item.studyManager.name"
                      ></case-image>
                      <div
                        class="pl-2 is-flex is-flex-direction-column is-align-items-flex-start"
                      >
                        <span>{{ item.studyManager.name }}</span>
                        <strong>CE</strong>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div class="columns is-multiline">
            <div class="column is-4">
              <p class="is-uppercase has-text-grey has-text-weight-medium">
                Informations client
              </p>
              <p>{{ item.customer.name }} - {{ item.externalId }}</p>
            </div>
            <div class="column is-8">
              <p class="is-uppercase has-text-grey has-text-weight-medium">
                Responsables
              </p>
              <p>
                <a
                  class="coma-list"
                  routerLink="/projets"
                  [queryParams]="{ referentIds: item.referent1.id }"
                  >{{ item.referent1.name }}</a
                >
                <a
                  class="coma-list"
                  routerLink="/projets"
                  [queryParams]="{ referentIds: item.referent2.id }"
                  *ngIf="item.referent2"
                  >{{ item.referent2.name }}</a
                >
                <a
                  class="coma-list"
                  routerLink="/projets"
                  [queryParams]="{ referentIds: item.referent3.id }"
                  *ngIf="item.referent3"
                  >{{ item.referent3.name }}</a
                >
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="column is-3">
      <div class="card is-fullheight">
        <div class="card-content">
          <app-project-time-slot-chart
            [project]="item"
          ></app-project-time-slot-chart>
        </div>
      </div>
    </div>
  </div>

  <div class="columns is-multiline">
    <div class="column is-12 mt-4">
      <div class="panel is-link has-background-light">
        <p class="panel-tabs pt-2">
          <a
            [ngClass]="{ 'is-active': selectedTab === 'technical' }"
            (click)="selectedTab = 'technical'"
            >Technique</a
          >
          <a
            [ngClass]="{ 'is-active': selectedTab === 'accounting' }"
            (click)="selectedTab = 'accounting'"
            *caseHasPermission="{
              requireOr: [
                'browseInvoices',
                'browseExpenses',
                'browseOrders',
                'browseAcceptanceReports'
              ]
            }"
            >Financier</a
          >
          <a
            [ngClass]="{ 'is-active': selectedTab === 'timeSlots' }"
            (click)="selectedTab = 'timeSlots'"
            >Imputation</a
          >
        </p>
        <div class="tabs-content">
          <app-project-tab-technical
            [project]="item"
            [createContradictoryStatementAction]="
              createContradictoryStatementAction
            "
            [createEstimatedStatementAction]="createEstimatedStatementAction"
            [downloadLastStatement]="downloadLastStatement"
            *ngIf="selectedTab === 'technical'"
          ></app-project-tab-technical>
          <app-project-tab-accounting
            [project]="item"
            [createAcceptanceReportAction]="createAcceptanceReportAction"
            [createRelationForProjectQueryParams]="
              createRelationForProjectQueryParams
            "
            *ngIf="selectedTab === 'accounting'"
          ></app-project-tab-accounting>
          <app-project-tab-time-slots
            [project]="item"
            *ngIf="selectedTab === 'timeSlots'"
          ></app-project-tab-time-slots>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<app-project-framework-modal
  [project]="item"
  [isOnboarding]="isOnboarding"
  *ngIf="showProjectFrameworkModal && item"
  (onClose)="showProjectFrameworkModal = false"
></app-project-framework-modal>
