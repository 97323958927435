import { Component, Input } from '@angular/core'
import { ProjectStatus } from './../../../../../shared/enums/project-status.enum'

@Component({
  selector: 'app-status-bar',
  templateUrl: './status-bar.component.html',
  styleUrls: ['./status-bar.component.scss']
})
export class StatusBarComponent {
  @Input() status: ProjectStatus
  @Input() showStatusName = false
  @Input() statusNumber
}
