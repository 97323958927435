<ng-container *ngIf="item">
  <!-- Main container -->
  <nav class="level">
    <!-- Left side -->
    <div class="level-left">
      <div class="level-item">
        <span class="icon is-large">
          <i class="icon icon-pie-chart is-size-2 has-text-link"></i>
        </span>
      </div>
      <div class="level-item">
        <h1 class="title is-2 has-text-weight-light">{{ item.name }}</h1>
      </div>
    </div>

    <!-- Right side -->
    <div class="level-right">
      <p class="level-item">
        <a
          class="button is-link px-5"
          [routerLink]="['/marches', item.id, 'edit']"
        >
          Modifier
        </a>
      </p>
    </div>
  </nav>
  <div class="columns is-multiline">
    <div class="column is-9">
      <div class="card is-fullheight">
        <div class="card-content">
          <div class="columns is-multiline">
            <div class="column is-4">
              <p class="is-uppercase has-text-grey has-text-weight-medium">
                Budget total
              </p>
              <p>{{ item.budget | euros }} HT</p>
            </div>
            <div class="column is-4">
              <p class="is-uppercase has-text-grey has-text-weight-medium">
                Montant restant à commander
              </p>
              <p>{{ item.budget - item.orderAmount | euros }} HT</p>
            </div>
            <div class="column is-4">
              <p class="is-uppercase has-text-grey has-text-weight-medium">
                Montant consommé
              </p>
              <p>
                {{
                  item.budget -
                    (item.contradictoryStatementsAmount +
                      item.estimatedStatementsAmount) | euros
                }}
                HT
              </p>
            </div>
          </div>
          <hr />
          <div class="columns is-multiline">
            <div class="column is-4">
              <p class="is-uppercase has-text-grey has-text-weight-medium">
                Description
              </p>
              <p>{{ item.description }}</p>
            </div>
            <div class="column is-4">
              <p class="is-uppercase has-text-grey has-text-weight-medium">
                Date de début
              </p>
              <p>{{ item.startDate | date }}</p>
            </div>
            <div class="column is-4">
              <p class="is-uppercase has-text-grey has-text-weight-medium">
                Date de fin
              </p>
              <p>{{ item.endDate | date }}</p>
            </div>
          </div>
          <hr />
          <div class="columns is-multiline">
            <div class="column is-4">
              <p class="is-uppercase has-text-grey has-text-weight-medium">
                Série 1
              </p>
              <p>{{ item.service1Name }}</p>
              <p>Prix du point: {{ item.service1Price | euros }} HT</p>
            </div>
            <div class="column is-4">
              <p class="is-uppercase has-text-grey has-text-weight-medium">
                Série 2
              </p>
              <p>{{ item.service2Name }}</p>
              <p>Prix du point: {{ item.service2Price | euros }} HT</p>
            </div>
            <div class="column is-4">
              <p class="is-uppercase has-text-grey has-text-weight-medium">
                Série 3
              </p>
              <p>{{ item.service3Name }}</p>
              <p>Prix du point: {{ item.service3Price | euros }} HT</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="column is-3">
      <div class="card is-fullheight">
        <div class="card-content">
          <div class="columns">
            <div class="column">
              <p class="is-uppercase has-text-grey has-text-weight-medium">
                Clients
              </p>
              <p *ngFor="let customer of item.customers">
                <a
                  [routerLink]="['/projets']"
                  [queryParams]="{ customerIds: customer.id }"
                  >{{ customer.name }}</a
                >
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="columns">
    <div class="column is-12">
      <div class="card is-fullheight">
        <div class="card-content">
          <div class="columns">
            <div class="column">
              <p class="is-uppercase has-text-weight-medium mb-3">Montants</p>
              <div class="columns is-multiline">
                <div class="column is-3">
                  <p class="is-uppercase has-text-grey has-text-weight-medium">
                    Somme des RP
                  </p>
                  <p>{{ item.estimatedStatementsAmount | euros }} HT</p>
                </div>
                <div class="column is-2">
                  <p class="is-uppercase has-text-grey has-text-weight-medium">
                    Somme des RC
                  </p>
                  <p>{{ item.contradictoryStatementsAmount | euros }} HT</p>
                </div>
                <div class="column is-2">
                  <p class="is-uppercase has-text-grey has-text-weight-medium">
                    Somme des commandes
                  </p>
                  <p>{{ item.orderAmount | euros }} HT</p>
                </div>
                <div class="column is-2">
                  <p class="is-uppercase has-text-grey has-text-weight-medium">
                    Somme des factures
                  </p>
                  <p>{{ item.invoiceAmount | euros }} HT</p>
                </div>
                <div class="column is-3">
                  <p class="is-uppercase has-text-grey has-text-weight-medium">
                    Somme des PVRs
                  </p>
                  <p>{{ item.acceptanceReportAmount | euros }} HT</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
