import { Route } from '@angular/router'

import {
  AuthGuard,
  PermissionGuard,
  ResourceMode
} from '@case-app/angular-library'

import { MarketCreateEditComponent } from './market-create-edit/market-create-edit.component'
import { MarketDetailComponent } from './market-detail/market-detail.component'
import { MarketListComponent } from './market-list/market-list.component'

export const marketRoutes: Route[] = [
  {
    path: 'marches',
    component: MarketListComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permission: 'browseMarkets'
    }
  },
  {
    path: 'marches/create',
    component: MarketCreateEditComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permission: 'addMarkets',
      mode: ResourceMode.Create
    }
  },
  {
    path: 'marches/:id/edit',
    component: MarketCreateEditComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      mode: ResourceMode.Edit,
      permission: 'editMarkets'
    }
  },
  {
    path: 'marches/:id',
    component: MarketDetailComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permission: 'readMarkets'
    }
  }
]
