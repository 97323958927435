import {
  ActionType,
  EurosPipe,
  InputType,
  ResourceMode,
  Yield,
  YieldType
} from '@case-app/angular-library'
import * as moment from 'moment'

import { Invoice } from '../../../src/resources/invoice/invoice.entity'
import { invoiceDefinition } from './invoice.definition'

export const invoiceYields: Yield[] = [
  {
    label: 'N°',
    property: 'number'
  },
  {
    label: 'Date',
    property: 'issueDate',
    type: YieldType.Date
  },
  {
    label: 'Echéance',
    property: 'dueDate',
    disableOrderBy: true,
    type: YieldType.Date
  },
  {
    label: 'N° de projet',
    property: 'project.number',
    link: (i) => `/projets/${i.project.id}`
  },

  {
    label: 'Client',
    property: 'project.customer.name',
    orderByProperty: 'customer.name',
    link: (i) => `/factures?customerIds=${i.project.customer.id}`
  },
  {
    label: 'PVR',
    orderByProperty: 'acceptanceReport.number',
    property: 'acceptanceReportNumbers'
  },
  {
    label: 'Adresse',
    type: YieldType.Address,
    property: 'project.address'
  },
  {
    label: 'Montant HT',
    property: 'amount',
    type: YieldType.Currency
  },
  {
    label: 'TVA',
    property: 'taxes',
    type: YieldType.Currency
  },
  {
    label: 'Montant TTC',
    property: 'amountWithTaxes',
    orderByProperty: 'amount',
    type: YieldType.Currency
  },
  {
    label: 'Paiement',
    property: 'payedAt',
    secondProperty: 'payedAtFormatted',
    type: YieldType.Switch,
    action: (invoice: Invoice) => ({
      type: ActionType.OpenCreateEditModal,
      openCreateEditModal: {
        title: `Attribuer un réglement pour la facture N° ${invoice.number}`,
        definition: invoiceDefinition,
        mode: ResourceMode.Patch,
        patchURL: `/invoices/${invoice.id}/pay`,
        item: invoice,
        keyPoints: [
          {
            label: 'Montant TTC',
            value: new EurosPipe().transform(invoice.amountWithTaxes)
          },
          {
            label: 'Projet concerné',
            value: invoice.project.number
          }
        ],
        fields: [
          {
            label: 'Date du réglement',
            property: 'payedAt',
            inputType: InputType.Datepicker,
            initialValue: moment().format('YYYY-MM-DD'),
            helpText: `Pour supprimer un paiement, veuillez supprimer la date pour laisser le champ vide. Enregistrez le paiement pour terminer l'opération.`
          }
        ]
      }
    })
  },
  {
    label: `CA SVL`,
    property: 'userImages',
    secondProperty: 'name',
    orderByProperty: 'businessEngineer.name',
    type: YieldType.Image,
    link: (i: Invoice) =>
      `/collaborateurs/${i.project.businessEngineer.id}?selectedTab=calendar`
  },
  {
    label: 'Télécharger',
    property: 'path',
    type: YieldType.Download
  }
]
