<div class="panel-block has-v-separation p-4">
  <div class="is-fullwidth">
    <div class="columns is-size-8">
      <div class="column is-9">
        <nav class="level mb-2">
          <!-- Left side -->
          <div class="level-left">
            <div class="level-item">
              <span class="icon-text">
                <span class="is-uppercase has-text-dark has-text-weight-medium">
                  Relevés prévisionnels
                </span>
                <span class="icon">
                  <i class="icon icon-plus has-text-grey"></i>
                </span>
                <a [caseAction]="createEstimatedStatementAction"
                  >Ajouter un RP</a
                >
              </span>
            </div>
          </div>

          <!-- Right side -->
          <div class="level-right">
            <p class="level-item">
              <span class="tag is-link-light">
                Total des RP (HT):
                {{ project.estimatedStatementsAmount | euros }}</span
              >
            </p>
          </div>
        </nav>

        <case-table
          [items]="estimatedStatements"
          [definition]="statementDefinition"
          [yields]="statementYields"
        ></case-table>
        <hr class="mt-0" />
        <nav class="level mb-2">
          <!-- Left side -->
          <div class="level-left">
            <div class="level-item">
              <span class="icon-text">
                <span class="is-uppercase has-text-dark has-text-weight-medium">
                  Relevés contradictoires
                </span>
                <span class="icon">
                  <i class="icon icon-plus has-text-grey"></i>
                </span>
                <a [caseAction]="createContradictoryStatementAction"
                  >Ajouter un RC</a
                >
              </span>
            </div>
          </div>

          <!-- Right side -->
          <div class="level-right">
            <p class="level-item">
              <span class="tag is-link-light"
                >Total des RC (HT):
                {{ project.contradictoryStatementsAmount | euros }}</span
              >
            </p>
          </div>
        </nav>

        <case-table
          [items]="contradictoryStatements"
          [definition]="statementDefinition"
          [yields]="statementYields"
        ></case-table>
      </div>
      <div class="column is-3">
        <div>
          <span class="icon-text pt-1">
            <span class="is-uppercase has-text-dark has-text-weight-medium">
              Commentaire technique
            </span>
            <span class="icon">
              <i class="icon icon-edit has-text-grey"></i>
            </span>
            <a
              [routerLink]="['/projets', project.id, 'edit']"
              fragment="technicalComment"
              >Modifier</a
            >
          </span>
        </div>
        <div *ngIf="project.technicalComment">
          <div class="message mt-2 is-small">
            <div
              class="message-body"
              [innerHtml]="project.technicalComment"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
