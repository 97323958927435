import { Component, Inject, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import {
  AuthService,
  BreadcrumbService,
  CaseConfig,
  CaseListComponent,
  caseListTemplate,
  Filter,
  FilterService,
  FlashMessageService,
  InputType,
  ResourceDefinition,
  ResourceService,
  Yield
} from '@case-app/angular-library'

import { appConstants } from '../../../../../../shared/constants/app.constants'
import { ProjectStatus } from '../../../../../../shared/enums/project-status.enum'
import { corporateGroupDefinition } from '../../corporate-group/corporate-group.definition'
import { customerDefinition } from '../../customer/customer.definition'
import { localityDefinition } from '../../locality/locality.definition'
import { referentDefinition } from '../../referent/referent.definition'
import { projectDefinition } from '../project.definition'
import { projectYields } from '../project.yields'

@Component({ template: caseListTemplate })
export class ProjectListComponent extends CaseListComponent implements OnInit {
  definition: ResourceDefinition = projectDefinition
  yields: Yield[] = projectYields

  filters: Filter[] = [
    {
      label: 'Rechercher un projet',
      placeholder:
        'Rechercher par N° de projet (interne ou client), par commune, client, groupe de client, ou responsable...',
      inputType: InputType.MultiSearch,
      className: 'is-6',
      searchResources: [
        projectDefinition,
        referentDefinition,
        customerDefinition,
        localityDefinition,
        corporateGroupDefinition
      ],
      properties: {
        projectIds: 'projectIds',
        referentIds: 'referentIds',
        customerIds: 'customerIds',
        localityIds: 'localityIds',
        corporateGroupIds: 'corporateGroupIds'
      }
    },
    {
      label: 'Type de projet',
      placeholder: 'Tous les projets',
      inputType: InputType.Select,
      property: 'typeId',
      className: 'is-3',
      selectOptions: () =>
        this.componentResourceService.listSelectOptions('types', {
          orderBy: 'name'
        })
    },
    {
      label: 'Statut',
      property: 'statuses',
      placeholder: 'Tous les statuts',
      inputType: InputType.MultiSelect,
      className: 'is-3',
      selectOptions: Object.values(ProjectStatus).map((projectStatus) => ({
        label: projectStatus,
        value: projectStatus
      }))
    },
    {
      label: `Chargé d'affaire SVL`,
      property: 'businessEngineerIds',
      placeholder: `Tous les chargés d'affaire`,
      inputType: InputType.MultiSelect,
      className: 'is-3',
      selectOptions: () =>
        this.componentResourceService.listSelectOptions('users', {
          roleNames: [
            appConstants.ROLES.ADMIN_ROLE_NAME,
            appConstants.ROLES.BUSINESS_ENGINEER_ROLE_NAME
          ],
          includeNotActive: true,
          orderBy: 'name'
        })
    },
    {
      label: `Chargé d'étude SVL`,
      property: 'studyManagerIds',
      placeholder: `Tous les chargés d'étude`,
      inputType: InputType.MultiSelect,
      className: 'is-3',
      selectOptions: () =>
        this.componentResourceService.listSelectOptions('users', {
          roleNames: [
            appConstants.ROLES.STUDY_MANAGER_ROLE_NAME,
            appConstants.ROLES.BUSINESS_ENGINEER_ROLE_NAME
          ],
          includeNotActive: true,
          orderBy: 'name'
        })
    },
    {
      label: 'Projets à facturer',
      inputType: InputType.Checkbox,
      property: 'toBillOnly'
    },
    {
      label: 'Projets sans RC',
      inputType: InputType.Checkbox,
      property: 'withoutContradictoryStatement'
    },
    {
      label: 'Projets avec RC sans PVR',
      inputType: InputType.Checkbox,
      property: 'withContradictoryStatementThatHaveNoAR'
    },
    {
      label: 'Inclure les projets inactifs',
      inputType: InputType.Checkbox,
      property: 'includeNotActive'
    }
  ]

  constructor(
    router: Router,
    activatedRoute: ActivatedRoute,
    resourceService: ResourceService,
    breadcrumbService: BreadcrumbService,
    flashMessageService: FlashMessageService,
    filterService: FilterService,
    authService: AuthService,
    private componentResourceService: ResourceService,

    @Inject('CASE_CONFIG_TOKEN') config: CaseConfig
  ) {
    super(
      router,
      activatedRoute,
      breadcrumbService,
      resourceService,
      flashMessageService,
      authService,
      filterService,
      config
    )
  }

  ngOnInit() {
    this.initListView()
  }
}
