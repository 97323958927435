import { Route } from '@angular/router'

import {
  AuthGuard,
  PermissionGuard,
  ResourceMode
} from '@case-app/angular-library'

import { ReferentCreateEditComponent } from './referent-create-edit/referent-create-edit.component'
import { ReferentListComponent } from './referent-list/referent-list.component'

export const referentRoutes: Route[] = [
  {
    path: 'responsables',
    component: ReferentListComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permission: 'browseReferents'
    }
  },
  {
    path: 'responsables/create',
    component: ReferentCreateEditComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permission: 'addReferents',
      mode: ResourceMode.Create
    }
  },
  {
    path: 'responsables/:id/edit',
    component: ReferentCreateEditComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      mode: ResourceMode.Edit,
      permission: 'editReferents'
    }
  }
]
