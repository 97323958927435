export enum ProjectStatus {
  ToAssign = 'A affecter',
  InProgress = 'En cours',
  BusinessEngineeringFinished = 'CE terminé',
  Waiting = 'En attente',
  Sent = 'Projet envoyé',
  ContradictoryStatementSent = 'RC envoyé',
  WaitingToBilling = 'A facturer',
  Billed = 'Facturé',
  Payed = 'Payé'
}
