<ng-container *ngIf="item">
  <!-- Main container -->
  <nav class="level mb-2">
    <!-- Left side -->
    <div class="level-left">
      <div class="level-item">
        <span class="icon is-large">
          <i class="icon icon-box is-size-2 has-text-link"></i>
        </span>
      </div>
      <div class="level-item">
        <h1 class="title is-2 has-text-weight-light">{{ item.number }}</h1>
        <h2 class="pl-6 title is-2 has-text-weight-light has-text-grey-dark">
          {{ item.amount | euros }}
        </h2>
      </div>
    </div>

    <!-- Right side -->
    <div class="level-right">
      <p class="level-item" *ngIf="!item.acceptanceReports.length">
        <a class="button is-light px-5" [caseAction]="deleteOrderAction"
          >Supprimer
        </a>
      </p>

      <p class="level-item">
        <a
          class="button is-link-light px-5"
          [caseAction]="createAcceptanceReportAction"
          >+ PVR
        </a>
      </p>

      <p class="level-item">
        <a
          class="button is-link-light px-5"
          [routerLink]="['/factures', 'create']"
          [queryParams]="{
            specialRules: createInvoiceForProjectSpecialRules
          }"
          >+ Facture
        </a>
      </p>

      <p class="level-item">
        <a
          class="button is-link px-5"
          [routerLink]="['/commandes', item.id, 'edit']"
        >
          Modifier
        </a>
      </p>
    </div>
  </nav>
  <div class="mb-4">
    <span class="icon-text mr-4">
      <span class="icon">
        <i class="icon icon-calendar has-text-grey"></i>
      </span>
      <span>{{ item.date | date }}</span>
    </span>
    <span class="icon-text">
      <span class="icon">
        <i class="icon icon-activity has-text-grey"></i>
      </span>
      <a [routerLink]="['/marches', item.market.id]">{{ item.market.name }}</a>
    </span>
  </div>
  <div class="columns is-multiline">
    <div class="column is-12">
      <div class="card is-fullheight">
        <div class="card-content">
          <div class="columns is-multiline">
            <div class="column is-3">
              <div class="columns is-multiline">
                <div class="column is-12">
                  <p class="is-uppercase has-text-grey has-text-weight-medium">
                    Projet
                  </p>
                  <p>{{ item.project.number }}</p>
                </div>
                <div class="column is-12">
                  <p class="is-uppercase has-text-grey has-text-weight-medium">
                    Date de début
                  </p>
                  <p>{{ item.project.startDate | date }}</p>
                </div>
              </div>
            </div>
            <div class="column is-3">
              <p class="is-uppercase has-text-grey has-text-weight-medium">
                Membres affectés au projet
              </p>
              <div class="is-flex is-align-items-center mb-2">
                <case-image
                  class="avatar avatar-sm"
                  [path]="item.project.businessEngineer.imageObjects[0].image"
                  [title]="item.project.businessEngineer.name"
                  [routerLink]="[
                    '/collaborateurs/',
                    item.project.businessEngineer.id
                  ]"
                ></case-image>
                <div
                  class="ml-2 is-flex is-flex-direction-column is-justify-content-center is-align-items-flex-start"
                >
                  <span>{{ item.project.businessEngineer.name }}</span>
                  <strong>CA</strong>
                </div>
              </div>
              <div
                class="is-flex is-align-items-center"
                *ngIf="item.project.studyManager"
              >
                <case-image
                  class="avatar avatar-sm"
                  [path]="item.project.studyManager.imageObjects[0].image"
                  [title]="item.project.studyManager.name"
                  [routerLink]="[
                    '/collaborateurs/',
                    item.project.studyManager.id
                  ]"
                ></case-image>
                <div
                  class="ml-2 is-flex is-flex-direction-column is-justify-content-center is-align-items-flex-start"
                >
                  <span>{{ item.project.studyManager.name }}</span>
                  <strong>CE</strong>
                </div>
              </div>
            </div>
            <div class="column is-6">
              <div class="columns is-multiline">
                <div class="column is-6">
                  <p class="is-uppercase has-text-grey has-text-weight-medium">
                    Nom du client
                  </p>
                  <p>
                    <a
                      [routerLink]="['/projets']"
                      [queryParams]="{ customerIds: item.project.customer.id }"
                    >
                      {{ item.project.customer.name }}
                    </a>
                  </p>
                </div>
                <div class="column is-6">
                  <p class="is-uppercase has-text-grey has-text-weight-medium">
                    N* de projet client
                  </p>
                  <p>{{ item.project.externalId || '-' }}</p>
                </div>
                <div class="column is-12">
                  <p class="is-uppercase has-text-grey has-text-weight-medium">
                    Responsables
                  </p>
                  <p>
                    <a
                      class="coma-list"
                      routerLink="/projets"
                      [queryParams]="{ referent1Id: item.project.referent1.id }"
                      >{{ item.project.referent1.name }}</a
                    >
                    <a
                      class="coma-list"
                      routerLink="/projets"
                      [queryParams]="{ referent2Id: item.project.referent2.id }"
                      *ngIf="item.project.referent2"
                      >{{ item.project.referent2.name }}</a
                    >
                    <a
                      class="coma-list"
                      routerLink="/projets"
                      [queryParams]="{ referent2Id: item.project.referent2.id }"
                      *ngIf="item.project.referent2"
                      >{{ item.project.referent2.name }}</a
                    >
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="column is-9">
      <nav class="level mb-2">
        <!-- Left side -->
        <div class="level-left">
          <div class="level-item">
            <span class="icon-text">
              <span class="is-uppercase has-text-dark has-text-weight-medium">
                Procés verbaux de reception reliés
              </span>
              <span class="icon">
                <i class="icon icon-plus has-text-grey"></i>
              </span>
              <a [caseAction]="createAcceptanceReportAction"> Ajouter un PVR</a>
            </span>
          </div>
        </div>

        <!-- Right side -->
        <div class="level-right">
          <p class="level-item">
            <span class="tag is-link-light">{{
              acceptanceReportAmountsSum | euros
            }}</span>
          </p>
        </div>
      </nav>
      <case-table
        [items]="item.acceptanceReports"
        [definition]="acceptanceReportDefinition"
        [yields]="acceptanceReportYields"
      ></case-table>

      <nav class="level mb-2 mt-6">
        <!-- Left side -->
        <div class="level-left">
          <div class="level-item">
            <span class="icon-text">
              <span class="is-uppercase has-text-dark has-text-weight-medium">
                Factures reliées
              </span>
              <span class="icon">
                <i class="icon icon-plus has-text-grey"></i>
              </span>
              <a
                [routerLink]="['/factures', 'create']"
                [queryParams]="{
                  specialRules: createInvoiceForProjectSpecialRules
                }"
                >Ajouter une facture</a
              >
            </span>
          </div>
        </div>

        <!-- Right side -->
        <div class="level-right">
          <p class="level-item">
            <span class="tag is-link-light">{{
              invoiceAmountsSum | euros
            }}</span>
          </p>
        </div>
      </nav>
      <case-table
        [items]="relatedInvoices"
        [definition]="invoiceDefinition"
        [yields]="invoiceYields"
      ></case-table>
    </div>
    <div class="column is-3">
      <h4 class="is-uppercase has-text-dark has-text-weight-medium mt-1">
        Autres commandes du projet n° {{ item.project.number }}
      </h4>

      <nav class="panel has-background-light mt-3">
        <ng-container *ngFor="let order of item.project.orders">
          <ng-container *ngIf="order.id !== item.id">
            <a
              class="panel-block is-size-6"
              [routerLink]="['/commandes', order.id]"
            >
              <span class="panel-icon">
                <i class="icon icon-box has-text-link"></i>
              </span>
              {{ order.number }}</a
            >
          </ng-container>
        </ng-container>
      </nav>
    </div>
  </div>
</ng-container>
