<nav class="level">
  <!-- Left side -->
  <div class="level-left">
    <div class="level-item">
      <span class="icon is-large">
        <i class="icon icon-bar-chart is-size-2 has-text-link"></i>
      </span>
    </div>
    <div class="level-item">
      <h1 class="title is-2 has-text-weight-light">Monitoring</h1>
    </div>
  </div>
</nav>
<!-- Filters -->
<section>
  <div class="card mb-6 mt-4">
    <app-monitoring-tabs [selectedTab]="'profitability'"></app-monitoring-tabs>
    <div class="p-4">
      <div class="columns">
        <div class="column">
          <h2
            class="title is-6 has-text-weight-bold is-uppercase has-text-grey"
          >
            Filtres
          </h2>
        </div>
      </div>

      <div
        class="columns is-multiline is-align-items-flex-end"
        *ngIf="isFilterSelectOptionsFetched"
      >
        <div
          class="column"
          [ngClass]="filter.className"
          *ngFor="let filter of filters"
        >
          <case-input
            [type]="filter.inputType"
            [initialValue]="filter.value"
            [selectOptions]="filter.selectOptions"
            [label]="filter.label"
            [readonly]="filter.readonly"
            [searchResources]="filter.searchResources"
            [required]="filter.required"
            [placeholder]="filter.placeholder"
            (valueChanged)="onFilterValueChanged($event, filter)"
          ></case-input>
        </div>
      </div>
    </div>
  </div>
</section>
<div class="columns">
  <div class="column is-2">
    <div
      class="card p-4 is-fullheight is-flex is-align-item-center is-justify-content-center"
    >
      <div>
        <i
          class="icon icon--big icon-percent has-text-success is-size-4 mb-4"
        ></i>
        <h2 class="title is-2" *ngIf="average">{{ average | euros }} / h</h2>
        <h4 class="title is-6 has-text-weight-bold is-uppercase has-text-grey">
          Rentabilité moyenne
        </h4>
        <p>
          Σ factures de tous les projets / heures passées sur tous les projets
        </p>
      </div>
    </div>
  </div>
  <div class="column is-5">
    <div class="card is-fullheight p-4">
      <h2 class="title is-6 has-text-weight-bold is-uppercase has-text-grey">
        Les plus rentables
      </h2>
      <p>Σ factures d'un projet / heures passées sur un projet</p>
      <div style="display: block">
        <canvas
          ngChartjs
          [datasets]="mostProfitableChartData"
          [labels]="mostProfitableChartLabels"
          [options]="barChartOptions"
          [chartType]="'horizontalBar'"
          [legend]="false"
          (chartClick)="onChartClick($event, 'mostProfitable')"
          *ngIf="mostProfitableChartData && mostProfitableChartLabels"
        ></canvas>
      </div>
    </div>
  </div>
  <div class="column is-5">
    <div class="card is-fullheight p-4">
      <h2 class="title is-6 has-text-weight-bold is-uppercase has-text-grey">
        Les moins rentables
      </h2>
      <p>Σ factures d'un projet / heures passées sur un projet</p>
      <div style="display: block">
        <canvas
          ngChartjs
          [datasets]="lessProfitableChartData"
          [labels]="lessProfitableChartLabels"
          [options]="barChartOptions"
          [chartType]="'horizontalBar'"
          [legend]="false"
          (chartClick)="onChartClick($event, 'lessProfitable')"
          *ngIf="lessProfitableChartData && lessProfitableChartLabels"
        ></canvas>
      </div>
    </div>
  </div>
</div>
