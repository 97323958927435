<div class="is-flex is-align-items-center is-justify-content-center py-3">
  <div
    class="button is-light is-circle mr-2"
    mwlCalendarPreviousView
    [view]="'month'"
    [(viewDate)]="viewDate"
    (viewDateChange)="goToMonth($event)"
  >
    <i class="icon icon-chevron-left is-size-3"></i>
  </div>
  <h3
    class="title is-3 is-light mb-0 is-flex is-align-items-center is-justify-content-center"
    style="min-width: 200px"
  >
    {{ viewDate | calendarDate: 'monthViewTitle' }}
  </h3>
  <div
    class="button is-light is-circle ml-2"
    mwlCalendarNextView
    [view]="'month'"
    [(viewDate)]="viewDate"
    (viewDateChange)="goToMonth($event)"
  >
    <i class="icon icon-chevron-right is-size-3"></i>
  </div>
</div>

<!-- Calendar -->
<mwl-calendar-month-view
  [events]="events"
  [viewDate]="viewDate"
  [weekStartsOn]="1"
  [cellTemplate]="dayTemplate"
  [tooltipTemplate]="emptyTooltipTemplate"
  (dayClicked)="openCreateModal($event.day)"
  *ngIf="events"
></mwl-calendar-month-view>

<!-- We keep an empty tooltip template to get project.name (from ng-reflect attr) without displaying tooltip on hover -->
<ng-template #emptyTooltipTemplate>
  <div class="do-not-delete"></div>
</ng-template>

<!-- Custom day template-->
<ng-template #dayTemplate let-day="day" let-locale="locale">
  <div class="cal-cell-top">
    <span class="cal-day-number">
      {{ day.date | calendarDate: 'monthViewDayNumber':locale }}
    </span>
  </div>

  <div class="cal-events">
    <div
      class="cal-event tooltip has-tooltip is-1-of-4"
      [ngStyle]="{ 'background-color': event.color.primary }"
      [attr.data-tooltip]="
        event.meta.projectNumber ? event.meta.projectNumber : null
      "
      *ngFor="let event of day.events"
      (click)="deleteTimeSlot($event, event)"
    >
      <span>
        {{ event.title }}
      </span>
    </div>
  </div>
</ng-template>
