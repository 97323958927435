import { Component, OnInit, Inject } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import {
  caseListTemplate,
  CaseListComponent,
  Filter,
  ResourceDefinition,
  BreadcrumbService,
  FlashMessageService,
  ResourceService,
  CaseConfig,
  Yield,
  AuthService,
  FilterService
} from '@case-app/angular-library'

import { typeDefinition } from '../type.definition'
import { typeYields } from '../type.yields'

@Component({ template: caseListTemplate })
export class TypeListComponent extends CaseListComponent implements OnInit {
  definition: ResourceDefinition = typeDefinition
  yields: Yield[] = typeYields

  filters: Filter[] = []

  constructor(
    router: Router,
    activatedRoute: ActivatedRoute,
    resourceService: ResourceService,
    breadcrumbService: BreadcrumbService,
    flashMessageService: FlashMessageService,
    filterService: FilterService,
    authService: AuthService,
    @Inject('CASE_CONFIG_TOKEN') config: CaseConfig
  ) {
    super(
      router,
      activatedRoute,
      breadcrumbService,
      resourceService,
      flashMessageService,
      authService,
      filterService,
      config
    )
  }

  ngOnInit() {
    this.initListView()
  }
}
