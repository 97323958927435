import { Route } from '@angular/router'

import {
  AuthGuard,
  PermissionGuard,
  ResourceMode
} from '@case-app/angular-library'

import { ProjectCreateEditComponent } from './project-create-edit/project-create-edit.component'
import { ProjectDetailComponent } from './project-detail/project-detail.component'
import { ProjectListComponent } from './project-list/project-list.component'

export const projectRoutes: Route[] = [
  {
    path: 'projets',
    component: ProjectListComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permission: 'browseProjects'
    }
  },
  {
    path: 'projets/create',
    component: ProjectCreateEditComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permission: 'addProjects',
      mode: ResourceMode.Create
    }
  },
  {
    path: 'projets/:id/edit',
    component: ProjectCreateEditComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      mode: ResourceMode.Edit,
      permission: 'editProjects'
    }
  },
  {
    path: 'projets/:id',
    component: ProjectDetailComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permission: 'readProjects'
    }
  }
]
