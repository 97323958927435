import { Yield, YieldType } from '@case-app/angular-library'

export const typeYields: Yield[] = [
  {
    label: 'Name',
    property: 'color',
    secondProperty: 'name',
    orderByProperty: 'name',
    className: 'has-mini-circle pointer',
    type: YieldType.Color
  }
]
