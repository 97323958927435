<nav class="level">
  <!-- Left side -->
  <div class="level-left">
    <div class="level-item">
      <span class="icon is-large">
        <i class="icon icon-bar-chart is-size-2 has-text-link"></i>
      </span>
    </div>
    <div class="level-item">
      <h1 class="title is-2 has-text-weight-light">Monitoring</h1>
    </div>
  </div>
</nav>
<!-- Filters -->
<section>
  <div class="card mb-6 mt-4">
    <app-monitoring-tabs [selectedTab]="'turnover'"></app-monitoring-tabs>
    <div class="p-4">
      <div class="columns">
        <div class="column">
          <h2 class="title is-6 is-bold is-uppercase text-is-grey">Filtres</h2>
        </div>
      </div>

      <div
        class="columns is-multiline is-align-items-flex-end"
        *ngIf="isFilterSelectOptionsFetched"
      >
        <div
          class="column"
          [ngClass]="filter.className"
          *ngFor="let filter of filters"
        >
          <case-input
            [type]="filter.inputType"
            [initialValue]="filter.value"
            [selectOptions]="filter.selectOptions"
            [readonly]="filter.readonly"
            [label]="filter.label"
            [searchResources]="filter.searchResources"
            [required]="filter.required"
            [placeholder]="filter.placeholder"
            (valueChanged)="onFilterValueChanged($event, filter)"
          ></case-input>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- Key numbers -->
<div class="level mb-2">
  <div class="level-left">
    <div class="level-item">
      <h2 class="title is-6 has-text-weight-bold is-uppercase has-text-grey">
        Synthèse financière
      </h2>
    </div>
  </div>
  <div class="level-right is-hidden-mobile">
    <div class="level-item tags">
      <span
        class="tag"
        *ngFor="let keyNumber of definition.keyNumbers"
        [ngClass]="keyNumber.className || 'is-link-light'"
      >
        <ng-container *ngIf="keyNumber.loading">Loading...</ng-container>
        <ng-container *ngIf="!keyNumber.loading"
          >{{ keyNumber.label }}: {{ keyNumber.value | euros }}</ng-container
        >
      </span>
    </div>
  </div>
</div>

<div class="card is-fullheight p-4">
  <div class="columns">
    <div class="column is-8-desktop is-offset-2-desktop">
      <div style="display: block">
        <div style="display: block">
          <canvas #chart> </canvas>
        </div>
      </div>
    </div>
  </div>
</div>
