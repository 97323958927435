import { Component, Inject, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import {
  AuthService,
  BreadcrumbService,
  CaseConfig,
  CaseListComponent,
  Filter,
  FilterService,
  FlashMessageService,
  InputType,
  ResourceDefinition,
  ResourceService
} from '@case-app/angular-library'
import { ChartDataSets, ChartOptions, ChartTooltipItem } from 'chart.js'
import * as moment from 'moment'
import { Color, Labels } from 'ng-chartjs'

import { appConstants } from '../../../../../shared/constants/app.constants'
import { ChartItem } from '../chart-item.interface'
import { monitoringLatenessDefinition } from './monitoring-lateness.definition'

@Component({
  selector: 'app-monitoring-lateness',
  templateUrl: './monitoring-lateness.component.html',
  styleUrls: ['./monitoring-lateness.component.scss']
})
export class MonitoringLatenessComponent
  extends CaseListComponent
  implements OnInit
{
  lateProjects: ChartItem[]

  pieChartLabels: Labels = ['En retard', `A l'heure`]
  pieChartData: number[]
  pieChartOptions: ChartOptions = {
    tooltips: {
      callbacks: {
        title: (items: ChartTooltipItem[], data) =>
          items[0].index === 0 ? 'En retard' : `A l'heure`,

        label: (tooltipItem, data) => {
          return data.datasets[0].data[tooltipItem.index] + ' %'
        }
      }
    }
  }

  barChartLabels: Labels
  barChartData: ChartDataSets[] = []

  pieChartColors: Color[] = [
    {
      backgroundColor: ['#FF4500', '#31BE76'],
      hoverBackgroundColor: ['#FF4500', '#31BE76']
    }
  ]

  barChartOptions: ChartOptions = {
    scales: {
      yAxes: [
        {
          gridLines: {
            borderDash: [8, 4],
            color: '#e9eeff'
          },
          ticks: {
            fontColor: '#b0bac9'
          }
        }
      ],
      xAxes: [
        {
          gridLines: {
            borderDash: [8, 4],
            color: '#e9eeff'
          },
          ticks: {
            fontColor: '#b0bac9',
            beginAtZero: true,
            callback: (value) => `${value}j`
          }
        }
      ]
    }
  }

  filters: Filter[] = [
    {
      label: 'Date',
      properties: {
        dateFrom: 'dateFrom',
        dateTo: 'dateTo'
      },
      value: {
        dateFrom: moment().subtract(1, 'y').format('YYYY-MM-DD'),
        dateTo: moment().format('YYYY-MM-DD')
      },
      inputType: InputType.DateRange,
      className: 'is-4'
    },
    {
      label: `Chargé d'affaire`,
      property: 'businessEngineerId',
      placeholder: 'Tous les CAs',
      inputType: InputType.Select,
      className: 'is-4',
      selectOptions: () =>
        this.componentResourceService.listSelectOptions('users', {
          roleNames: [
            appConstants.ROLES.ADMIN_ROLE_NAME,
            appConstants.ROLES.BUSINESS_ENGINEER_ROLE_NAME
          ],
          orderBy: 'name'
        })
    }
  ]

  isIndividualAnalytics: boolean
  definition: ResourceDefinition = monitoringLatenessDefinition

  constructor(
    resourceService: ResourceService,
    activatedRoute: ActivatedRoute,
    router: Router,
    breadcrumbService: BreadcrumbService,
    flashMessageService: FlashMessageService,
    filterService: FilterService,
    authService: AuthService,
    @Inject('CASE_CONFIG_TOKEN') config: CaseConfig,
    private componentResourceService: ResourceService,
    private componentRouter: Router,
    private componentAuthService: AuthService,
    private componentActivatedRoute: ActivatedRoute
  ) {
    super(
      router,
      activatedRoute,
      breadcrumbService,
      resourceService,
      flashMessageService,
      authService,
      filterService,
      config
    )
  }

  async ngOnInit() {
    const activateFilters: boolean = await this.componentAuthService.can(
      'browseOwnAnalytics'
    )
    if (activateFilters) {
      this.filters.push({
        label: `Chargé d'affaire`,
        property: 'businessEngineerId',
        inputType: InputType.Select,
        className: 'is-4',
        selectOptions: () =>
          this.componentResourceService.listSelectOptions('users', {
            roleNames: [
              appConstants.ROLES.ADMIN_ROLE_NAME,
              appConstants.ROLES.BUSINESS_ENGINEER_ROLE_NAME
            ],
            orderBy: 'name'
          })
      })
    }

    this.paginator$.subscribe((paginator: any) => {
      const projects: ChartItem[] = paginator

      this.lateProjects = projects
        .filter((cI: ChartItem) => cI.value >= 0)
        .sort((a: ChartItem, b: ChartItem) => b.value - a.value)

      this.pieChartData = [
        Math.round((this.lateProjects.length / projects.length) * 10000) / 100,
        Math.round(
          (projects.filter((cI: ChartItem) => cI.value < 0).length /
            projects.length) *
            10000
        ) / 100
      ]
      this.barChartLabels = this.lateProjects
        .slice(0, 10)
        .map((cI: ChartItem) => cI.label)
      this.barChartData = [
        {
          data: this.lateProjects.slice(0, 10).map((cI: ChartItem) => cI.value),
          label: `Jours de retard`,
          backgroundColor: '#5065ff',
          hoverBackgroundColor: '#5065ff'
        }
      ]
    })
    await this.initListView()
  }

  onChartClick(event: { active: { _index: number }[] }) {
    if (event.active.length) {
      const project: ChartItem = this.lateProjects[event.active[0]._index]
      this.componentRouter.navigate(['/projets', project.id])
    }
  }
}
