import { Component, OnInit } from '@angular/core'
import { FormBuilder } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import {
  BreadcrumbService,
  CaseCreateEditComponent,
  caseCreateEditTemplate,
  Field,
  FlashMessageService,
  InputType,
  ResourceDefinition,
  ResourceService
} from '@case-app/angular-library'

import { customerDefinition } from '../customer.definition'

@Component({ template: caseCreateEditTemplate })
export class CustomerCreateEditComponent
  extends CaseCreateEditComponent
  implements OnInit
{
  definition: ResourceDefinition = customerDefinition
  fields: Field[] = [
    {
      label: 'Nom',
      property: 'name',
      className: 'is-6',
      required: true,
      inputType: InputType.Text
    },
    {
      label: 'Adresse',
      property: 'address',
      className: 'is-6',
      required: true,
      inputType: InputType.Text
    },
    {
      label: 'Groupe',
      property: 'corporateGroupId',
      retrievedItemProperties: {
        corporateGroupId: 'corporateGroup.id'
      },
      className: 'is-6',
      required: true,
      inputType: InputType.Select,
      selectOptions: () =>
        this.componentResourceService.listSelectOptions('corporate-groups', {
          orderBy: 'name'
        })
    },
    {
      label: 'Délai de paiement (en jours)',
      property: 'paymentDeadline',
      className: 'is-6',
      required: true,
      inputType: InputType.Number
    }
  ]

  constructor(
    formBuilder: FormBuilder,
    router: Router,
    activatedRoute: ActivatedRoute,
    resourceService: ResourceService,
    breadcrumbService: BreadcrumbService,
    flashMessageService: FlashMessageService,
    private componentResourceService: ResourceService
  ) {
    super(
      formBuilder,
      router,
      breadcrumbService,
      resourceService,
      flashMessageService,
      activatedRoute
    )
  }

  ngOnInit() {
    this.initCreateEditView()
  }
}
