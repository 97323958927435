import { Component, OnDestroy, OnInit } from '@angular/core'
import { FormBuilder, Validators } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import {
  BreadcrumbService,
  CaseCreateEditComponent,
  caseCreateEditTemplate,
  Field,
  FlashMessageService,
  InputType,
  ResourceDefinition,
  ResourceService
} from '@case-app/angular-library'

import { userDefinition } from './user.definition'

@Component({
  template: caseCreateEditTemplate
})
export class UserCreateEditComponent
  extends CaseCreateEditComponent
  implements OnInit, OnDestroy
{
  definition: ResourceDefinition = userDefinition
  isEditMyself: boolean

  // EditMyself only : Changing user's own email makes token obsolete.
  emailChanged = false

  fields: Field[] = [
    {
      id: 'name',
      label: 'Nom',
      property: 'name',
      className: 'is-6',
      inputType: InputType.Text,
      required: true
    },
    {
      id: 'roleId',
      label: `Rôle`,
      placeholder: `Choisir le rôle du collaborateur...`,
      property: 'roleId',
      retrievedItemProperties: {
        roleId: 'role.id'
      },
      inputType: InputType.Select,
      permission: 'editUsers',
      selectOptions: () =>
        this.componentResourceService.listSelectOptions('roles'),
      className: 'is-6',
      required: true
    },
    {
      id: 'email',
      label: 'Email',
      property: 'email',
      className: 'is-6',
      inputType: InputType.Email,
      required: true,
      validators: [Validators.email]
    },
    {
      label: 'Mot de passe',
      property: 'password',
      className: 'is-6',
      inputType: InputType.Password,
      createValidators: [Validators.required],
      editValidators: []
    },
    {
      label: 'Taux horaire',
      property: 'hourlyRate',
      className: 'is-3',
      inputType: InputType.Number,
      required: true,
      permission: 'browseAnalytics'
    },
    {
      label: 'Seuil minimum CA ',
      property: 'minTurnover',
      className: 'is-3',
      inputType: InputType.Number
    },
    {
      label: 'Avatar',
      placeholder: 'Choisir un fichier image',
      property: 'image',
      className: 'is-3',
      inputType: InputType.Image,
      required: true
    },
    {
      label: 'Actif',
      helpText: `Seul les utilisateurs actifs peuvent se connecter à l'application`,
      property: 'isActive',
      initialValue: true,
      className: 'is-3',
      inputType: InputType.Checkbox
    },
    {
      label: 'Objectif',
      property: 'objective',
      className: 'is-12',
      inputType: InputType.Textarea
    }
  ]

  constructor(
    formBuilder: FormBuilder,
    router: Router,
    breadcrumbService: BreadcrumbService,
    resourceService: ResourceService,
    flashMessageService: FlashMessageService,
    activatedRoute: ActivatedRoute,
    private customFlashMessageService: FlashMessageService,
    private customRouter: Router,
    private componentResourceService: ResourceService
  ) {
    super(
      formBuilder,
      router,
      breadcrumbService,
      resourceService,
      flashMessageService,
      activatedRoute
    )
    this.isEditMyself = activatedRoute.snapshot.data.editMyself
  }

  async ngOnInit() {
    await this.initCreateEditView()

    this.form.valueChanges.subscribe((newValue: { email: string }) => {
      if (newValue.email !== this.item.email) {
        this.emailChanged = true
      }
    })
  }

  ngOnDestroy() {
    if (this.isEditMyself && this.emailChanged) {
      this.customRouter.navigate(['/logout'])
      this.customFlashMessageService.info(
        `Vous avez changé votre adresse email. Veuillez vous re-connecter avec votre nouvelle adresse.`
      )
    }
  }
}
