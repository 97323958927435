import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild
} from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import {
  ActionType,
  BreadcrumbService,
  CaseDetailComponent,
  FlashMessageService,
  InputType,
  ResourceDefinition,
  ResourceMode,
  ResourceService,
  SelectOption
} from '@case-app/angular-library'
import { Action } from '@case-app/angular-library/lib/interfaces/actions/action.interface'
import * as moment from 'moment'

import { StatementType } from '../../../../../../shared/enums/statement-type.enum'
import { Market } from '../../../../src/resources/market/market.entity'
import { Project } from '../../../../src/resources/project/project.entity'
import { acceptanceReportDefinition } from '../../acceptance-report/acceptance-report.definition'
import { statementDefinition } from '../../statement/statement.definition'
import { editStatus } from '../project.actions'
import { projectDefinition } from '../project.definition'

@Component({
  selector: 'app-project-detail',
  templateUrl: './project-detail.component.html',
  styleUrls: ['./project-detail.component.scss']
})
export class ProjectDetailComponent
  extends CaseDetailComponent
  implements OnInit
{
  @ViewChild('createMenu') createMenu: ElementRef

  definition: ResourceDefinition = projectDefinition
  item: Project

  selectedTab: string
  downloadLastStatement: boolean
  isOnboarding: boolean
  showCreateMenu: boolean
  showProjectFrameworkModal: boolean

  createRelationForProjectQueryParams: { [key: string]: string }
  createAcceptanceReportAction: Action
  createEstimatedStatementAction: Action
  createContradictoryStatementAction: Action

  editStatus: Action

  constructor(
    breadcrumbService: BreadcrumbService,
    resourceService: ResourceService,
    flashMessageService: FlashMessageService,
    activatedRoute: ActivatedRoute,
    private componentActivatedRoute: ActivatedRoute,
    private componentResourceService: ResourceService
  ) {
    super(
      breadcrumbService,
      resourceService,
      flashMessageService,
      activatedRoute
    )
  }

  async ngOnInit(): Promise<void> {
    this.componentActivatedRoute.queryParams.subscribe(async (queryParams) => {
      this.selectedTab = queryParams.selectedTab || 'technical'
      this.downloadLastStatement = queryParams.downloadLastStatement === '1'

      await this.initDetailView()

      this.editStatus = editStatus(this.item)

      this.isOnboarding =
        queryParams.resourceCreated === `Project-${this.item.id}`

      if (queryParams.resourceCreated === `Project-${this.item.id}`) {
        this.showProjectFrameworkModal = true
      }

      this.createAcceptanceReportAction = {
        type: ActionType.OpenCreateEditModal,
        openCreateEditModal: {
          title: 'Nouveau PV de réception',
          definition: acceptanceReportDefinition,
          mode: ResourceMode.Create,
          helpText:
            'Remplissez les champs pour télécharger le relevé. Vous pourrez ensuite entrer le montant calculé.',
          redirectTo: `/projets/${this.item.id}`,
          redirectToQueryParams: {
            selectedTab: 'accounting'
          },
          fields: [
            {
              id: 'order',
              label: 'Commande',
              property: 'orderId',
              required: true,
              inputType: InputType.Select,
              selectOptions: () => {
                const orders = this.item.orders.map((order) => ({
                  label: order.number,
                  value: order.id,
                  orderBy: 'name'
                }))

                orders.sort((a, b) =>
                  a.label < b.label ? -1 : a.label > b.label ? 1 : 0
                )

                return Promise.resolve(orders)
              }
            },
            {
              label: 'Numéro de PVR',
              property: 'number',
              inputType: InputType.Text,
              className: 'is-12',
              required: true
            },
            {
              label: 'Date de PVR',
              property: 'date',
              inputType: InputType.Datepicker,
              initialValue: moment(),
              className: 'is-12',
              required: true
            },
            {
              label: 'Description',
              property: 'description',
              inputType: InputType.Text,
              className: 'is-12',
              required: true
            },
            {
              label: 'Montant du PVR (HT)',
              property: 'amount',
              inputType: InputType.Number,
              className: 'is-12',
              required: true
            }
          ]
        }
      }
      this.createEstimatedStatementAction = {
        type: ActionType.OpenCreateEditModal,
        openCreateEditModal: {
          title: 'Nouveau relevé prévisionnel',
          definition: statementDefinition,
          redirectTo: `/projets/${this.item.id}`,
          redirectToQueryParams: {
            downloadLastStatement: '1'
          },
          mode: ResourceMode.Create,
          helpText:
            'Remplissez les champs pour télécharger le relevé. Vous pourrez ensuite entrer le montant calculé.',
          fields: [
            {
              label: 'Projet',
              hidden: true,
              required: true,
              property: 'projectId',
              inputType: InputType.Number,
              forcedValue: this.item.id
            },
            {
              label: 'type',
              hidden: true,
              required: true,
              property: 'type',
              inputType: InputType.Number,
              forcedValue: StatementType.Estimated
            },
            {
              label: 'Nom de relevé',
              property: 'title',
              required: true,
              inputType: InputType.Text,
              className: 'is-12'
            },
            {
              label: 'Date du relevé',
              property: 'date',
              inputType: InputType.Datepicker,
              initialValue: moment(),
              required: true,
              className: 'is-12'
            },
            {
              label: 'Marché',
              property: 'marketId',
              inputType: InputType.Select,
              selectOptions: () =>
                this.componentResourceService.listSelectOptions('markets', {
                  orderBy: 'name'
                }),
              required: true,
              className: 'is-12',
              onChange: async (newValue: number) => {
                const market: Market = await this.componentResourceService
                  .show('markets', newValue)
                  .then((res) => res)

                const seriesField =
                  this.createEstimatedStatementAction.openCreateEditModal.fields.find(
                    (f) => f.id === 'series'
                  )

                seriesField.selectOptions =
                  ProjectDetailComponent.getMarketSeries(market)
              }
            },
            {
              id: 'series',
              label: 'Série',
              placeholder: 'Choisir la série',
              property: 'seriesNumber',
              inputType: InputType.Select,
              selectOptions: [],
              required: true,
              className: 'is-12'
            }
          ]
        }
      }
      this.createContradictoryStatementAction = {
        type: ActionType.OpenCreateEditModal,
        openCreateEditModal: {
          title: 'Nouveau relevé contradictoire',
          definition: statementDefinition,
          mode: ResourceMode.Create,
          redirectTo: `/projets/${this.item.id}`,
          redirectToQueryParams: {
            downloadLastStatement: '1'
          },
          fields: [
            {
              label: 'Projet',
              hidden: true,
              required: true,
              property: 'projectId',
              inputType: InputType.Number,
              forcedValue: this.item.id
            },
            {
              label: 'type',
              hidden: true,
              required: true,
              property: 'type',
              inputType: InputType.Number,
              forcedValue: StatementType.Contradictory
            },
            {
              label: 'Nom de relevé',
              property: 'title',
              required: true,
              inputType: InputType.Text,
              className: 'is-12'
            },
            {
              label: 'Date du relevé',
              property: 'date',
              inputType: InputType.Datepicker,
              initialValue: moment(),
              required: true,
              className: 'is-12'
            },
            {
              label: 'Marché',
              property: 'marketId',
              inputType: InputType.Select,
              selectOptions: () =>
                this.componentResourceService.listSelectOptions('markets', {
                  orderBy: 'name'
                }),
              required: true,
              className: 'is-12',
              onChange: async (newValue: number) => {
                const market: Market = await this.componentResourceService
                  .show('markets', newValue)
                  .then((res) => res)

                const seriesField =
                  this.createContradictoryStatementAction.openCreateEditModal.fields.find(
                    (f) => f.id === 'series'
                  )

                seriesField.selectOptions =
                  ProjectDetailComponent.getMarketSeries(market)
              }
            },
            {
              id: 'series',
              label: 'Série',
              property: 'seriesNumber',
              inputType: InputType.Select,
              selectOptions: [],
              required: true,
              className: 'is-12'
            }
          ]
        }
      }

      this.createRelationForProjectQueryParams = {
        specialRules: JSON.stringify([
          {
            fieldId: 'projectId',
            forcedValue: this.item.id
          }
        ]),
        redirectTo: `/projets/${this.item.id}`,
        redirectToQueryParams: JSON.stringify({ selectedTab: 'accounting' })
      }
    })
  }

  static getMarketSeries(market: Market): SelectOption[] {
    const series: SelectOption[] = []
    Array(1, 2, 3).map((seriesNumber: number) => {
      if (
        market[`service${seriesNumber}Name`] &&
        market[`service${seriesNumber}File`] &&
        market[`service${seriesNumber}Price`]
      ) {
        series.push({
          label: market[`service${seriesNumber}Name`],
          value: seriesNumber
        })
      }
    })

    series.push({
      label: 'Aucune série',
      value: 'noSeries'
    })

    return series
  }

  @HostListener('document:click', ['$event.target'])
  onClick(target) {
    if (
      this.showCreateMenu &&
      !this.createMenu.nativeElement.contains(target)
    ) {
      this.showCreateMenu = false
    }
  }
}
