import {
  Gender,
  LinkType,
  ResourceDefinition,
  ActionType
} from '@case-app/angular-library'

export const expenseDefinition: ResourceDefinition = {
  title: 'Charges',
  nameSingular: 'charge',
  namePlural: 'charges',
  className: 'Expense',
  mainIdentifier: 'reference',
  slug: 'expenses',
  path: 'charges',
  icon: 'icon-grid',
  gender: Gender.Feminine,
  hasDetailPage: false,
  hasListPage: true,
  buttons: [LinkType.CREATE],
  defaultLink: LinkType.EDIT,
  childrenThatPreventDelete: [],
  dropdownLinks: [
    {
      label: 'Modifier',
      permission: 'editExpenses',
      action: (expense) => ({
        type: ActionType.Link,
        link: {
          path: `${expenseDefinition.path}/${expense.id}/edit`
        }
      })
    },
    {
      label: 'Supprimer',
      permission: 'deleteExpenses',
      action: (expense) => ({
        type: ActionType.Delete,
        delete: {
          itemToDelete: expense,
          definition: expenseDefinition
        }
      })
    }
  ]
}
