import { Component, Inject, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import {
  AuthService,
  BreadcrumbService,
  CaseConfig,
  CaseListComponent,
  caseListTemplate,
  Filter,
  FilterService,
  FlashMessageService,
  InputType,
  ResourceDefinition,
  ResourceService,
  Yield
} from '@case-app/angular-library'

import { acceptanceReportDefinition } from '../../acceptance-report/acceptance-report.definition'
import { customerDefinition } from '../../customer/customer.definition'
import { projectDefinition } from '../../project/project.definition'
import { invoiceDefinition } from '../invoice.definition'
import { invoiceYields } from '../invoice.yields'

@Component({ template: caseListTemplate })
export class InvoiceListComponent extends CaseListComponent implements OnInit {
  definition: ResourceDefinition = invoiceDefinition
  yields: Yield[] = invoiceYields

  filters: Filter[] = [
    {
      label: 'Rechercher une facture par numéro, projet, client ou PVR...',
      inputType: InputType.MultiSearch,
      className: 'is-4',
      searchResources: [
        invoiceDefinition,
        customerDefinition,
        projectDefinition,
        acceptanceReportDefinition
      ],
      properties: {
        invoiceIds: 'invoiceIds',
        customerIds: 'customerIds',
        projectIds: 'projectIds',
        acceptanceReportIds: 'acceptanceReportIds'
      }
    },
    {
      label: `Date de facturation`,
      properties: {
        dateFrom: 'dateFrom',
        dateTo: 'dateTo'
      },
      inputType: InputType.DateRange,
      className: 'is-6'
    },
    {
      label: `Statut`,
      property: 'paymentStatus',
      placeholder: 'Factures payées et non payées',
      inputType: InputType.Select,
      className: 'is-2',
      selectOptions: [
        {
          label: 'Payées',
          value: 'payedOnly'
        },
        {
          label: 'Non payées',
          value: 'notPayedOnly'
        },
        {
          label: 'Non payées et en retard de paiement',
          value: 'notPayedAndLateOnly'
        }
      ]
    }
  ]

  constructor(
    router: Router,
    activatedRoute: ActivatedRoute,
    resourceService: ResourceService,
    breadcrumbService: BreadcrumbService,
    flashMessageService: FlashMessageService,
    filterService: FilterService,
    authService: AuthService,
    @Inject('CASE_CONFIG_TOKEN') config: CaseConfig
  ) {
    super(
      router,
      activatedRoute,
      breadcrumbService,
      resourceService,
      flashMessageService,
      authService,
      filterService,
      config
    )
  }

  ngOnInit() {
    this.initListView()
  }
}
