export const appConstants = {
  STORAGE_PATH: 'public/storage',

  ITEMS_PER_PAGE: 40,

  ROLES: {
    STUDY_MANAGER_ROLE_NAME: 'studyManager',
    BUSINESS_ENGINEER_ROLE_NAME: 'businessEngineer',
    ADMIN_ROLE_NAME: 'admin'
  }
}
