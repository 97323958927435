import {
  ActionType,
  Gender,
  LinkType,
  ResourceDefinition
} from '@case-app/angular-library'
import { Market } from '../../../src/resources/market/market.entity'

export const marketDefinition: ResourceDefinition = {
  title: 'Marchés',
  nameSingular: 'marché',
  namePlural: 'marchés',
  gender: Gender.Masculine,
  mainIdentifier: 'name',
  slug: 'markets',
  path: 'marches',
  className: 'Market',
  icon: 'icon-pie-chart',
  hasDetailPage: false,
  hasListPage: true,
  buttons: [LinkType.CREATE, LinkType.EXPORT],
  defaultLink: LinkType.EDIT,
  childrenThatPreventDelete: [],
  dropdownLinks: [
    {
      label: 'Modifier',
      permission: 'editMarkets',
      action: (market) => ({
        type: ActionType.Link,
        link: {
          path: `${marketDefinition.path}/${market.id}/edit`
        }
      })
    },
    {
      label: 'Supprimer',
      permission: 'deleteMarkets',
      action: (market) => ({
        type: ActionType.Delete,
        delete: {
          itemToDelete: market,
          definition: marketDefinition
        }
      }),
      disabled: (market: Market) => !!market.orders.length,
      tooltip: (market: Market) =>
        !!market.orders.length
          ? `Impossible de supprimer car certaines commandes sont reliés à ce marché.`
          : null
    }
  ]
}
