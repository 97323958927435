import { Component, OnInit } from '@angular/core'
import { FormBuilder } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import {
  BreadcrumbService,
  CaseCreateEditComponent,
  caseCreateEditTemplate,
  Field,
  FlashMessageService,
  InputType,
  ResourceDefinition,
  ResourceService
} from '@case-app/angular-library'

import { settingDefinition } from '../setting.definition'

@Component({ template: caseCreateEditTemplate })
export class SettingCreateEditComponent
  extends CaseCreateEditComponent
  implements OnInit
{
  definition: ResourceDefinition = settingDefinition
  fields: Field[] = [
    {
      label: `Seuil minimum CA mensuel (global)`,
      property: 'globalMinTurnover',
      inputType: InputType.Number,
      required: true,
      className: 'is-6'
    },

    {
      label: 'Modèle Excel facture',
      placeholder: 'Ajouter un fichier Excel',
      property: 'invoiceBaseFile',
      inputType: InputType.File,
      required: true,
      className: 'is-6'
    },
    {
      label: 'Template projet 1',
      placeholder: 'Ajouter un dossier',
      property: 'projectFramework1',
      inputType: InputType.File,
      required: true,
      className: 'is-3'
    },
    {
      label: 'Template projet 2',
      placeholder: 'Ajouter un dossier',
      property: 'projectFramework2',
      inputType: InputType.File,
      required: true,
      className: 'is-3'
    },
    {
      label: 'Template projet 3',
      placeholder: 'Ajouter un dossier',
      property: 'projectFramework3',
      inputType: InputType.File,
      required: true,
      className: 'is-3'
    },
    {
      label: 'Template projet 4',
      placeholder: 'Ajouter un dossier',
      property: 'projectFramework4',
      inputType: InputType.File,
      required: true,
      className: 'is-3'
    }
  ]

  constructor(
    formBuilder: FormBuilder,
    router: Router,
    activatedRoute: ActivatedRoute,
    resourceService: ResourceService,
    breadcrumbService: BreadcrumbService,
    flashMessageService: FlashMessageService
  ) {
    super(
      formBuilder,
      router,
      breadcrumbService,
      resourceService,
      flashMessageService,
      activatedRoute
    )
  }

  ngOnInit() {
    this.initCreateEditView()
  }
}
