import { Yield } from '@case-app/angular-library'

export const referentYields: Yield[] = [
  {
    label: 'Name',
    property: 'name'
  },
  {
    label: 'Client',
    property: 'customer.name',
    link: (r) => `/projets?customerIds=${r.customer.id}`
  }
]
