<div class="panel-block has-v-separation p-4">
  <div class="is-fullwidth">
    <div class="columns is-size-8">
      <div class="column is-9">
        <nav class="level mb-2">
          <!-- Left side -->
          <div class="level-left">
            <div class="level-item">
              <span class="icon-text">
                <span class="is-uppercase has-text-dark has-text-weight-medium">
                  Commandes
                </span>
                <span class="icon">
                  <i class="icon icon-plus has-text-grey"></i>
                </span>
                <a
                  routerLink="/commandes/create"
                  [queryParams]="createRelationForProjectQueryParams"
                  >Ajouter une commande</a
                >
              </span>
            </div>
          </div>

          <!-- Right side -->
          <div class="level-right">
            <p class="level-item">
              <span class="tag is-link-light"
                >Total des commandes (HT): {{ totalOrderAmounts | euros }}</span
              >
            </p>
          </div>
        </nav>
        <case-table
          [items]="orders"
          [definition]="orderDefinition"
          [yields]="orderYields"
          *ngIf="orders"
        ></case-table>
        <hr class="mt-0" />
        <nav class="level mb-2">
          <!-- Left side -->
          <div class="level-left">
            <div class="level-item">
              <span class="icon-text">
                <span class="is-uppercase has-text-dark has-text-weight-medium">
                  Procès verbaux de réception
                </span>
                <span class="icon">
                  <i class="icon icon-plus has-text-grey"></i>
                </span>
                <a [caseAction]="createAcceptanceReportAction"
                  >Ajouter un PVR</a
                >
              </span>
            </div>
          </div>

          <!-- Right side -->
          <div class="level-right">
            <p class="level-item">
              <span class="tag is-link-light">
                Total des PVR (HT):
                {{ totalAcceptanceReportAmounts | euros }}</span
              >
            </p>
          </div>
        </nav>
        <case-table
          [items]="acceptanceReports"
          [definition]="acceptanceReportDefinition"
          [yields]="acceptanceReportYields"
          *ngIf="acceptanceReports"
        ></case-table>
        <hr class="mt-0" />
        <nav class="level mb-2">
          <!-- Left side -->
          <div class="level-left">
            <div class="level-item">
              <span class="icon-text">
                <span class="is-uppercase has-text-dark has-text-weight-medium">
                  Factures
                </span>
                <span class="icon">
                  <i class="icon icon-plus has-text-grey"></i>
                </span>
                <a
                  routerLink="/factures/create"
                  [queryParams]="createRelationForProjectQueryParams"
                  >Ajouter une facture</a
                >
              </span>
            </div>
          </div>

          <!-- Right side -->
          <div class="level-right">
            <p class="level-item">
              <span class="tag is-link-light">
                Total des factures (HT):
                {{ totalInvoiceAmounts | euros }}</span
              >
            </p>
          </div>
        </nav>
        <case-table
          [items]="invoices"
          [definition]="invoiceDefinition"
          [yields]="invoiceYields"
          *ngIf="invoices"
        ></case-table>
        <hr class="mt-0" />
        <nav class="level mb-2">
          <!-- Left side -->
          <div class="level-left">
            <div class="level-item">
              <span class="icon-text">
                <span class="is-uppercase has-text-dark has-text-weight-medium">
                  Charges
                </span>
                <span class="icon">
                  <i class="icon icon-plus has-text-grey"></i>
                </span>
                <a
                  routerLink="/charges/create"
                  [queryParams]="createRelationForProjectQueryParams"
                  >Ajouter une charge</a
                >
              </span>
            </div>
          </div>

          <!-- Right side -->
          <div class="level-right">
            <p class="level-item">
              <span class="tag is-link-light">
                Total des charges (HT): {{ totalExpenseAmounts | euros }}</span
              >
            </p>
          </div>
        </nav>
        <case-table
          [items]="expenses"
          [definition]="expenseDefinition"
          [yields]="expenseYields"
          *ngIf="expenses"
        ></case-table>
      </div>
      <div class="column is-3">
        <div>
          <span class="icon-text pt-1">
            <span class="is-uppercase has-text-dark has-text-weight-medium">
              Commentaire financier
            </span>
            <span class="icon">
              <i class="icon icon-edit has-text-grey"></i>
            </span>
            <a
              [routerLink]="['/projets', project.id, 'edit']"
              fragment="financialComment"
              >Modifier</a
            >
          </span>
        </div>

        <div *ngIf="project.financialComment">
          <div class="message mt-2 is-small">
            <div
              class="message-body"
              [innerHtml]="project.financialComment"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
