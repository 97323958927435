import { Component, OnInit } from '@angular/core'
import { FormBuilder } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import {
  BreadcrumbService,
  CaseCreateEditComponent,
  caseCreateEditTemplate,
  EurosPipe,
  Field,
  FlashMessageService,
  InputType,
  ResourceDefinition,
  ResourceService
} from '@case-app/angular-library'
import * as moment from 'moment'
import { AcceptanceReport } from '../../../../src/resources/acceptance-report/acceptance-report.entity'
import { projectDefinition } from '../../project/project.definition'

import { invoiceDefinition } from '../invoice.definition'

@Component({ template: caseCreateEditTemplate })
export class InvoiceCreateEditComponent
  extends CaseCreateEditComponent
  implements OnInit
{
  definition: ResourceDefinition = invoiceDefinition
  fields: Field[] = [
    {
      id: 'projectId',
      label: 'Choisir un projet',
      placeholder: 'Projet',
      property: 'projectId',
      retrievedItemProperties: {
        projectId: 'project.id'
      },
      inputType: InputType.MultiSearch,
      maxSelectedItems: 1,
      searchResources: [projectDefinition],
      className: 'is-3',
      required: true,
      onChange: async (projectId: number) => {
        const orderField: Field = this.getFieldById('order')
        const acceptanceReportField: Field =
          this.getFieldById('acceptanceReports')

        this.setFieldValue(orderField, null)
        this.setFieldValue(acceptanceReportField, null)

        acceptanceReportField.hidden = true

        if (projectId) {
          orderField.hidden = false
          orderField.selectOptions =
            await this.componentResourceService.listSelectOptions('orders', {
              projectId,
              orderBy: 'number'
            })
        } else {
          orderField.hidden = true
        }
      }
    },
    {
      id: 'order',
      label: 'Commande',
      property: 'orderId',
      placeholder: 'Aucune',
      inputType: InputType.Select,
      selectOptions: [],
      hidden: true,
      onChange: async (newValue: string) => {
        const acceptanceReportField: Field =
          this.getFieldById('acceptanceReports')
        this.setFieldValue(acceptanceReportField, null)

        if (newValue && newValue !== 'null') {
          acceptanceReportField.hidden = false
          acceptanceReportField.selectOptions =
            await this.componentResourceService
              .list('acceptance-reports', {
                orderId: newValue,
                invoiceStatus: 'withoutInvoiceOnly',
                orderBy: 'name'
              })
              .then((acceptanceReports: AcceptanceReport[]) => {
                // Store in component to retrieve amount once selected (acceptanceReport field - onChange).
                this.acceptanceReports = acceptanceReports

                return acceptanceReports.map((aR: AcceptanceReport) => ({
                  label: `${aR.number} - ${this.eurosPipe.transform(
                    aR.amount
                  )}`,
                  value: aR.id
                }))
              })
        } else {
          acceptanceReportField.hidden = true
        }
      }
    },
    {
      id: 'acceptanceReports',
      label: 'PVR à facturer',
      property: 'acceptanceReportIds',
      inputType: InputType.MultiSelect,
      selectOptions: [],
      hidden: true,

      onChange: async (newValue: string[]) => {
        // Set amount as sum of selected acceptance reports.
        const amountField: Field = this.getFieldById('amount')
        const selectedAcceptanceReports = this.acceptanceReports.filter((aR) =>
          newValue.includes(aR.id.toString())
        )
        this.setFieldValue(
          amountField,
          selectedAcceptanceReports.reduce((sum, aR) => sum + aR.amount, 0)
        )
      }
    },
    {
      label: 'Numéro de facture',
      property: 'number',
      inputType: InputType.Text,
      className: 'is-3',
      required: true,
      initialValue: () =>
        this.componentResourceService
          .show('invoices', 'suggest-next-number')
          .then((res: { suggestedNumber: string }) => res.suggestedNumber)
    },
    {
      label: 'Date de création',
      property: 'issueDate',
      inputType: InputType.Datepicker,
      className: 'is-3',
      required: true,
      initialValue: moment()
    },
    {
      id: 'amount',
      label: 'Montant (€ HT)',
      property: 'amount',
      inputType: InputType.Number,
      className: 'is-3',
      required: true
    }
  ]

  acceptanceReports: AcceptanceReport[]

  constructor(
    formBuilder: FormBuilder,
    router: Router,
    activatedRoute: ActivatedRoute,
    resourceService: ResourceService,
    breadcrumbService: BreadcrumbService,
    flashMessageService: FlashMessageService,
    private componentResourceService: ResourceService,
    private eurosPipe: EurosPipe
  ) {
    super(
      formBuilder,
      router,
      breadcrumbService,
      resourceService,
      flashMessageService,
      activatedRoute
    )
  }

  async ngOnInit() {
    await this.initCreateEditView()

    if (this.form.get('projectId').value) {
      const orderField: Field = this.getFieldById('order')
      orderField.hidden = false

      orderField.selectOptions =
        await this.componentResourceService.listSelectOptions('orders', {
          projectId: this.form.get('projectId').value
        })
    }
  }
}
