import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { ResourceService } from '@case-app/angular-library'
import { environment } from '../../../../../environments/environment'
import { Project } from '../../../../../src/resources/project/project.entity'

@Component({
  selector: 'app-project-framework-modal',
  templateUrl: './project-framework-modal.component.html',
  styleUrls: ['./project-framework-modal.component.scss']
})
export class ProjectFrameworkModalComponent implements OnInit {
  @Input() project: Project
  @Input() isOnboarding: boolean = true

  @Output() onClose: EventEmitter<void> = new EventEmitter()

  form: FormGroup = this.formBuilder.group({
    projectFramework: ['', Validators.required]
  })

  setting: any

  constructor(
    private formBuilder: FormBuilder,
    private resourceService: ResourceService
  ) {}

  ngOnInit(): void {
    this.resourceService.show('settings', 'get').then((res) => {
      this.setting = res
    })
  }

  close() {
    this.onClose.emit()
  }

  downloadProjectFramework(form: FormGroup) {
    window.open(
      `${environment.storagePath}/${this.setting[form.value.projectFramework]}`
    )
    this.close()
  }
}
