import { Component, Inject, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'

import {
  CaseListComponent,
  CaseConfig,
  ResourceDefinition,
  Yield,
  BreadcrumbService,
  FlashMessageService,
  ResourceService,
  caseListTemplate,
  Filter,
  InputType,
  AuthService,
  FilterService
} from '@case-app/angular-library'

import { userDefinition } from './user.definition'
import { userYields } from './user.yields'

@Component({
  template: caseListTemplate
})
export class UserListComponent extends CaseListComponent implements OnInit {
  public static searchableFields: string[] = ['name', 'email']
  public static displayName: string = 'name'

  definition: ResourceDefinition = userDefinition
  yields: Yield[] = userYields

  filters: Filter[] = [
    {
      label: 'Rechercher un utlisateur',
      placeholder: 'Rechercher un utilisateur par nom...',
      inputType: InputType.MultiSearch,
      searchResources: [userDefinition],
      properties: {
        userIds: 'userIds'
      }
    },
    {
      label: 'Inclure les collaborateurs inactifs',
      inputType: InputType.Checkbox,
      property: 'includeNotActive'
    }
  ]

  constructor(
    router: Router,
    activatedRoute: ActivatedRoute,
    breadcrumbService: BreadcrumbService,
    resourceService: ResourceService,
    flashMessageService: FlashMessageService,
    filterService: FilterService,
    authService: AuthService,
    @Inject('CASE_CONFIG_TOKEN') config: CaseConfig
  ) {
    super(
      router,
      activatedRoute,
      breadcrumbService,
      resourceService,
      flashMessageService,
      authService,
      filterService,
      config
    )
  }

  ngOnInit(): void {
    this.initListView()
  }
}
