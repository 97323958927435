import {
  ActionType,
  Gender,
  ResourceDefinition
} from '@case-app/angular-library'

export const acceptanceReportDefinition: ResourceDefinition = {
  title: 'PVs de réceptions',
  nameSingular: 'PV de réception',
  namePlural: 'PVs de réceptions',
  gender: Gender.Masculine,
  mainIdentifier: 'number',
  slug: 'acceptance-reports',
  path: 'pvrs',
  className: 'AcceptanceReport',
  icon: 'icon-file',
  hasDetailPage: false,
  hasListPage: false,
  buttons: [],
  defaultLink: null,
  childrenThatPreventDelete: [],
  dropdownLinks: [
    {
      label: 'Supprimer',
      permission: 'deleteAcceptanceReports',
      action: (aR) => ({
        type: ActionType.Delete,
        delete: {
          itemToDelete: aR,
          definition: acceptanceReportDefinition
        }
      })
    }
  ]
}
