import { Gender, LinkType, ResourceDefinition } from '@case-app/angular-library'

export const timeSlotDefinition: ResourceDefinition = {
  title: 'Imputation',
  nameSingular: 'imputation',
  namePlural: 'imputations',
  gender: Gender.Masculine,
  mainIdentifier: 'displayName',
  slug: 'time-slots',
  path: 'NOT APPLICABLE',
  className: 'Statement',
  icon: 'icon-grid',
  hasDetailPage: false,
  hasListPage: false,
  buttons: [],
  defaultLink: LinkType.EDIT,
  childrenThatPreventDelete: [],
  dropdownLinks: []
}
