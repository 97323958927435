import { Component, Inject, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import {
  AuthService,
  BreadcrumbService,
  CaseConfig,
  CaseListComponent,
  EurosPipe,
  Filter,
  FilterService,
  FlashMessageService,
  InputType,
  ResourceDefinition,
  ResourceService
} from '@case-app/angular-library'
import { ChartDataSets, ChartOptions, ChartTooltipItem } from 'chart.js'
import { Labels } from 'ng-chartjs'

import { appConstants } from '../../../../../shared/constants/app.constants'
import { User } from '../../../src/resources/user/user.entity'
import { ChartItem } from '../chart-item.interface'
import { monitoringSubtractionDefinition } from './monitoring-subtraction.definition'

@Component({
  selector: 'app-monitoring-subtraction',
  templateUrl: './monitoring-subtraction.component.html',
  styleUrls: ['./monitoring-subtraction.component.scss']
})
export class MonitoringSubtractionComponent
  extends CaseListComponent
  implements OnInit
{
  definition: ResourceDefinition = monitoringSubtractionDefinition

  total: number
  filters: Filter[] = [
    {
      label: `Chargé d'affaire`,
      property: 'businessEngineerId',
      placeholder: 'Tous les CAs',
      inputType: InputType.Select,
      className: 'is-4',
      selectOptions: () =>
        this.componentResourceService.listSelectOptions('users', {
          roleNames: [
            appConstants.ROLES.ADMIN_ROLE_NAME,
            appConstants.ROLES.BUSINESS_ENGINEER_ROLE_NAME
          ],
          orderBy: 'name'
        })
    }
  ]

  isIndividualAnalytics: boolean

  chartItems: ChartItem[]
  barChartLabels: Labels
  barChartData: ChartDataSets[] = []
  barChartOptions: ChartOptions = {
    scales: {
      yAxes: [
        {
          gridLines: {
            borderDash: [8, 4],
            color: '#e9eeff'
          },
          ticks: { fontColor: '#b0bac9' }
        }
      ],
      xAxes: [
        {
          gridLines: {
            borderDash: [8, 4],
            color: '#e9eeff'
          },
          ticks: {
            fontColor: '#b0bac9',
            beginAtZero: true,
            callback: (value) => this.eurosPipe.transform(value as number)
          }
        }
      ]
    },
    tooltips: {
      callbacks: {
        label: (tooltipItem: ChartTooltipItem) =>
          this.eurosPipe.transform(tooltipItem.xLabel as number)
      },
      backgroundColor: '#FFF',
      titleFontSize: 16,
      titleFontColor: '#0066ff',
      bodyFontColor: '#000',
      bodyFontSize: 14,
      displayColors: false
    },
    responsive: true
  }

  constructor(
    resourceService: ResourceService,
    activatedRoute: ActivatedRoute,
    router: Router,
    breadcrumbService: BreadcrumbService,
    flashMessageService: FlashMessageService,
    filterService: FilterService,
    authService: AuthService,
    @Inject('CASE_CONFIG_TOKEN') config: CaseConfig,
    private eurosPipe: EurosPipe,
    private componentRouter: Router,
    private componentResourceService: ResourceService,
    private componentAuthService: AuthService,
    private componentActivatedRoute: ActivatedRoute
  ) {
    super(
      router,
      activatedRoute,
      breadcrumbService,
      resourceService,
      flashMessageService,
      authService,
      filterService,
      config
    )
  }

  async ngOnInit() {
    this.componentAuthService.currentUser.subscribe(
      async (currentUser: User) => {
        // Set businessEngineer filter to current user's if user has no full rights.
        this.isIndividualAnalytics = !(await this.componentAuthService.can(
          'browseAnalytics'
        ))
        const businessEngineerId =
          this.componentActivatedRoute.snapshot.queryParams.businessEngineerId

        if (this.isIndividualAnalytics && !businessEngineerId) {
          this.componentRouter.navigate(['/monitoring', 'subtraction'], {
            queryParams: {
              businessEngineerId: currentUser.id
            },
            queryParamsHandling: 'merge'
          })
        }

        if (this.isIndividualAnalytics) {
          const businessEngineerFilter: Filter = this.filters.find(
            (f) => f.property === 'businessEngineerId'
          )
          businessEngineerFilter.readonly = true
        }

        this.paginator$.subscribe((paginator: any) => {
          this.chartItems = paginator as ChartItem[]

          this.barChartLabels = this.chartItems.map((cI: ChartItem) => cI.label)

          this.barChartData = [
            {
              data: this.chartItems
                .slice(0, 10)
                .map((cI: ChartItem) => cI.value),
              backgroundColor: '#5065ff',
              hoverBackgroundColor: '#5065ff'
            }
          ]

          this.total = this.chartItems.reduce(
            (prev: number, curr: ChartItem) => prev + curr.value,
            0
          )
        })

        this.initListView()
      }
    )
  }

  onChartClick(event: { active: { _index: number }[] }) {
    if (event.active.length) {
      const project: ChartItem = this.chartItems[event.active[0]._index]
      this.componentRouter.navigate(['/projets', project.id])
    }
  }
}
