import { Component, Input, OnInit } from '@angular/core'
import {
  ActionType,
  ResourceDefinition,
  ResourceMode,
  ResourceService,
  Yield,
  YieldType
} from '@case-app/angular-library'
import { Action } from '@case-app/angular-library/lib/interfaces/actions/action.interface'

import { AcceptanceReport } from '../../../../../src/resources/acceptance-report/acceptance-report.entity'
import { Expense } from '../../../../../src/resources/expense/expense.entity'
import { Invoice } from '../../../../../src/resources/invoice/invoice.entity'
import { Order } from '../../../../../src/resources/order/order.entity'
import { Project } from '../../../../../src/resources/project/project.entity'
import { acceptanceReportDefinition } from '../../../acceptance-report/acceptance-report.definition'
import { expenseDefinition } from '../../../expense/expense.definition'
import { expenseYields } from '../../../expense/expense.yields'
import { invoiceDefinition } from '../../../invoice/invoice.definition'
import { orderDefinition } from '../../../order/order.definition'

@Component({
  selector: 'app-project-tab-accounting',
  templateUrl: './project-tab-accounting.component.html',
  styleUrls: ['./project-tab-accounting.component.scss']
})
export class ProjectTabAccountingComponent implements OnInit {
  @Input() project: Project
  @Input() createAcceptanceReportAction: Action
  @Input() createRelationForProjectQueryParams: { [key: string]: string }

  orders: Order[]
  totalOrderAmounts: number
  orderDefinition: ResourceDefinition = orderDefinition

  invoices: Invoice[]
  totalInvoiceAmounts: number
  invoiceDefinition: ResourceDefinition = invoiceDefinition

  acceptanceReports: AcceptanceReport[]
  totalAcceptanceReportAmounts: number
  acceptanceReportDefinition: ResourceDefinition = acceptanceReportDefinition

  expenses: Expense[]
  totalExpenseAmounts: number
  expenseDefinition: ResourceDefinition = expenseDefinition

  orderYields: Yield[] = [
    {
      label: 'N°',
      property: 'number'
    },
    {
      label: 'Date',
      property: 'date',
      type: YieldType.Date
    },
    {
      label: 'Montant (HT)',
      property: 'amount',
      type: YieldType.Currency
    },
    {
      label: 'Montant restant (HT)',
      property: 'remainingAmount',
      type: YieldType.Currency
    }
  ]
  acceptanceReportYields: Yield[] = [
    {
      label: 'N° et description du PVR',
      property: 'label'
    },
    {
      label: 'Date',
      property: 'date',
      type: YieldType.Date
    },
    {
      label: 'Montant HT',
      property: 'amount',
      type: YieldType.Currency
    },
    {
      label: 'Commande associée',
      property: 'order.number',
      link: (aR: AcceptanceReport) => `/commandes/${aR.order.id}`
    }
  ]
  invoiceYields: Yield[] = [
    {
      label: 'N°',
      property: 'number'
    },
    {
      label: 'Date',
      property: 'issueDate',
      type: YieldType.Date
    },
    {
      label: 'N° PVR',
      property: 'acceptanceReportNumbers'
    },
    {
      label: 'Montant HT',
      property: 'amount',
      type: YieldType.Currency
    },
    {
      label: 'TVA',
      property: 'taxes',
      type: YieldType.Currency
    },
    {
      label: 'Montant TTC',
      property: 'amountWithTaxes',
      type: YieldType.Currency
    },
    {
      label: 'Paiement',
      property: 'payedAt',
      type: YieldType.Date
    },
    {
      label: '',
      property: 'path',
      type: YieldType.Download
    }
  ]
  expenseYields: Yield[] = expenseYields
  constructor(private resourceService: ResourceService) {}

  ngOnInit(): void {
    this.resourceService
      .list('orders', {
        projectIds: [this.project.id],
        withoutPagination: true
      })
      .then((orderRes: Order[]) => {
        this.orders = orderRes
        this.totalOrderAmounts = this.orders.reduce(
          (sum, curr) => sum + curr.amount,
          0
        )
      })

    this.resourceService
      .list('invoices', {
        projectId: this.project.id,
        withoutPagination: true
      })
      .then((invoiceRes: Invoice[]) => {
        this.invoices = invoiceRes
        this.totalInvoiceAmounts = this.invoices.reduce(
          (sum, curr) => sum + curr.amount,
          0
        )
      })

    this.resourceService
      .list('acceptance-reports', {
        projectId: this.project.id,
        withoutPagination: true
      })
      .then((acceptanceReportRes: AcceptanceReport[]) => {
        this.acceptanceReports = acceptanceReportRes
        this.totalAcceptanceReportAmounts = this.acceptanceReports.reduce(
          (sum, curr) => sum + curr.amount,
          0
        )
      })

    this.resourceService
      .list('expenses', {
        projectId: this.project.id,
        withoutPagination: true
      })
      .then((expenseRes: Expense[]) => {
        this.expenses = expenseRes
        this.totalExpenseAmounts = this.expenses.reduce(
          (sum, curr) => sum + curr.amount,
          0
        )
      })

    if (
      this.acceptanceReportDefinition.dropdownLinks &&
      !this.acceptanceReportDefinition.dropdownLinks.find(
        (dL) => dL.label === 'Modifier'
      )
    ) {
      this.acceptanceReportDefinition.dropdownLinks.unshift({
        label: 'Modifier',
        condition: (aR: AcceptanceReport) => !aR.invoice,
        action: (aR) => ({
          type: ActionType.OpenCreateEditModal,
          openCreateEditModal: Object.assign(
            // Clone object to prevent modifying original one.
            { ...this.createAcceptanceReportAction.openCreateEditModal },
            {
              title: 'Modifier PV de récéption',
              mode: ResourceMode.Edit,
              item: Object.assign(aR, { orderId: aR.order.id.toString() })
            }
          )
        })
      })
    }
  }
}
