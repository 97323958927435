import { Yield, YieldType } from '@case-app/angular-library'

export const orderYields: Yield[] = [
  {
    label: 'N°',
    property: 'number'
  },
  {
    label: 'N° de projet',
    property: 'project.number',
    link: (o) => `/projets/${o.project.id}`
  },
  {
    label: 'Date',
    property: 'date',
    type: YieldType.Date
  },
  {
    label: 'Description',
    property: 'description'
  },
  {
    label: 'Montant (HT)',
    property: 'amount',
    type: YieldType.Currency
  },
  {
    label: 'Montant restant (HT)',
    property: 'remainingAmount',
    className: 'has-text-right',
    disableOrderBy: true,
    type: YieldType.Currency
  }
]
