import { Component, Input } from '@angular/core'

@Component({
  selector: 'app-monitoring-tabs',
  templateUrl: './monitoring-tabs.component.html',
  styleUrls: ['./monitoring-tabs.component.scss']
})
export class MonitoringTabsComponent {
  @Input() selectedTab: string
}
