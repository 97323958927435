<nav class="level">
  <!-- Left side -->
  <div class="level-left">
    <div class="level-item">
      <span class="icon is-large">
        <i class="icon icon-bar-chart is-size-2 has-text-link"></i>
      </span>
    </div>
    <div class="level-item">
      <h1 class="title is-2 has-text-weight-light">Monitoring</h1>
    </div>
  </div>
</nav>
<!-- Filters -->
<section>
  <div class="card mb-6 mt-4">
    <app-monitoring-tabs [selectedTab]="'lateness'"></app-monitoring-tabs>
    <div class="p-4">
      <div class="columns">
        <div class="column">
          <h2
            class="title is-6 has-text-weight-bold is-uppercase has-text-grey"
          >
            Filtres
          </h2>
        </div>
      </div>
      <div
        class="columns is-multiline is-align-items-flex-end"
        *ngIf="isFilterSelectOptionsFetched"
      >
        <div
          class="column"
          [ngClass]="filter.className"
          *ngFor="let filter of filters"
        >
          <case-input
            [type]="filter.inputType"
            [initialValue]="filter.value"
            [selectOptions]="filter.selectOptions"
            [label]="filter.label"
            [searchResources]="filter.searchResources"
            [readonly]="filter.readonly"
            [required]="filter.required"
            [placeholder]="filter.placeholder"
            (valueChanged)="onFilterValueChanged($event, filter)"
          ></case-input>
        </div>
      </div>
    </div>
  </div>
</section>

<div class="columns">
  <!-- Pie chart -->
  <div class="column is-5">
    <div class="card is-fullheight p-4">
      <div style="display: block">
        <h2 class="title is-6 has-text-weight-bold is-uppercase has-text-grey">
          Résumé en pourcentage des délais
        </h2>

        <div style="display: block">
          <canvas
            ngChartjs
            [data]="pieChartData"
            [labels]="pieChartLabels"
            [colors]="pieChartColors"
            [options]="pieChartOptions"
            [chartType]="'doughnut'"
            [legend]="false"
            *ngIf="pieChartData"
          >
          </canvas>
        </div>
      </div>
    </div>
  </div>

  <!-- Bar chart-->
  <div class="column is-7">
    <div class="card is-fullheight p-4">
      <div style="display: block">
        <h2 class="title is-6 has-text-weight-bold is-uppercase has-text-grey">
          Delais des projets
        </h2>

        <div style="display: block">
          <canvas
            ngChartjs
            [data]="barChartData"
            [labels]="barChartLabels"
            [options]="barChartOptions"
            chartType="horizontalBar"
            [legend]="false"
            (chartClick)="onChartClick($event)"
            *ngIf="barChartData"
          >
          </canvas>
        </div>
      </div>
    </div>
  </div>
</div>
