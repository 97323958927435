import { Route } from '@angular/router'

import {
  AuthGuard,
  PermissionGuard,
  ResourceMode
} from '@case-app/angular-library'

import { CustomerCreateEditComponent } from './customer-create-edit/customer-create-edit.component'
import { CustomerListComponent } from './customer-list/customer-list.component'

export const customerRoutes: Route[] = [
  {
    path: 'clients',
    component: CustomerListComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permission: 'browseCustomers'
    }
  },
  {
    path: 'clients/create',
    component: CustomerCreateEditComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permission: 'addCustomers',
      mode: ResourceMode.Create
    }
  },
  {
    path: 'clients/:id/edit',
    component: CustomerCreateEditComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      mode: ResourceMode.Edit,
      permission: 'editCustomers'
    }
  }
]
