import { Route } from '@angular/router'
import {
  AuthGuard,
  PermissionGuard,
  ResourceMode
} from '@case-app/angular-library'

import { ExpenseCreateEditComponent } from './expense-create-edit/expense-create-edit.component'

export const expenseRoutes: Route[] = [
  {
    path: 'charges/create',
    component: ExpenseCreateEditComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permission: 'addExpenses',
      mode: ResourceMode.Create
    }
  },
  {
    path: 'charges/:id/edit',
    component: ExpenseCreateEditComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      mode: ResourceMode.Edit,
      permission: 'editExpenses'
    }
  }
]
