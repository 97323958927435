import { Component, OnInit } from '@angular/core'
import { FormBuilder } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import {
  BreadcrumbService,
  CaseCreateEditComponent,
  caseCreateEditTemplate,
  Field,
  FlashMessageService,
  InputType,
  ResourceDefinition,
  ResourceService
} from '@case-app/angular-library'
import * as moment from 'moment'

import { projectDefinition } from '../../project/project.definition'
import { expenseDefinition } from '../expense.definition'

@Component({ template: caseCreateEditTemplate })
export class ExpenseCreateEditComponent
  extends CaseCreateEditComponent
  implements OnInit
{
  definition: ResourceDefinition = expenseDefinition
  fields: Field[] = [
    {
      id: 'projectId',
      label: 'Choisir un projet',
      placeholder: 'Projet',
      property: 'projectId',
      retrievedItemProperties: {
        projectId: 'project.id'
      },
      inputType: InputType.MultiSearch,
      maxSelectedItems: 1,
      searchResources: [projectDefinition],
      className: 'is-3',
      required: true
    },
    {
      label: 'Libellé',
      property: 'reference',
      inputType: InputType.Text,
      className: 'is-3',
      required: true
    },
    {
      label: 'Date',
      property: 'date',
      inputType: InputType.Datepicker,
      className: 'is-3',
      initialValue: moment()
    },
    {
      label: 'Montant (€ HT)',
      property: 'amount',
      inputType: InputType.Number,
      className: 'is-3',
      required: true
    }
  ]

  constructor(
    formBuilder: FormBuilder,
    router: Router,
    activatedRoute: ActivatedRoute,
    resourceService: ResourceService,
    breadcrumbService: BreadcrumbService,
    flashMessageService: FlashMessageService
  ) {
    super(
      formBuilder,
      router,
      breadcrumbService,
      resourceService,
      flashMessageService,
      activatedRoute
    )
  }

  ngOnInit() {
    this.initCreateEditView()
  }
}
