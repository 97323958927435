import { Component, Inject, Input, OnInit } from '@angular/core'
import { CaseConfig, ResourceService, User } from '@case-app/angular-library'

import { Project } from '../../../../../src/resources/project/project.entity'

@Component({
  selector: 'app-project-tab-time-slots',
  templateUrl: './project-tab-time-slots.component.html',
  styleUrls: ['./project-tab-time-slots.component.scss']
})
export class ProjectTabTimeSlotsComponent implements OnInit {
  @Input() project: Project

  users: User[]
  expandedUserId: number

  constructor(
    private resourceService: ResourceService,
    @Inject('CASE_CONFIG_TOKEN') private config: CaseConfig
  ) {}

  ngOnInit() {
    this.resourceService
      .list('time-slots/by-user', { projectId: this.project.id })
      .then((userRes: User[]) => {
        this.users = userRes
      })
  }

  toggleExpandedUser(userId: number) {
    if (this.expandedUserId === userId) {
      delete this.expandedUserId
    } else {
      this.expandedUserId = userId
    }
  }

  export() {
    this.resourceService
      .list('time-slots/by-user', { projectId: this.project.id, toXLS: true })
      .then((res: { filePath: string }) => {
        window.open(`${this.config.storagePath}/${res.filePath}`)
      })
  }
}
