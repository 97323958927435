import {
  ActionType,
  Gender,
  LinkType,
  ResourceDefinition
} from '@case-app/angular-library'
import { Referent } from '../../../src/resources/referent/referent.entity'

export const referentDefinition: ResourceDefinition = {
  title: 'Responsables',
  nameSingular: 'responsable',
  namePlural: 'responsables',
  gender: Gender.Masculine,
  mainIdentifier: 'name',
  slug: 'referents',
  path: 'responsables',
  className: 'Referent',
  icon: 'icon-users',
  hasDetailPage: false,
  hasListPage: true,
  buttons: [LinkType.CREATE],
  defaultLink: LinkType.EDIT,
  childrenThatPreventDelete: [],
  dropdownLinks: [
    {
      label: 'Modifier',
      permission: 'editReferents',
      action: (referent) => ({
        type: ActionType.Link,
        link: {
          path: `${referentDefinition.path}/${referent.id}/edit`
        }
      })
    },
    {
      label: 'Supprimer',
      permission: 'deleteReferents',
      action: (referent) => ({
        type: ActionType.Delete,
        delete: {
          itemToDelete: referent,
          definition: referentDefinition
        }
      }),
      disabled: (referent: Referent) =>
        !!referent.projectAsReferent1 ||
        !!referent.projectAsReferent2 ||
        !!referent.projectAsReferent3,
      tooltip: (referent: Referent) =>
        !!referent.projectAsReferent1 ||
        !!referent.projectAsReferent2 ||
        !!referent.projectAsReferent3
          ? `Impossible de supprimer un responsable qui a des projets reliés. Veuillez d'abord le retirer de ce(s) projet(s) ou bien supprimer le(s) projet(s).`
          : null
    }
  ]
}
