import {
  ActionType,
  Gender,
  LinkType,
  ResourceDefinition
} from '@case-app/angular-library'
import { Order } from '../../../src/resources/order/order.entity'

export const orderDefinition: ResourceDefinition = {
  title: 'Commandes',
  nameSingular: 'commande',
  namePlural: 'commandes',
  gender: Gender.Feminine,
  mainIdentifier: 'number',
  slug: 'orders',
  path: 'commandes',
  className: 'Order',
  icon: 'icon-box',
  hasDetailPage: false,
  hasListPage: true,
  buttons: [LinkType.CREATE, LinkType.EXPORT],
  defaultLink: LinkType.DETAIL,
  childrenThatPreventDelete: [],
  dropdownLinks: [
    {
      label: 'Modifier',
      permission: 'editOrders',
      action: (order) => ({
        type: ActionType.Link,
        link: {
          path: `${orderDefinition.path}/${order.id}/edit`
        }
      })
    },
    {
      label: 'Supprimer',
      permission: 'deleteOrders',
      action: (order) => ({
        type: ActionType.Delete,
        delete: {
          itemToDelete: order,
          definition: orderDefinition
        }
      }),
      disabled: (order: Order) => !!order.acceptanceReports.length,
      tooltip: (order: Order) =>
        !!order.acceptanceReports.length
          ? `Impossible de supprimer car certains PVR sont reliés à cette commande.`
          : null
    }
  ]
}
