import { Component, OnInit } from '@angular/core'
import { FormBuilder } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import {
  BreadcrumbService,
  CaseCreateEditComponent,
  caseCreateEditTemplate,
  Field,
  FlashMessageService,
  InputType,
  ResourceDefinition,
  ResourceMode,
  ResourceService
} from '@case-app/angular-library'

import { appConstants } from '../../../../../../shared/constants/app.constants'
import { ProjectStatus } from '../../../../../../shared/enums/project-status.enum'
import { customerDefinition } from '../../customer/customer.definition'
import { referentDefinition } from '../../referent/referent.definition'
import { projectDefinition } from '../project.definition'

@Component({ template: caseCreateEditTemplate })
export class ProjectCreateEditComponent
  extends CaseCreateEditComponent
  implements OnInit
{
  definition: ResourceDefinition = projectDefinition

  fields: Field[] = [
    {
      id: 'customer',
      label: 'Choisir un client',
      property: 'customerId',
      retrievedItemProperties: {
        customerId: 'customer.id'
      },
      inputType: InputType.MultiSearch,
      searchResources: [customerDefinition],
      className: 'is-12',
      required: true,
      maxSelectedItems: 1,
      onChange: async (customerId: number) => {
        Array(1, 2, 3).forEach((referentNumber: number) => {
          const referentField: Field = this.getFieldById(
            `referent${referentNumber}`
          )
          this.setFieldValue(referentField, { referentId: null })

          if (customerId) {
            referentField.hidden = false
            referentField.searchParams = {
              customerId: this.form.value.customerId
            }
            referentField.placeholder = `Sélectionnez jusqu'à 3 personnes responsables...`
          } else {
            referentField.hidden = true
          }
        })
      }
    },
    {
      id: 'referent1',
      hidden: true,
      label: 'Responsable 1 du projet (côté client)',
      placeholder: `Selectionnez un client pour voir la liste ...`,
      inputType: InputType.MultiSearch,
      searchResources: [referentDefinition],
      property: 'referent1Id',
      retrievedItemProperties: {
        referent1Id: 'referent1.id'
      },
      maxSelectedItems: 1,
      className: 'is-4',
      required: true
    },
    {
      id: 'referent2',
      hidden: true,
      label: 'Responsable 2 du projet (côté client)',
      placeholder: `Selectionnez un client pour voir la liste ...`,
      inputType: InputType.MultiSearch,
      searchResources: [referentDefinition],
      property: 'referent2Id',
      retrievedItemProperties: {
        referent2Id: 'referent2.id'
      },
      maxSelectedItems: 1,
      className: 'is-4'
    },
    {
      id: 'referent3',
      hidden: true,
      label: 'Responsable 3 du projet (côté client)',
      placeholder: `Selectionnez un client pour voir la liste ...`,
      inputType: InputType.MultiSearch,
      searchResources: [referentDefinition],
      property: 'referent3Id',
      retrievedItemProperties: {
        referent3Id: 'referent3.id'
      },
      maxSelectedItems: 1,
      className: 'is-4'
    },
    {
      id: 'startDate',
      label: 'Date de creation',
      placeholder: 'Date de création',
      property: 'startDate',
      inputType: InputType.Datepicker,
      className: 'is-6',
      required: true
    },
    {
      label: 'Numéro du projet',
      property: 'number',
      inputType: InputType.Text,
      className: 'is-6',
      required: true,
      initialValue: () =>
        this.componentResourceService
          .show('projects', 'suggest-next-number')
          .then((res: { suggestedNumber: string }) => res.suggestedNumber)
    },
    {
      id: 'type',
      label: ' Type de Projet',
      property: 'typeId',
      placeholder: 'Sélectionnez un type de projet',
      retrievedItemProperties: {
        typeId: 'type.id'
      },
      inputType: InputType.Select,
      className: 'is-6',
      required: true,
      selectOptions: () =>
        this.componentResourceService.listSelectOptions('types', {
          orderBy: 'name'
        })
    },
    {
      id: 'cableLength',
      label: ' Quantitatif',
      property: 'cableLength',
      inputType: InputType.Text,
      className: 'is-6'
    },

    {
      id: 'externalId',
      label: ' Numéro de projet client',
      property: 'externalId',
      inputType: InputType.Text,
      className: 'is-6'
    },
    {
      id: 'address',
      label: ' Adresse',
      property: 'address',
      inputType: InputType.Address,
      className: 'is-6',
      required: true
    },
    {
      id: 'description',
      label: 'Description',
      property: 'description',
      inputType: InputType.RichText,
      className: 'is-12'
    },
    {
      id: 'estimatedEndDate',
      label: 'Date estimée de fin',
      property: 'estimatedEndDate',
      inputType: InputType.Datepicker,
      className: 'is-6',
      required: true
    },
    {
      id: 'endDate',
      label: 'Date de fin',
      property: 'endDate',
      inputType: InputType.Datepicker,
      className: 'is-6'
    },
    {
      id: 'status',
      label: ' Statut du projet',
      property: 'status',
      inputType: InputType.Select,
      className: 'is-4',
      required: true,
      selectOptions: Object.values(ProjectStatus).map((projectStatus) => ({
        label: projectStatus,
        value: projectStatus
      }))
    },
    {
      id: 'businessEngineerId',
      label: `Chargé d'affaire`,
      property: 'businessEngineerId',
      retrievedItemProperties: {
        businessEngineerId: 'businessEngineer.id'
      },
      inputType: InputType.Select,
      required: true,
      className: 'is-4',
      selectOptions: () =>
        this.componentResourceService.listSelectOptions('users', {
          roleNames: [
            appConstants.ROLES.ADMIN_ROLE_NAME,
            appConstants.ROLES.BUSINESS_ENGINEER_ROLE_NAME
          ],
          orderBy: 'name'
        })
    },
    {
      id: 'studyManagerId',
      label: `Chargé d'étude`,
      property: 'studyManagerId',
      placeholder: 'Aucun',
      retrievedItemProperties: {
        studyManagerId: 'studyManager.id'
      },
      inputType: InputType.Select,
      className: 'is-4',
      selectOptions: () =>
        this.componentResourceService.listSelectOptions('users', {
          roleNames: [
            appConstants.ROLES.STUDY_MANAGER_ROLE_NAME,
            appConstants.ROLES.BUSINESS_ENGINEER_ROLE_NAME
          ],
          orderBy: 'name'
        })
    },
    {
      id: 'technicalComment',
      label: `Commentaire technique`,
      property: 'technicalComment',
      inputType: InputType.RichText,
      className: 'is-6'
    },
    {
      id: 'financialComment',
      label: `Commentaire finance`,
      property: 'financialComment',
      inputType: InputType.RichText,
      className: 'is-6'
    },
    {
      id: 'isActive',
      label: 'Projet actif',
      permission: 'archiveProjects',
      helpText: `Seuls les projets actifs peuvent recevoir des imputations et apparaissent par défaut dans la liste des projets.`,
      property: 'isActive',
      initialValue: true,
      className: 'is-12',
      inputType: InputType.Checkbox
    }
  ]

  constructor(
    formBuilder: FormBuilder,
    router: Router,
    activatedRoute: ActivatedRoute,
    resourceService: ResourceService,
    breadcrumbService: BreadcrumbService,
    flashMessageService: FlashMessageService,
    private componentResourceService: ResourceService
  ) {
    super(
      formBuilder,
      router,
      breadcrumbService,
      resourceService,
      flashMessageService,
      activatedRoute
    )
  }

  async ngOnInit() {
    await this.initCreateEditView()

    if (this.mode === ResourceMode.Edit) {
      Array(1, 2, 3).forEach((referentNumber: number) => {
        const referentField: Field = this.getFieldById(
          `referent${referentNumber}`
        )
        referentField.hidden = false
        referentField.searchParams = {
          customerId: this.form.value.customerId
        }
      })
    }
  }
}
