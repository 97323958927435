import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { FormBuilder } from '@angular/forms'

import {
  CaseCreateEditComponent,
  ResourceDefinition,
  Field,
  Filter,
  InputType,
  BreadcrumbService,
  FlashMessageService,
  ResourceService,
  caseCreateEditTemplate
} from '@case-app/angular-library'

import { typeDefinition } from '../type.definition'

@Component({ template: caseCreateEditTemplate })
export class TypeCreateEditComponent
  extends CaseCreateEditComponent
  implements OnInit
{
  definition: ResourceDefinition = typeDefinition
  fields: Field[] = [
    {
      label: 'Nom du type',
      placeholder: 'Entrer un nom...',
      property: 'name',
      inputType: InputType.Text,
      required: true,
      className: 'is-6'
    },
    {
      label: 'Couleur',
      placeholder: 'Entrer une couleur...',
      property: 'color',
      inputType: InputType.ColorPicker,
      required: true,
      className: 'is-6'
    }
  ]

  constructor(
    formBuilder: FormBuilder,
    router: Router,
    activatedRoute: ActivatedRoute,
    resourceService: ResourceService,
    breadcrumbService: BreadcrumbService,
    flashMessageService: FlashMessageService
  ) {
    super(
      formBuilder,
      router,
      breadcrumbService,
      resourceService,
      flashMessageService,
      activatedRoute
    )
  }

  ngOnInit() {
    this.initCreateEditView()
  }
}
