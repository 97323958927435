import { YieldType, Yield } from '@case-app/angular-library'

export const userYields: Yield[] = [
  {
    label: 'Collaborateur',
    property: 'imageObjects',
    secondProperty: 'name',
    orderByProperty: 'name',
    type: YieldType.Image,
    className: 'is-narrow'
  },
  {
    label: 'E-mail',
    property: 'email'
  },
  {
    label: 'Rôle',
    property: 'role.displayName'
  },
  {
    label: 'Tx horaire',
    property: 'hourlyRate',
    type: YieldType.Currency
  },
  {
    label: 'Objectif',
    property: 'objective',
    disableOrderBy: true
  },
  {
    label: 'Actif',
    property: 'isActive',
    type: YieldType.Check
  }
]
