<p class="title--small">Rentabilité du projet</p>
<table class="table is-fullwidth is-narrow">
  <tbody>
    <tr *ngIf="businessEngineerCost">
      <td class="pl-0">Chargé d'affaire:</td>
      <td class="pr-0 has-text-right">{{ businessEngineerCost | euros }}</td>
    </tr>
    <tr *ngIf="studyManagerCost">
      <td class="pl-0">Chargé d'étude:</td>
      <td class="pr-0 has-text-right">{{ studyManagerCost | euros }}</td>
    </tr>
    <tr *ngIf="totalTimeSlotCost">
      <td class="pl-0">Total imputé:</td>
      <td class="pr-0 has-text-right">{{ totalTimeSlotCost | euros }}</td>
    </tr>
    <tr *ngIf="contradictoryStatementsAmount">
      <td class="pl-0">Rentabilité (Σ factures / heures totales):</td>
      <td class="pr-0 has-text-right">
        <ng-container *ngIf="totalInvoices && totalHours"
          >{{ totalInvoices / totalHours | euros }} / heure</ng-container
        >
        <ng-container *ngIf="!totalInvoices || !totalHours">-</ng-container>
      </td>
    </tr>
    <tr *ngIf="totalExpenses">
      <td class="pl-0">Total des charges:</td>
      <td class="pr-0 has-text-right">{{ totalExpenses | euros }}</td>
    </tr>
    <tr *ngIf="totalExpenses || contradictoryStatementsAmount">
      <td class="pl-0">Résultat (Σ factures - Σ charges - total imputé):</td>
      <td class="pr-0 has-text-right">
        {{ totalInvoices - totalExpenses - totalTimeSlotCost | euros }}
      </td>
    </tr>
  </tbody>
</table>

<!-- Pie chart -->
<div style="display: block">
  <canvas
    ngChartjs
    [data]="pieChartData"
    [labels]="pieChartLabels"
    [colors]="pieChartColors"
    [options]="pieChartOptions"
    [chartType]="'doughnut'"
    [legend]="false"
    *ngIf="pieChartData"
    style="margin: auto; max-width: 100%"
  >
  </canvas>
</div>
