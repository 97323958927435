import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import {
  BreadcrumbService,
  CaseDetailComponent,
  FlashMessageService,
  ResourceDefinition,
  ResourceService
} from '@case-app/angular-library'
import { User } from '../../../../src/resources/user/user.entity'

import { userDefinition } from '../user.definition'

@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.scss']
})
export class UserDetailComponent extends CaseDetailComponent implements OnInit {
  definition: ResourceDefinition = userDefinition
  selectedTab: string

  item: User

  constructor(
    breadcrumbService: BreadcrumbService,
    resourceService: ResourceService,
    flashMessageService: FlashMessageService,
    activatedRoute: ActivatedRoute,
    private componentActivatedRoute: ActivatedRoute
  ) {
    super(
      breadcrumbService,
      resourceService,
      flashMessageService,
      activatedRoute
    )
  }

  async ngOnInit(): Promise<void> {
    this.componentActivatedRoute.queryParams.subscribe(async (queryParams) => {
      this.selectedTab = queryParams.selectedTab || 'projects'

      await this.initDetailView()
    })
  }
}
