import {
  ActionType,
  Gender,
  LinkType,
  ResourceDefinition
} from '@case-app/angular-library'
import * as moment from 'moment'

import { Project } from '../../../src/resources/project/project.entity'
import { invoiceDefinition } from '../invoice/invoice.definition'

export const projectDefinition: ResourceDefinition = {
  title: 'Projets',
  nameSingular: 'projet',
  namePlural: 'projets',
  gender: Gender.Masculine,
  mainIdentifier: 'number',
  slug: 'projects',
  path: 'projets',
  className: 'Project',
  icon: 'icon-grid',
  hasDetailPage: true,
  hasListPage: true,
  buttons: [LinkType.CREATE, LinkType.EXPORT],
  defaultLink: LinkType.DETAIL,
  childrenThatPreventDelete: [],
  dropdownLinks: [
    {
      label: 'Ouvrir',
      permission: 'readProjects',
      action: (project) => ({
        type: ActionType.Link,
        link: {
          path: `${projectDefinition.path}/${project.id}`
        }
      })
    },
    {
      label: 'Modifier',
      permission: 'editProjects',
      action: (project) => ({
        type: ActionType.Link,
        link: {
          path: `${projectDefinition.path}/${project.id}/edit`
        }
      })
    },
    {
      label: 'Dupliquer',
      permission: 'addProjects',
      action: (project: Project) => ({
        type: ActionType.Link,
        link: {
          path: `${projectDefinition.path}/create`,
          queryParams: {
            specialRules: JSON.stringify([
              {
                fieldId: 'customer',
                forcedValue: project.customer.id
              },
              {
                fieldId: 'startDate',
                forcedValue: moment(project.startDate).format('YYYY-MM-DD')
              },
              {
                fieldId: 'type',
                forcedValue: project.type.id
              },
              {
                fieldId: 'cableLength',
                forcedValue: project.cableLength
              },
              {
                fieldId: 'referent1',
                forcedValue: project.referent1.id
              },
              {
                fieldId: 'referent2',
                forcedValue: project.referent2?.id
              },
              {
                fieldId: 'referent3',
                forcedValue: project.referent3?.id
              },
              {
                fieldId: 'externalId',
                forcedValue: project.externalId
              },
              {
                fieldId: 'address',
                forcedValue: project.address
              },
              {
                fieldId: 'description',
                forcedValue: project.description
              },
              {
                fieldId: 'estimatedEndDate',
                forcedValue: moment(project.estimatedEndDate).format(
                  'YYYY-MM-DD'
                )
              },
              {
                fieldId: 'endDate',
                forcedValue: moment(project.endDate).format('YYYY-MM-DD')
              },
              {
                fieldId: 'status',
                forcedValue: project.status
              },
              {
                fieldId: 'businessEngineerId',
                forcedValue: project.businessEngineer.id
              },
              {
                fieldId: 'studyManagerId',
                forcedValue: project.studyManager?.id
              },
              {
                fieldId: 'technicalComment',
                forcedValue: project.technicalComment
              },
              {
                fieldId: 'financialComment',
                forcedValue: project.financialComment
              }
            ])
          }
        }
      })
    },
    {
      label: 'Supprimer',
      permission: 'deleteProjects',
      action: (project) => ({
        type: ActionType.Delete,
        delete: {
          itemToDelete: project,
          definition: projectDefinition
        }
      }),
      disabled: (project: Project) =>
        !!project.invoices?.length ||
        !!project.statements?.length ||
        !!project.orders?.length,
      tooltip: (project: Project) =>
        !!project.invoices?.length ||
        !!project.statements?.length ||
        !!project.orders?.length
          ? `Impossible de supprimer un projet qui comporte des commandes et/ou des factures et/ou des relevés (RC/RP)	`
          : null
    },
    {
      label: 'Générer une facture',
      permission: 'editInvoices',
      action: (project) => ({
        type: ActionType.Link,
        link: {
          path: `${invoiceDefinition.path}/create`,
          queryParams: {
            specialRules: JSON.stringify([
              {
                fieldId: 'projectId',
                forcedValue: project.id
              }
            ])
          }
        }
      })
    }
  ],
  actionButtons: [
    {
      label: 'Ajouter un CE',
      condition: (p: Project) => !p.studyManager,
      className: 'button is-link-light is-small px-5',
      action: (project) => ({
        type: ActionType.Link,
        link: {
          path: `/projets/${project.id}/edit`
        }
      })
    }
  ],
  keyNumbers: [
    {
      label: 'Total prévisionnel HT',
      className: 'is-link-light',
      permission: 'addProjects',
      extraParams: {
        calculate: 'totalEstimated'
      }
    },
    {
      label: 'Total à recevoir HT',
      className: 'is-link-light',
      permission: 'addProjects',
      extraParams: {
        calculate: 'totalWaiting'
      }
    }
  ]
}
