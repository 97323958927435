import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { FormBuilder } from '@angular/forms'

import {
  CaseCreateEditComponent,
  ResourceDefinition,
  Field,
  Filter,
  InputType,
  BreadcrumbService,
  FlashMessageService,
  ResourceService,
  caseCreateEditTemplate
} from '@case-app/angular-library'

import { marketDefinition } from '../market.definition'

@Component({ template: caseCreateEditTemplate })
export class MarketCreateEditComponent
  extends CaseCreateEditComponent
  implements OnInit
{
  definition: ResourceDefinition = marketDefinition
  fields: Field[] = [
    {
      label: 'N° de marché',
      placeholder: 'N° de marché...',
      property: 'name',
      inputType: InputType.Text,
      required: true,
      className: 'is-12'
    },
    {
      label: 'Date de début',
      placeholder: 'Entrer une date de début...',
      property: 'startDate',
      inputType: InputType.Datepicker,
      required: true,
      className: 'is-4'
    },
    {
      label: 'Date de fin',
      placeholder: 'Entrer une date de fin...',
      property: 'endDate',
      inputType: InputType.Datepicker,
      required: true,
      className: 'is-4'
    },
    {
      label: 'Budget total',
      placeholder: '0',
      property: 'budget',
      inputType: InputType.Number,
      required: true,
      className: 'is-4'
    },
    {
      label: 'Description',
      placeholder: 'Description...',
      property: 'description',
      inputType: InputType.Textarea,
      required: true,
      className: 'is-12'
    },
    {
      label: 'Série 1',
      placeholder: 'Choisir un fichier',
      property: 'service1File',
      inputType: InputType.File,
      className: 'is-4'
    },
    {
      label: 'Série 2',
      placeholder: 'Choisir un fichier',
      property: 'service2File',
      inputType: InputType.File,
      className: 'is-4'
    },
    {
      label: 'Série 3',
      placeholder: 'Choisir un fichier',
      property: 'service3File',
      inputType: InputType.File,
      className: 'is-4'
    },
    {
      label: 'Nom de la série 1',
      placeholder: 'Entrer un nom',
      property: 'service1Name',
      inputType: InputType.Text,
      className: 'is-4'
    },
    {
      label: 'Nom de la série 2',
      placeholder: 'Entrer un nom',
      property: 'service2Name',
      inputType: InputType.Text,
      className: 'is-4'
    },
    {
      label: 'Nom de la série 3',
      placeholder: 'Entrer un nom',
      property: 'service3Name',
      inputType: InputType.Text,
      className: 'is-4'
    },
    {
      label: 'Prix du point série 1',
      placeholder: '0',
      property: 'service1Price',
      inputType: InputType.Number,
      className: 'is-4'
    },
    {
      label: 'Prix du point série 2',
      placeholder: '0',
      property: 'service2Price',
      inputType: InputType.Number,
      className: 'is-4'
    },
    {
      label: 'Prix du point série 3',
      placeholder: '0',
      property: 'service3Price',
      inputType: InputType.Number,
      className: 'is-4'
    },
    {
      label: 'Marché Actif',
      helpText: `Les marchés inactifs ne sont plus sélectionnables lors de la création de relevés et de commandes.`,
      property: 'isActive',
      initialValue: true,
      className: 'is-12',
      inputType: InputType.Checkbox
    }
  ]

  constructor(
    formBuilder: FormBuilder,
    router: Router,
    activatedRoute: ActivatedRoute,
    resourceService: ResourceService,
    breadcrumbService: BreadcrumbService,
    flashMessageService: FlashMessageService
  ) {
    super(
      formBuilder,
      router,
      breadcrumbService,
      resourceService,
      flashMessageService,
      activatedRoute
    )
  }

  ngOnInit() {
    this.initCreateEditView()
  }
}
