import {
  ActionType,
  Gender,
  LinkType,
  ResourceDefinition
} from '@case-app/angular-library'
import { Type } from '../../../src/resources/type/type.entity'

export const typeDefinition: ResourceDefinition = {
  title: 'Types',
  nameSingular: 'type',
  namePlural: 'types',
  gender: Gender.Masculine,
  mainIdentifier: 'name',
  slug: 'types',
  path: 'types',
  className: 'Type',
  icon: 'icon-tag',
  hasDetailPage: false,
  hasListPage: true,
  buttons: [LinkType.CREATE],
  defaultLink: LinkType.EDIT,
  childrenThatPreventDelete: [],
  dropdownLinks: [
    {
      label: 'Modifier',
      permission: 'editTypes',
      action: (type) => ({
        type: ActionType.Link,
        link: {
          path: `${typeDefinition.path}/${type.id}/edit`
        }
      })
    },
    {
      label: 'Supprimer',
      permission: 'deleteTypes',
      action: (type) => ({
        type: ActionType.Delete,
        delete: {
          itemToDelete: type,
          definition: typeDefinition
        }
      }),
      disabled: (type: Type) => !!type.projects.length,
      tooltip: (type: Type) =>
        !!type.projects.length
          ? `Impossible de supprimer un type qui a des projets reliés. Veuillez d'abord le retirer de ce(s) projet(s) ou bien supprimer le(s) projet(s).`
          : null
    }
  ]
}
