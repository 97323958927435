import { Route } from '@angular/router'
import {
  AuthGuard,
  PermissionGuard,
  ResourceMode
} from '@case-app/angular-library'

import { UserCreateEditComponent } from './user-create-edit.component'
import { UserDetailComponent } from './user-detail/user-detail.component'
import { UserListComponent } from './user-list.component'

export const userRoutes: Route[] = [
  {
    path: 'collaborateurs',
    component: UserListComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permission: 'browseUsers'
    }
  },
  {
    path: 'collaborateurs/create',
    component: UserCreateEditComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permission: 'addUsers',
      mode: ResourceMode.Create
    }
  },
  {
    path: 'collaborateurs/:id/edit',
    component: UserCreateEditComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      mode: ResourceMode.Edit,
      permissions: ['editUsers', 'editOwnUsers']
    }
  },
  {
    path: 'collaborateurs/:id',
    component: UserDetailComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permissions: ['readUsers', 'readOwnUsers']
    }
  }
]
