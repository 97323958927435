import { Gender, LinkType, ResourceDefinition } from '@case-app/angular-library'

export const monitoringSubtractionDefinition: ResourceDefinition = {
  title: 'RC sans PVR - Monitoring',
  nameSingular: 'NOT APPLICABLE',
  namePlural: 'RC sans PVRs',
  gender: Gender.Feminine,
  mainIdentifier: 'NOT APPLICABLE',
  slug: 'monitoring/subtraction',
  path: 'monitoring/subtraction',
  className: 'NOT APPLICABLE',
  icon: 'NOT APPLICABLE',
  hasDetailPage: false,
  hasListPage: true,
  buttons: [],
  defaultLink: LinkType.EDIT,
  childrenThatPreventDelete: [],
  dropdownLinks: [],
  keyNumbers: []
}
