import { Component, OnInit } from '@angular/core'
import {
  EurosPipe,
  ResourceDefinition,
  ResourceService,
  Yield
} from '@case-app/angular-library'
import { ChartDataSets, ChartOptions, ChartTooltipItem } from 'chart.js'
import { Labels } from 'ng-chartjs'

import { Invoice } from '../../../src/resources/invoice/invoice.entity'
import { invoiceDefinition } from '../../resources/invoice/invoice.definition'
import { invoiceYields } from '../../resources/invoice/invoice.yields'
import { ChartItem } from '../chart-item.interface'

@Component({
  selector: 'app-monitoring-payment-forecast',
  templateUrl: './monitoring-payment-forecast.component.html',
  styleUrls: ['./monitoring-payment-forecast.component.scss']
})
export class MonitoringPaymentForecast implements OnInit {
  invoices: Invoice[]
  chartItems: ChartItem[]

  barChartLabels: Labels
  barChartData: ChartDataSets[] = []
  barChartOptions: ChartOptions = {
    tooltips: {
      callbacks: {
        label: (tooltipItem: ChartTooltipItem) =>
          this.eurosPipe.transform(tooltipItem.yLabel as number)
      },
      backgroundColor: '#FFF',
      titleFontSize: 16,
      titleFontColor: '#0066ff',
      bodyFontColor: '#000',
      bodyFontSize: 14,
      displayColors: false
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            borderDash: [8, 4],
            color: '#e9eeff'
          },
          ticks: {
            fontColor: '#b0bac9'
          }
        }
      ],
      yAxes: [
        {
          gridLines: {
            borderDash: [8, 4],
            color: '#e9eeff'
          },
          ticks: {
            callback: (value) => this.eurosPipe.transform(value as number),
            fontColor: '#b0bac9',
            beginAtZero: true
          }
        }
      ]
    },
    responsive: true
  }

  invoiceDefinition: ResourceDefinition = invoiceDefinition
  invoiceYields: Yield[] = invoiceYields

  constructor(
    private eurosPipe: EurosPipe,
    private resourceService: ResourceService
  ) {}

  ngOnInit() {
    this.resourceService
      .list('monitoring/payment-forecast')
      .then((res: ChartItem[]) => {
        this.chartItems = res
        this.barChartLabels = this.chartItems.map((cI: ChartItem) => cI.label)
        this.barChartData = [
          {
            data: this.chartItems.map((cI: ChartItem) => cI.value),
            label: `Chiffre d'affaires`,
            backgroundColor: '#5065ff',
            hoverBackgroundColor: '#5065ff'
          }
        ]
      })

    this.resourceService
      .list('invoices', {
        withoutPagination: true,
        paymentStatus: 'notPayedOnly'
      })
      .then((invoiceRes: Invoice[]) => {
        this.invoices = invoiceRes
      })
  }
}
