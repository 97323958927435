<!-- Tabs -->
<div class="tabs is-centered m-b-xl">
  <ul>
    <li [ngClass]="{ 'is-active': selectedTab === 'turnover' }">
      <a routerLink="/monitoring/turnover">Synthèse financière</a>
    </li>
    <li [ngClass]="{ 'is-active': selectedTab === 'estimation' }">
      <a routerLink="/monitoring/estimation">Trésorerie prévisionnelle</a>
    </li>
    <li [ngClass]="{ 'is-active': selectedTab === 'profitability' }">
      <a routerLink="/monitoring/profitability">Rentabilité</a>
    </li>
    <li [ngClass]="{ 'is-active': selectedTab === 'subtraction' }">
      <a routerLink="/monitoring/subtraction">RC sans PVR</a>
    </li>
    <li [ngClass]="{ 'is-active': selectedTab === 'lateness' }">
      <a routerLink="/monitoring/lateness">Retards</a>
    </li>
  </ul>
</div>
