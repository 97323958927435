import {
  ActionType,
  Gender,
  LinkType,
  ResourceDefinition
} from '@case-app/angular-library'
import { Tooltip } from 'chart.js'
import { Customer } from '../../../src/resources/customer/customer.entity'

export const customerDefinition: ResourceDefinition = {
  title: 'Clients',
  nameSingular: 'client',
  namePlural: 'clients',
  gender: Gender.Masculine,
  mainIdentifier: 'name',
  slug: 'customers',
  path: 'clients',
  className: 'Customer',
  icon: 'icon-briefcase',
  hasDetailPage: false,
  hasListPage: true,
  buttons: [LinkType.CREATE],
  defaultLink: LinkType.EDIT,
  childrenThatPreventDelete: [],
  dropdownLinks: [
    {
      label: 'Modifier',
      permission: 'editCustomers',
      action: (customer) => ({
        type: ActionType.Link,
        link: {
          path: `${customerDefinition.path}/${customer.id}/edit`
        }
      })
    },
    {
      label: 'Supprimer',
      permission: 'deleteCustomers',
      action: (customer) => ({
        type: ActionType.Delete,
        delete: {
          itemToDelete: customer,
          definition: customerDefinition
        }
      }),
      disabled: (customer: Customer) =>
        !!customer.projects.length || !!customer.referents.length,
      tooltip: (customer: Customer) =>
        !!customer.projects.length || !!customer.referents.length
          ? `Impossible de supprimer un client qui a des projets et/ou des responsables reliés. Veuillez supprimer ces ressources avant de supprimer le client.`
          : null
    }
  ]
}
