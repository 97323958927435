import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import {
  AuthGuard,
  caseRoutes,
  PermissionGuard,
  ResourceMode
} from '@case-app/angular-library'

import { monitoringRoutes } from './monitoring/monitoring.routes'
import { HomeComponent } from './pages/home/home.component'
import { corporateGroupRoutes } from './resources/corporate-group/corporate-group.routes'
import { customerRoutes } from './resources/customer/customer.routes'
import { expenseRoutes } from './resources/expense/expense.routes'
import { invoiceRoutes } from './resources/invoice/invoice.routes'
import { marketRoutes } from './resources/market/market.routes'
import { orderRoutes } from './resources/order/order.routes'
import { projectRoutes } from './resources/project/project.routes'
import { referentRoutes } from './resources/referent/referent.routes'
import { SettingCreateEditComponent } from './resources/setting/setting-create-edit/setting-create-edit.component'
import { typeRoutes } from './resources/type/type.routes'
import { userRoutes } from './resources/user/user.routes'

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    canActivate: [AuthGuard]
  },

  // Settings.
  {
    path: 'configuration/:id/edit',
    component: SettingCreateEditComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permission: 'editSettings',
      mode: ResourceMode.Edit
    }
  },

  ...monitoringRoutes,
  ...userRoutes,
  ...corporateGroupRoutes,
  ...customerRoutes,
  ...referentRoutes,
  ...typeRoutes,
  ...marketRoutes,
  ...projectRoutes,
  ...invoiceRoutes,
  ...orderRoutes,
  ...expenseRoutes,
  ...(caseRoutes as Routes)
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
