import { Yield, YieldType } from '@case-app/angular-library'

import { editStatus } from './project.actions'

export const projectYields: Yield[] = [
  {
    label: 'Projet',
    property: 'type.color',
    secondProperty: 'label',
    orderByProperty: 'number',
    className: 'has-mini-circle pointer',
    type: YieldType.Color
  },
  {
    label: 'Type',
    property: 'type.name'
  },
  {
    label: 'Adresse',
    property: 'addressLocality',
    secondProperty: 'addressRoute',
    orderByProperty: 'address',
    type: YieldType.Text
  },
  {
    label: 'Quantitatif',
    property: 'cableLength'
  },

  {
    label: 'Rendu souhaité',
    property: 'estimatedEndDate',
    type: YieldType.Date
  },
  {
    label: 'Client',
    property: 'customer.name',
    link: (p) => `/projets?customerIds=${p.customer.id}`
  },
  {
    label: 'Res 1',
    property: 'referent1.name',
    orderByProperty: 'referent1.name',
    link: (p) => (p.referent1 ? `/projets?referentIds=${p.referent1.id}` : null)
  },
  {
    label: 'Res 2',
    disableOrderBy: true,
    property: 'referent2.name',
    link: (p) => (p.referent2 ? `/projets?referentIds=${p.referent2.id}` : null)
  },
  {
    label: 'Prévisionnel (HT)',
    disableOrderBy: true,
    property: 'estimatedStatementsAmount',
    type: YieldType.Currency
  },
  {
    label: 'A recevoir (HT)',
    disableOrderBy: true,
    property: 'waitingAmount',
    type: YieldType.Currency
  },
  {
    label: 'Reçu',
    disableOrderBy: true,
    property: 'receivedAmount',
    type: YieldType.Currency
  },
  {
    label: 'Équipe',
    property: 'userImages',
    secondProperty: 'name',
    orderByProperty: 'businessEngineer.name',
    type: YieldType.Image
  },
  {
    label: 'Statut',
    property: 'statusNumber',
    secondProperty: 'totalStatusNumber',
    thirdProperty: 'status',
    orderByProperty: 'status',
    type: YieldType.ProgressBar,
    action: editStatus
  },
  {
    label: 'description',
    property: 'description',
    orderByProperty: 'description'
  },
  {
    label: '',
    property: 'technicalComment',
    icon: 'icon-file-text has-text-warning',
    type: YieldType.Icon
  },
  {
    label: '',
    property: 'financialComment',
    icon: 'icon-book-open has-text-warning',
    type: YieldType.Icon
  }
]
