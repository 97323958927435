import { Route } from '@angular/router'

import {
  AuthGuard,
  PermissionGuard,
  ResourceMode
} from '@case-app/angular-library'

import { CorporateGroupCreateEditComponent } from './corporate-group-create-edit/corporate-group-create-edit.component'
import { CorporateGroupListComponent } from './corporate-group-list/corporate-group-list.component'

export const corporateGroupRoutes: Route[] = [
  {
    path: 'groupe-de-clients',
    component: CorporateGroupListComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permission: 'browseCorporateGroups'
    }
  },
  {
    path: 'groupe-de-clients/create',
    component: CorporateGroupCreateEditComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permission: 'addCorporateGroups',
      mode: ResourceMode.Create
    }
  },
  {
    path: 'groupe-de-clients/:id/edit',
    component: CorporateGroupCreateEditComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      mode: ResourceMode.Edit,
      permission: 'editCorporateGroups'
    }
  }
]
