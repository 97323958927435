<div
  [ngClass]="{
    'is-flex is-align-items-center is-justify-content-flex-start':
      showStatusName
  }"
>
  <span
    *ngIf="showStatusName"
    style="margin-top: 2px"
    class="has-text-weight-semibold mr-2	is-uppercase text-progress-{{
      statusNumber
    }}"
  >
    {{ status }}
  </span>

  <span
    class="bullet progress-1"
    [ngClass]="{ 'is-white': statusNumber < 1 }"
  ></span>
  <span
    class="bullet progress-2"
    [ngClass]="{ 'is-white': statusNumber < 2 }"
  ></span>
  <span
    class="bullet progress-3"
    [ngClass]="{ 'is-white': statusNumber < 3 }"
  ></span>
  <span
    class="bullet progress-4"
    [ngClass]="{ 'is-white': statusNumber < 4 }"
  ></span>
  <span
    class="bullet progress-5"
    [ngClass]="{ 'is-white': statusNumber < 5 }"
  ></span>
  <span
    class="bullet progress-6"
    [ngClass]="{ 'is-white': statusNumber < 6 }"
  ></span>
  <span
    class="bullet progress-7"
    [ngClass]="{ 'is-white': statusNumber < 7 }"
  ></span>
  <span
    class="bullet progress-8"
    [ngClass]="{ 'is-white': statusNumber < 8 }"
  ></span>
  <span
    class="bullet progress-9"
    [ngClass]="{ 'is-white': statusNumber < 9 }"
  ></span>
</div>
