import { Component, Inject, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import {
  AuthService,
  BreadcrumbService,
  CaseConfig,
  CaseListComponent,
  EurosPipe,
  Filter,
  FilterService,
  FlashMessageService,
  InputType,
  ResourceDefinition,
  ResourceService
} from '@case-app/angular-library'
import { ChartDataSets, ChartOptions, ChartTooltipItem } from 'chart.js'
import * as moment from 'moment'
import { Labels } from 'ng-chartjs'

import { appConstants } from '../../../../../shared/constants/app.constants'
import { ProjectStatus } from '../../../../../shared/enums/project-status.enum'
import { User } from '../../../src/resources/user/user.entity'
import { projectDefinition } from '../../resources/project/project.definition'
import { typeDefinition } from '../../resources/type/type.definition'
import { ChartItem } from '../chart-item.interface'
import { monitoringProfitabilityDefinition } from './monitoring-profitability.definition'

@Component({
  selector: 'app-monitoring-profitability',
  templateUrl: './monitoring-profitability.component.html',
  styleUrls: ['./monitoring-profitability.component.scss']
})
export class MonitoringProfitabilityComponent
  extends CaseListComponent
  implements OnInit
{
  mostProfitableItems: ChartItem[]
  lessProfitableItems: ChartItem[]

  filters: Filter[] = [
    {
      label: 'Date',
      properties: {
        dateFrom: 'dateFrom',
        dateTo: 'dateTo'
      },
      value: {
        dateFrom: moment().subtract(1, 'y').format('YYYY-MM-DD'),
        dateTo: moment().format('YYYY-MM-DD')
      },
      inputType: InputType.DateRange,
      className: 'is-4'
    },
    {
      label: `Chargé d'affaire SVL`,
      property: 'businessEngineerIds',
      placeholder: 'Tous les CAs',
      inputType: InputType.MultiSelect,
      className: 'is-3',
      selectOptions: () =>
        this.componentResourceService.listSelectOptions('users', {
          roleNames: [
            appConstants.ROLES.ADMIN_ROLE_NAME,
            appConstants.ROLES.BUSINESS_ENGINEER_ROLE_NAME
          ],
          includeNotActive: true,
          orderBy: 'name'
        })
    },
    {
      label: `Chargé d'étude SVL`,
      property: 'studyManagerIds',
      placeholder: 'Tous les CEs',
      inputType: InputType.MultiSelect,
      className: 'is-4',
      selectOptions: () =>
        this.componentResourceService.listSelectOptions('users', {
          roleName: 'studyManager',
          includeNotActive: true,
          orderBy: 'name'
        })
    },
    {
      label: 'Projet',
      placeholder: 'Rechercher par N° de projet (interne ou client)',
      inputType: InputType.MultiSearch,
      className: 'is-4',
      searchResources: [projectDefinition],
      properties: {
        projectIds: 'projectIds'
      }
    },
    {
      label: 'Type de projet',
      placeholder: 'Rechercher par type de...',
      inputType: InputType.MultiSearch,
      className: 'is-4',
      searchResources: [typeDefinition],
      properties: {
        typeIds: 'typeIds'
      }
    },
    {
      label: 'Statut',
      property: 'statuses',
      inputType: InputType.MultiSelect,
      className: 'is-4',
      selectOptions: Object.values(ProjectStatus).map((projectStatus) => ({
        label: projectStatus,
        value: projectStatus
      }))
    },
    {
      label: 'Projets à facturer',
      inputType: InputType.Checkbox,
      className: 'is-3',
      property: 'toBillOnly'
    },
    {
      label: 'Projets avec RC sans PVR',
      inputType: InputType.Checkbox,
      className: 'is-3',
      property: 'withContradictoryStatementWithoutAcceptanceReport'
    },

    {
      label: 'Inclure les projets inactifs',
      inputType: InputType.Checkbox,
      className: 'is-3',
      property: 'includeNotActive'
    }
  ]

  mostProfitableChartLabels: Labels
  mostProfitableChartData: ChartDataSets[] = []
  lessProfitableChartLabels: Labels
  lessProfitableChartData: ChartDataSets[] = []
  barChartOptions: ChartOptions = {
    scales: {
      yAxes: [
        {
          gridLines: {
            borderDash: [8, 4],
            color: '#e9eeff'
          },
          ticks: { fontColor: '#b0bac9' }
        }
      ],
      xAxes: [
        {
          gridLines: {
            borderDash: [8, 4],
            color: '#e9eeff'
          },
          ticks: {
            fontColor: '#b0bac9',
            beginAtZero: true,
            callback: (value) => this.eurosPipe.transform(value as number)
          }
        }
      ]
    },
    tooltips: {
      callbacks: {
        label: (tooltipItem: ChartTooltipItem) =>
          this.eurosPipe.transform(tooltipItem.xLabel as number) + ' / h'
      },
      backgroundColor: '#FFF',
      titleFontSize: 16,
      titleFontColor: '#0066ff',
      bodyFontColor: '#000',
      bodyFontSize: 14,
      displayColors: false
    },
    responsive: true
  }
  average: number
  isIndividualAnalytics: boolean

  definition: ResourceDefinition = monitoringProfitabilityDefinition

  constructor(
    resourceService: ResourceService,
    activatedRoute: ActivatedRoute,
    router: Router,
    breadcrumbService: BreadcrumbService,
    flashMessageService: FlashMessageService,
    filterService: FilterService,
    authService: AuthService,
    @Inject('CASE_CONFIG_TOKEN') config: CaseConfig,
    private eurosPipe: EurosPipe,
    private componentResourceService: ResourceService,
    private componentRouter: Router,
    private componentActivatedRoute: ActivatedRoute,
    private componentAuthService: AuthService
  ) {
    super(
      router,
      activatedRoute,
      breadcrumbService,
      resourceService,
      flashMessageService,
      authService,
      filterService,
      config
    )
  }

  ngOnInit() {
    this.componentAuthService.currentUser.subscribe(
      async (currentUser: User) => {
        // Set businessEngineer filter to current user's if user has no full rights.
        this.isIndividualAnalytics = !(await this.componentAuthService.can(
          'browseAnalytics'
        ))
        const businessEngineerIds =
          this.componentActivatedRoute.snapshot.queryParams.businessEngineerIds

        if (this.isIndividualAnalytics && !businessEngineerIds) {
          this.componentRouter.navigate(['/monitoring', 'profitability'], {
            queryParams: {
              businessEngineerIds: currentUser.id
            },
            queryParamsHandling: 'merge'
          })
        }

        if (this.isIndividualAnalytics) {
          const businessEngineerFilter: Filter = this.filters.find(
            (f) => f.property === 'businessEngineerIds'
          )
          businessEngineerFilter.readonly = true
        }

        this.initListView()

        this.paginator$.subscribe((paginator: any) => {
          const projects: ChartItem[] = paginator

          this.mostProfitableItems = projects
            .sort((a: ChartItem, b: ChartItem) => b.value - a.value)
            .slice(0, 10)
          this.mostProfitableChartLabels = this.mostProfitableItems.map(
            (cI: ChartItem) => cI.label
          )
          this.mostProfitableChartData = [
            {
              data: this.mostProfitableItems.map((cI: ChartItem) => cI.value),
              label: `€ / h`,
              backgroundColor: '#5065ff',
              hoverBackgroundColor: '#5065ff'
            }
          ]

          this.lessProfitableItems = projects
            .sort((a: ChartItem, b: ChartItem) => b.value - a.value)
            .slice(projects.length - 10)
            .reverse()
          this.lessProfitableChartLabels = this.lessProfitableItems.map(
            (cI: ChartItem) => cI.label
          )
          this.lessProfitableChartData = [
            {
              data: this.lessProfitableItems.map((cI: ChartItem) => cI.value),
              label: `€ / h`,
              backgroundColor: '#825ef6',
              hoverBackgroundColor: '#825ef6'
            }
          ]

          this.average =
            projects.reduce(
              (prev: number, curr: ChartItem) =>
                prev + curr.value * curr.extraValue,
              0
            ) /
            projects.reduce(
              (prev: number, curr: ChartItem) => prev + curr.extraValue,
              0
            )
        })
      }
    )
  }

  onChartClick(
    event: { active: { _index: number }[] },
    chartDesignation: string
  ) {
    if (event.active.length) {
      const project: ChartItem =
        this[`${chartDesignation}Items`][event.active[0]._index]
      this.componentRouter.navigate(['/projets', project.id])
    }
  }
}
