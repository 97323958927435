import { Component, OnInit, Inject } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import {
  caseListTemplate,
  CaseListComponent,
  Filter,
  ResourceDefinition,
  BreadcrumbService,
  FlashMessageService,
  ResourceService,
  CaseConfig,
  Yield,
  InputType,
  AuthService,
  FilterService
} from '@case-app/angular-library'
import { acceptanceReportDefinition } from '../../acceptance-report/acceptance-report.definition'
import { customerDefinition } from '../../customer/customer.definition'
import { invoiceDefinition } from '../../invoice/invoice.definition'
import { projectDefinition } from '../../project/project.definition'

import { orderDefinition } from '../order.definition'
import { orderYields } from '../order.yields'

@Component({ template: caseListTemplate })
export class OrderListComponent extends CaseListComponent implements OnInit {
  definition: ResourceDefinition = orderDefinition
  yields: Yield[] = orderYields

  filters: Filter[] = [
    {
      label: 'Rechercher une commande',
      placeholder: 'Rechercher par numéro, facture, projet, client ou PVR...',
      inputType: InputType.MultiSearch,
      className: 'is-6',
      searchResources: [
        orderDefinition,
        invoiceDefinition,
        projectDefinition,
        customerDefinition,
        acceptanceReportDefinition
      ],
      properties: {
        orderIds: 'orderIds',
        invoiceIds: 'invoiceIds',
        projectIds: 'projectIds',
        customerIds: 'customerIds',
        acceptanceReportIds: 'acceptanceReportIds'
      }
    },
    {
      label: `PV de réception`,
      property: 'acceptanceReportStatus',
      inputType: InputType.Select,
      placeholder: 'Avec et sans PVR',
      className: 'is-3',
      selectOptions: [
        {
          label: 'Avec PVR uniquement',
          value: 'withAcceptanceReportOnly'
        },
        {
          label: 'Sans PVR uniquement',
          value: 'withoutAcceptanceReportOnly'
        }
      ]
    },
    {
      label: `Facture`,
      property: 'invoiceStatus',
      placeholder: 'Avec et sans facture',
      inputType: InputType.Select,
      className: 'is-3',
      selectOptions: [
        {
          label: 'Avec facture uniquement',
          value: 'withInvoiceOnly'
        },
        {
          label: 'Sans facture uniquement',
          value: 'withoutInvoiceOnly'
        }
      ]
    },
    {
      label: `Date`,
      properties: {
        dateFrom: 'dateFrom',
        dateTo: 'dateTo'
      },
      inputType: InputType.DateRange,
      className: 'is-12'
    }
  ]

  constructor(
    router: Router,
    activatedRoute: ActivatedRoute,
    resourceService: ResourceService,
    breadcrumbService: BreadcrumbService,
    flashMessageService: FlashMessageService,
    filterService: FilterService,
    authService: AuthService,
    @Inject('CASE_CONFIG_TOKEN') config: CaseConfig
  ) {
    super(
      router,
      activatedRoute,
      breadcrumbService,
      resourceService,
      flashMessageService,
      authService,
      filterService,
      config
    )
  }

  ngOnInit() {
    this.initListView()
  }
}
