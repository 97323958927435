import {
  ActionType,
  Gender,
  LinkType,
  ResourceDefinition
} from '@case-app/angular-library'

export const statementDefinition: ResourceDefinition = {
  title: 'Relevés',
  nameSingular: 'relevé',
  namePlural: 'relevés',
  gender: Gender.Masculine,
  mainIdentifier: 'title',
  slug: 'statements',
  path: 'releves',
  className: 'Statement',
  icon: 'icon-grid',
  hasDetailPage: false,
  hasListPage: false,
  buttons: [LinkType.CREATE],
  defaultLink: null,
  childrenThatPreventDelete: [],
  dropdownLinks: [
    {
      label: 'Supprimer',
      permission: 'deleteStatements',
      action: (statement) => ({
        type: ActionType.Delete,
        delete: {
          itemToDelete: statement,
          definition: statementDefinition
        }
      })
    }
  ]
}
