import { Route } from '@angular/router'

import {
  AuthGuard,
  PermissionGuard,
  ResourceMode
} from '@case-app/angular-library'

import { OrderCreateEditComponent } from './order-create-edit/order-create-edit.component'
import { OrderDetailComponent } from './order-detail/order-detail.component'
import { OrderListComponent } from './order-list/order-list.component'

export const orderRoutes: Route[] = [
  {
    path: 'commandes',
    component: OrderListComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permission: 'browseOrders'
    }
  },
  {
    path: 'commandes/create',
    component: OrderCreateEditComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permission: 'addOrders',
      mode: ResourceMode.Create
    }
  },
  {
    path: 'commandes/:id/edit',
    component: OrderCreateEditComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      mode: ResourceMode.Edit,
      permission: 'editOrders'
    }
  },
  {
    path: 'commandes/:id',
    component: OrderDetailComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permission: 'readOrders'
    }
  }
]
