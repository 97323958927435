import { Gender, LinkType, ResourceDefinition } from '@case-app/angular-library';

export const localityDefinition: ResourceDefinition = {
  title: 'Villes',
  nameSingular: 'Ville',
  namePlural: 'Villes',
  className: 'Locality',
  mainIdentifier: 'id',
  slug: 'localities',
  path: 'villes',
  icon: 'icon-map-pin',
  gender: Gender.Feminine,
  hasDetailPage: false,
  hasListPage: false,
  buttons: [],
  defaultLink: LinkType.EDIT,
  childrenThatPreventDelete: [],
  dropdownLinks: []
}
