import {
  ActionType,
  Gender,
  LinkType,
  ResourceDefinition
} from '@case-app/angular-library'
import { CorporateGroup } from '../../../src/resources/corporate-group/corporate-group.entity'

export const corporateGroupDefinition: ResourceDefinition = {
  title: 'Groupe de clients',
  nameSingular: 'Groupe de clients',
  namePlural: 'Groupe de clients',
  gender: Gender.Masculine,
  mainIdentifier: 'name',
  slug: 'corporate-groups',
  path: 'groupe-de-clients',
  className: 'CorporateGroup',
  icon: 'icon-layers',
  hasDetailPage: false,
  hasListPage: true,
  buttons: [LinkType.CREATE],
  defaultLink: LinkType.EDIT,
  childrenThatPreventDelete: [],
  dropdownLinks: [
    {
      label: 'Modifier',
      permission: 'editCorporateGroups',
      action: (cG) => ({
        type: ActionType.Link,
        link: {
          path: `${corporateGroupDefinition.path}/${cG.id}/edit`
        }
      })
    },
    {
      label: 'Supprimer',
      permission: 'deleteCorporateGroups',
      action: (cG) => ({
        type: ActionType.Delete,
        delete: {
          itemToDelete: cG,
          definition: corporateGroupDefinition
        }
      }),
      disabled: (cG: CorporateGroup) => !!cG.customers.length,
      tooltip: (cG: CorporateGroup) =>
        !!cG.customers.length
          ? `Impossible de supprimer un groupe qui a des clients reliés. Veuillez d'abord supprimer ses clients.`
          : null
    }
  ]
}
