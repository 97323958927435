<div class="modal is-large is-active">
  <form [formGroup]="form" (submit)="downloadProjectFramework(form)">
    <div class="modal-background"></div>

    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title" *ngIf="isOnboarding">
          Le projet {{ project.externalId }} a bien été créé.
        </p>
        <p class="modal-card-title" *ngIf="!isOnboarding">
          Le Projet {{ project.externalId }}
        </p>

        <a
          class="is-flex is-align-item-center has-text-dark"
          (click)="close()"
          aria-label="close"
        >
          <i class="icon icon-x is-size-2"></i>
        </a>
      </header>
      <section class="modal-card-body">
        <div class="content">
          <div
            class="px-5 has-text-centered my-5 is-flex is-flex-direction-column is-align-items-center is-justify-content-center"
          >
            <img
              src="assets/images/done1.svg"
              alt=""
              width="164"
              *ngIf="isOnboarding"
            />
            <img
              src="assets/images/folders.svg"
              alt=""
              width="164"
              *ngIf="!isOnboarding"
            />
            <p class="is-size-5 mt-5">
              Choisissez le type de dossier que vous voulez télécharger et
              cliquez sur le bouton "télécharger le dossier"
            </p>
          </div>

          <div
            class="is-flex is-align-items-center is-justify-content-space-between"
          >
            <div class="control">
              <label for="folder-etude-moe" class="radio">
                Etude MOE
                <input
                  type="radio"
                  formControlName="projectFramework"
                  value="projectFramework1"
                  id="folder-etude-moe"
                />
                <span class="checkmark"></span>
              </label>
            </div>
            <div class="control">
              <label for="pgod" class="radio">
                PGOD
                <input
                  type="radio"
                  formControlName="projectFramework"
                  value="projectFramework2"
                  id="pgod"
                />
                <span class="checkmark"></span>
              </label>
            </div>
            <div class="control">
              <label for="pgoc" class="radio">
                PGOC
                <input
                  type="radio"
                  formControlName="projectFramework"
                  value="projectFramework3"
                  id="pgoc"
                />
                <span class="checkmark"></span>
              </label>
            </div>
            <div class="control">
              <label for="ic" class="radio">
                IC
                <input
                  type="radio"
                  formControlName="projectFramework"
                  value="projectFramework4"
                  id="ic"
                />
                <span class="checkmark"></span>
              </label>
            </div>
          </div>
        </div>
      </section>
      <footer
        class="modal-card-foot pt-0 is-flex is-justify-content-space-between"
      >
        <a class="button is-light" (click)="close()">Fermer</a>
        <button class="button is-link" type="submit" [disabled]="form.invalid">
          Télécharger le dossier
        </button>
      </footer>
    </div>
  </form>
</div>
