import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import {
  ActionType,
  BreadcrumbService,
  CaseDetailComponent,
  FlashMessageService,
  InputType,
  ResourceDefinition,
  ResourceMode,
  ResourceService,
  Yield,
  YieldType
} from '@case-app/angular-library'
import { Action } from '@case-app/angular-library/lib/interfaces/actions/action.interface'
import * as moment from 'moment'
import { combineLatest } from 'rxjs'
import { AcceptanceReport } from '../../../../src/resources/acceptance-report/acceptance-report.entity'
import { Invoice } from '../../../../src/resources/invoice/invoice.entity'
import { Order } from '../../../../src/resources/order/order.entity'
import { acceptanceReportDefinition } from '../../acceptance-report/acceptance-report.definition'
import { invoiceDefinition } from '../../invoice/invoice.definition'
import { orderDefinition } from '../order.definition'

@Component({
  selector: 'app-order-detail',
  templateUrl: './order-detail.component.html',
  styleUrls: ['./order-detail.component.scss']
})
export class OrderDetailComponent
  extends CaseDetailComponent
  implements OnInit
{
  definition: ResourceDefinition = orderDefinition

  item: Order
  relatedInvoices: Invoice[]
  invoiceAmountsSum: number
  acceptanceReportAmountsSum: number

  acceptanceReportDefinition: ResourceDefinition = acceptanceReportDefinition
  invoiceDefinition: ResourceDefinition = invoiceDefinition

  deleteOrderAction: Action
  createAcceptanceReportAction: Action
  createInvoiceForProjectSpecialRules: string

  acceptanceReportYields: Yield[] = [
    {
      label: 'Date',
      property: 'date',
      type: YieldType.Date
    },
    {
      label: 'Nom du relevé',
      property: 'number'
    },
    {
      label: 'Montant HT',
      property: 'amount',
      type: YieldType.Currency
    }
  ]

  invoiceYields: Yield[] = [
    {
      label: 'Numéro de facture',
      property: 'number'
    },
    {
      label: 'Date',
      property: 'issueDate',
      type: YieldType.Date
    },
    {
      label: 'N° de PVR',
      property: 'acceptanceReports.number'
    },
    {
      label: 'Montant HT',
      property: 'amount',
      type: YieldType.Currency
    }
  ]
  constructor(
    breadcrumbService: BreadcrumbService,
    resourceService: ResourceService,
    flashMessageService: FlashMessageService,
    activatedRoute: ActivatedRoute,
    private componentActivatedRoute: ActivatedRoute
  ) {
    super(
      breadcrumbService,
      resourceService,
      flashMessageService,
      activatedRoute
    )
  }

  async ngOnInit(): Promise<void> {
    combineLatest([
      this.componentActivatedRoute.params,
      this.componentActivatedRoute.queryParams
    ]).subscribe(async ([params, queryParams]) => {
      await this.initDetailView()

      this.acceptanceReportAmountsSum = this.item.acceptanceReports.reduce(
        (sum: number, aR: AcceptanceReport) => sum + aR.amount,
        0
      )
      this.relatedInvoices = this.item.acceptanceReports.reduce(
        (acc: Invoice[], aR: AcceptanceReport) => {
          if (aR.invoice && !acc.find((i) => i.id === aR.invoice.id)) {
            acc.push(aR.invoice)
          }
          return acc
        },
        []
      )
      this.invoiceAmountsSum = this.relatedInvoices.reduce(
        (sum: number, i: Invoice) => sum + i.amount,
        0
      )

      this.deleteOrderAction = {
        type: ActionType.Delete,
        delete: {
          itemToDelete: this.item,
          definition: orderDefinition,
          redirectTo: `/${orderDefinition.path}`
        }
      }

      this.createAcceptanceReportAction = {
        type: ActionType.OpenCreateEditModal,
        openCreateEditModal: {
          title: 'Create an Acceptance Report',
          helpText:
            'Creating a new Acceptance report is easy ! Just fill up that form and we will contact you soon.',

          definition: acceptanceReportDefinition,
          mode: ResourceMode.Create,
          redirectTo: `/${orderDefinition.path}/${this.item.id}`,
          redirectToQueryParams: {
            t: new Date().getTime().toString()
          },
          fields: [
            {
              label: 'Numéro de PVR',
              property: 'number',
              inputType: InputType.Text,
              className: 'is-12',
              required: true
            },
            {
              label: 'Date de PVR',
              property: 'date',
              inputType: InputType.Datepicker,
              initialValue: moment(),
              className: 'is-12',
              required: true
            },
            {
              label: 'Description',
              property: 'description',
              inputType: InputType.Text,
              className: 'is-12',
              required: true
            },
            {
              label: 'Montant du PVR (HT)',
              property: 'amount',
              inputType: InputType.Number,
              className: 'is-12',
              required: true
            },
            {
              id: 'order',
              hidden: true,
              label: 'Commande',
              property: 'orderId',
              retrievedItemProperties: {
                orderId: 'order.id'
              },
              searchResources: [orderDefinition],
              maxSelectedItems: 1,
              initialValue: this.item.id,
              required: true,
              inputType: InputType.MultiSearch
            }
          ]
        }
      }

      // TODO: Include a acceptanceReport selection in the create-edit form.
      this.createInvoiceForProjectSpecialRules = JSON.stringify([
        {
          fieldId: 'projectId',
          forcedValue: this.item.id
        }
      ])

      // Add dropdown link that depends on this.item.
      if (
        this.acceptanceReportDefinition.dropdownLinks &&
        !this.acceptanceReportDefinition.dropdownLinks.find(
          (dL) => dL.label === 'Modifier'
        )
      ) {
        this.acceptanceReportDefinition.dropdownLinks.unshift({
          label: 'Modifier',
          condition: (aR: AcceptanceReport) => !aR.invoice,
          action: (aR) => ({
            type: ActionType.OpenCreateEditModal,
            openCreateEditModal: Object.assign(
              // Clone object to prevent modifying original one.
              { ...this.createAcceptanceReportAction.openCreateEditModal },
              {
                title: 'Modifier PV de récéption',
                mode: ResourceMode.Edit,
                item: aR
              }
            )
          })
        })
      }
    })
  }
}
