import { MenuItem } from '@case-app/angular-library'

export const menuItems: MenuItem[] = [
  {
    label: 'Projets',
    permissionsOr: ['browseProjects'],
    routePath: '/projets',
    icon: 'icon-grid',
    items: []
  },
  {
    label: 'Factures',
    permissionsOr: ['browseInvoices', 'browseOwnInvoices'],
    routePath: '/factures',
    icon: 'icon-file-text',
    items: []
  },
  {
    label: 'Commandes',
    permissionsOr: ['browseOrders', 'browseOwnOrders'],
    routePath: '/commandes',
    icon: 'icon-box',
    items: []
  },
  {
    label: 'Marchés',
    permissionsOr: ['browseMarkets', 'browseOwnMarkets'],
    routePath: '/marches',
    icon: 'icon-pie-chart',
    items: []
  },
  {
    label: 'Monitoring',
    permissionsOr: ['browseAnalytics', 'browseOwnAnalytics'],
    routePath: '/monitoring/turnover',
    icon: 'icon-bar-chart'
  },
  {
    label: 'Paramètres',
    permissionsOr: [
      'browseUsers',
      'browseRoles',
      'browseCustomers',
      'browseCustomers',
      'browseCorporateGroups',
      'browseOwnCorporateGroups'
    ],
    icon: 'icon-settings',
    items: [
      {
        label: 'Types',
        permissionsOr: ['browseTypes', 'browseOwnTypes'],
        routePath: '/types'
      },
      {
        label: 'Clients',
        permissionsOr: ['browseCustomers', 'browseOwnCustomers'],
        routePath: '/clients'
      },
      {
        label: 'Groupe de clients',
        permissionsOr: ['browseCorporateGroups', 'browseOwnCorporateGroups'],
        routePath: '/groupe-de-clients'
      },
      {
        label: 'Responsables',
        permissionsOr: ['browseReferents', 'browseOwnReferents'],
        routePath: '/responsables'
      },
      {
        label: 'Collaborateurs',
        permissionsOr: ['browseUsers'],
        routePath: '/collaborateurs'
      },
      {
        label: 'Configuration',
        permissionsOr: ['browseSettings'],
        routePath: '/configuration/get/edit'
      }
    ]
  }
]
