import { Yield, YieldType } from '@case-app/angular-library'

export const marketYields: Yield[] = [
  {
    label: 'N° de marché',
    property: 'name'
  },
  {
    label: 'Déscription',
    property: 'description'
  },
  {
    label: 'Valeur cible',
    property: 'budget',
    type: YieldType.Currency
  },
  {
    label: 'Total des commandes HT',
    property: 'orderAmount',
    disableOrderBy: true,
    type: YieldType.Currency
  },
  {
    label: 'Montant restant HT',
    property: 'remainingAmount',
    disableOrderBy: true,
    type: YieldType.Currency
  },
  {
    label: 'Actif',
    property: 'isActive',
    type: YieldType.Check
  }
]
