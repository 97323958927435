<nav class="level">
  <!-- Left side -->
  <div class="level-left">
    <div class="level-item">
      <span class="icon is-large">
        <i class="icon icon-bar-chart is-size-2 has-text-link"></i>
      </span>
    </div>
    <div class="level-item">
      <h1 class="title is-2 has-text-weight-light">Monitoring</h1>
    </div>
  </div>
</nav>

<section>
  <div class="card mb-6 mt-4">
    <app-monitoring-tabs [selectedTab]="'estimation'"></app-monitoring-tabs>
    <!-- Next weeks chart -->
    <div class="p-4">
      <div class="columns">
        <div class="column is-8-desktop is-offset-2-desktop">
          <div style="display: block">
            <h4
              class="title is-6 has-text-weight-bold is-uppercase has-text-grey"
            >
              Facturation sur les 10 prochaines semaines
            </h4>

            <div style="display: block">
              <canvas
                ngChartjs
                [data]="barChartData"
                [labels]="barChartLabels"
                [options]="barChartOptions"
                chartType="bar"
                [legend]="false"
                *ngIf="barChartData"
              >
              </canvas>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- Invoice list -->
<div class="columns is-multiline">
  <div class="column is-12">
    <div class="card p-4 is-fullheight">
      <div style="display: block; width: 100%">
        <h2 class="title is-6 has-text-weight-bold is-uppercase has-text-grey">
          Factures impayées
        </h2>
        <case-table
          [items]="invoices"
          [definition]="invoiceDefinition"
          [yields]="invoiceYields"
          [allowOrderBy]="false"
          *ngIf="invoices"
          class="is-fullwidth"
        ></case-table>
      </div>
    </div>
  </div>
</div>
