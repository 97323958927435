import { Gender, LinkType, ResourceDefinition } from '@case-app/angular-library'

export const monitoringTurnoverDefinition: ResourceDefinition = {
  title: 'Synthèse financière - Monitoring',
  nameSingular: 'NOT APPLICABLE',
  namePlural: 'synthèses financières',
  gender: Gender.Feminine,
  mainIdentifier: 'NOT APPLICABLE',
  slug: 'monitoring/turnover',
  path: 'monitoring/turnover',
  className: 'NOT APPLICABLE',
  icon: 'NOT APPLICABLE',
  hasDetailPage: false,
  hasListPage: true,
  buttons: [],
  defaultLink: LinkType.EDIT,
  childrenThatPreventDelete: [],
  dropdownLinks: [],
  keyNumbers: [
    {
      label: 'Montant total (source)',
      extraParams: {
        calculate: 'totalAmounts'
      }
    }
  ]
}
