import { Route } from '@angular/router'

import {
  AuthGuard,
  PermissionGuard,
  ResourceMode
} from '@case-app/angular-library'

import { TypeCreateEditComponent } from './type-create-edit/type-create-edit.component'
import { TypeListComponent } from './type-list/type-list.component'

export const typeRoutes: Route[] = [
  {
    path: 'types',
    component: TypeListComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permission: 'browseTypes'
    }
  },
  {
    path: 'types/create',
    component: TypeCreateEditComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permission: 'addTypes',
      mode: ResourceMode.Create
    }
  },
  {
    path: 'types/:id/edit',
    component: TypeCreateEditComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      mode: ResourceMode.Edit,
      permission: 'editTypes'
    }
  }
]
