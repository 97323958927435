import { Component, OnInit } from '@angular/core'
import { FormBuilder } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import {
  BreadcrumbService,
  CaseCreateEditComponent,
  caseCreateEditTemplate,
  Field,
  FlashMessageService,
  InputType,
  ResourceDefinition,
  ResourceService
} from '@case-app/angular-library'

import { projectDefinition } from '../../project/project.definition'
import { orderDefinition } from '../order.definition'

@Component({ template: caseCreateEditTemplate })
export class OrderCreateEditComponent
  extends CaseCreateEditComponent
  implements OnInit
{
  definition: ResourceDefinition = orderDefinition
  fields: Field[] = [
    {
      id: 'projectId',
      label: 'Choisir un projet',
      placeholder: 'Projet',
      property: 'projectId',
      retrievedItemProperties: {
        projectId: 'project.id'
      },
      inputType: InputType.MultiSearch,
      maxSelectedItems: 1,
      searchResources: [projectDefinition],
      className: 'is-4',
      required: true
    },
    {
      label: ' N° de commande',
      property: 'number',
      inputType: InputType.Text,
      className: 'is-4',
      required: true
    },
    {
      label: 'Date de la commande',
      placeholder: 'date de la commande...',
      property: 'date',
      inputType: InputType.Datepicker,
      className: 'is-4',
      required: true
    },
    {
      label: 'Montant de la commande (HT)',
      placeholder: 'Montant de la commande (HT)',
      property: 'amount',
      inputType: InputType.Number,
      className: 'is-4',
      required: true
    },
    {
      label: 'Marché',
      placeholder: 'Selectionner un marché',
      property: 'marketId',
      retrievedItemProperties: {
        marketId: 'market.id'
      },
      inputType: InputType.Select,
      className: 'is-4',
      required: true,
      selectOptions: () =>
        this.componentResourceService.listSelectOptions('markets', {
          orderBy: 'name'
        })
    },
    {
      label: 'Description',
      property: 'description',
      inputType: InputType.RichText,
      className: 'is-4'
    }
  ]

  constructor(
    formBuilder: FormBuilder,
    router: Router,
    activatedRoute: ActivatedRoute,
    resourceService: ResourceService,
    breadcrumbService: BreadcrumbService,
    flashMessageService: FlashMessageService,
    private componentResourceService: ResourceService
  ) {
    super(
      formBuilder,
      router,
      breadcrumbService,
      resourceService,
      flashMessageService,
      activatedRoute
    )
  }

  ngOnInit() {
    this.initCreateEditView()
  }
}
