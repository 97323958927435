import { CommonModule, registerLocaleData } from '@angular/common'
import localeFr from '@angular/common/locales/fr'
import { LOCALE_ID, NgModule } from '@angular/core'
import { ReactiveFormsModule } from '@angular/forms'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import Bugsnag from '@bugsnag/js'
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular'
import { CaseModule, EurosPipe } from '@case-app/angular-library'
import { CalendarModule, DateAdapter } from 'angular-calendar'
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns'
import { NgChartjsModule } from 'ng-chartjs'

import { environment } from '../environments/environment'
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { MonitoringPaymentForecast } from './monitoring/monitoring-estimation/monitoring-payment-forecast.component'
import { MonitoringLatenessComponent } from './monitoring/monitoring-lateness/monitoring-lateness.component'
import { MonitoringProfitabilityComponent } from './monitoring/monitoring-profitability/monitoring-profitability.component'
import { MonitoringSubtractionComponent } from './monitoring/monitoring-subtraction/monitoring-subtraction.component'
import { MonitoringTurnoverComponent } from './monitoring/monitoring-turnover/monitoring-turnover.component'
import { MonitoringTabsComponent } from './monitoring/partials/monitoring-tabs/monitoring-tabs.component'
import { HomeComponent } from './pages/home/home.component'
import { StatusBarComponent } from './partials/status-bar/status-bar.component'
import { CorporateGroupCreateEditComponent } from './resources/corporate-group/corporate-group-create-edit/corporate-group-create-edit.component'
import { CorporateGroupListComponent } from './resources/corporate-group/corporate-group-list/corporate-group-list.component'
import { CustomerCreateEditComponent } from './resources/customer/customer-create-edit/customer-create-edit.component'
import { CustomerListComponent } from './resources/customer/customer-list/customer-list.component'
import { ExpenseCreateEditComponent } from './resources/expense/expense-create-edit/expense-create-edit.component'
import { InvoiceCreateEditComponent } from './resources/invoice/invoice-create-edit/invoice-create-edit.component'
import { InvoiceListComponent } from './resources/invoice/invoice-list/invoice-list.component'
import { MarketCreateEditComponent } from './resources/market/market-create-edit/market-create-edit.component'
import { MarketDetailComponent } from './resources/market/market-detail/market-detail.component'
import { MarketListComponent } from './resources/market/market-list/market-list.component'
import { OrderCreateEditComponent } from './resources/order/order-create-edit/order-create-edit.component'
import { OrderDetailComponent } from './resources/order/order-detail/order-detail.component'
import { OrderListComponent } from './resources/order/order-list/order-list.component'
import { ProjectCreateEditComponent } from './resources/project/project-create-edit/project-create-edit.component'
import { ProjectDetailComponent } from './resources/project/project-detail/project-detail.component'
import { ProjectFrameworkModalComponent } from './resources/project/project-detail/project-framework-modal/project-framework-modal.component'
import { ProjectTabAccountingComponent } from './resources/project/project-detail/project-tab-accounting/project-tab-accounting.component'
import { ProjectTabTechnicalComponent } from './resources/project/project-detail/project-tab-technical/project-tab-technical.component'
import { ProjectTabTimeSlotsComponent } from './resources/project/project-detail/project-tab-time-slots/project-tab-time-slots.component'
import { ProjectTimeSlotChartComponent } from './resources/project/project-detail/project-time-slot-chart/project-time-slot-chart.component'
import { ProjectListComponent } from './resources/project/project-list/project-list.component'
import { ReferentCreateEditComponent } from './resources/referent/referent-create-edit/referent-create-edit.component'
import { ReferentListComponent } from './resources/referent/referent-list/referent-list.component'
import { SettingCreateEditComponent } from './resources/setting/setting-create-edit/setting-create-edit.component'
import { TypeCreateEditComponent } from './resources/type/type-create-edit/type-create-edit.component'
import { TypeListComponent } from './resources/type/type-list/type-list.component'
import { UserCreateEditComponent } from './resources/user/user-create-edit.component'
import { UserCalendarComponent } from './resources/user/user-detail/user-calendar/user-calendar.component'
import { UserDetailComponent } from './resources/user/user-detail/user-detail.component'
import { UserProjectListComponent } from './resources/user/user-detail/user-project-list/user-project-list.component'
import { UserListComponent } from './resources/user/user-list.component'

registerLocaleData(localeFr, 'fr')

if (environment.enableBugsnag) {
  Bugsnag.start({
    apiKey: environment.bugsnagApiKey,
    releaseStage: environment.envName
  })
}

// create a factory which will return the Bugsnag error handler
export function errorHandlerFactory() {
  return new BugsnagErrorHandler()
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    UserListComponent,
    UserCreateEditComponent,
    CorporateGroupCreateEditComponent,
    CorporateGroupListComponent,
    CustomerCreateEditComponent,
    CustomerListComponent,
    SettingCreateEditComponent,
    UserDetailComponent,
    ReferentCreateEditComponent,
    ReferentListComponent,
    TypeCreateEditComponent,
    TypeListComponent,
    MarketCreateEditComponent,
    MarketListComponent,
    ProjectCreateEditComponent,
    ProjectListComponent,
    InvoiceCreateEditComponent,
    InvoiceListComponent,
    OrderCreateEditComponent,
    OrderListComponent,
    ProjectDetailComponent,
    MarketDetailComponent,
    OrderDetailComponent,
    StatusBarComponent,
    UserProjectListComponent,
    UserCalendarComponent,
    ProjectTimeSlotChartComponent,
    ProjectTabTechnicalComponent,
    ProjectTabAccountingComponent,
    ProjectTabTimeSlotsComponent,
    MonitoringPaymentForecast,
    MonitoringLatenessComponent,
    MonitoringProfitabilityComponent,
    MonitoringSubtractionComponent,
    MonitoringTurnoverComponent,
    MonitoringTabsComponent,
    ExpenseCreateEditComponent,
    ProjectFrameworkModalComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CommonModule,
    ReactiveFormsModule,
    NgChartjsModule,
    CaseModule.forRoot({
      baseUrl: environment.baseUrl,
      apiBaseUrl: environment.apiBaseUrl,
      storagePath: environment.storagePath,
      appName: environment.appName,
      tokenName: environment.tokenName,
      tokenAllowedDomains: environment.tokenAllowedDomains,
      production: environment.production,
      googlePlacesAPIKey: 'AIzaSyBxYJpx2C7gu0rn-dBVdYC-KxdcRzDK5OM',
      enablePersistentFilters: true
    }),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    })
  ],
  providers: [{ provide: LOCALE_ID, useValue: 'fr' }, EurosPipe],
  bootstrap: [AppComponent]
})
export class AppModule {}
