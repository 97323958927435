<section *ngIf="item">
  <nav class="level">
    <!-- Left side -->
    <div class="level-left">
      <div class="level-item">
        <span class="icon is-large">
          <i class="icon icon-user is-size-2 has-text-link"></i>
        </span>
      </div>
      <div class="level-item">
        <case-image
          class="avatar avatar-sm"
          [path]="item.imageObjects[0].image"
          [title]="item.name"
        ></case-image>
        <h1 class="title is-2 has-text-weight-light">{{ item.name }}</h1>
        <h2 class="px-4 title is-2 has-text-weight-light has-text-grey-dark">
          {{ item.role.displayName }}
        </h2>
        <span class="tag is-success-light" *ngIf="item.isActive"
          >En activité</span
        >
        <span class="tag is-dark" *ngIf="!item.isActive">Inactif</span>
      </div>
    </div>

    <!-- Right side -->
    <div class="level-right">
      <p class="level-item">
        <a
          class="button is-link px-5"
          [routerLink]="['/collaborateurs', item.id, 'edit']"
        >
          Modifier
        </a>
      </p>
    </div>
  </nav>

  <div
    class="columns is-multiline"
    *ngIf="item.role.name === 'businessEngineer' && item.objective"
  >
    <div class="column mt-4">
      <div class="card p-4">
        <h4 class="title--small">Objectif</h4>
        <p class="is-size-6">{{ item.objective }}</p>
      </div>
    </div>
  </div>

  <div class="columns is-multiline">
    <div class="column is-12 mt-4">
      <div class="panel is-link has-background-light">
        <p class="panel-tabs pt-2">
          <a
            [ngClass]="{ 'is-active': selectedTab === 'projects' }"
            class="has-tooltip has-tooltip-multiline"
            data-tooltip="Cette vue affiche uniquement les projets en cours du collaborateur. Pour voir tous les projets, rendez-vous sur l'onglet projets depuis le menu latéral puis utilisez le filtre."
            (click)="selectedTab = 'projects'"
            >Projets en cours</a
          >
          <a
            [ngClass]="{ 'is-active': selectedTab === 'calendar' }"
            (click)="selectedTab = 'calendar'"
            >Calendrier</a
          >
        </p>
        <div class="tabs-content">
          <div
            class="panel-block has-v-separation p-4 is-active"
            *ngIf="selectedTab === 'projects'"
          >
            <div class="table-container">
              <app-user-project-list [userId]="item.id"></app-user-project-list>
            </div>
          </div>
          <div
            class="panel-block has-v-separation p-4 is-active"
            *ngIf="selectedTab === 'calendar'"
          >
            <div class="is-fullwidth">
              <app-user-calendar [user]="item"></app-user-calendar>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
