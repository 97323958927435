import { Component, Input, OnInit } from '@angular/core'
import {
  ResourceDefinition,
  ResourceService,
  Yield
} from '@case-app/angular-library'
import { ProjectStatus } from '../../../../../../../shared/enums/project-status.enum'

import { Project } from '../../../../../src/resources/project/project.entity'
import { projectDefinition } from '../../../project/project.definition'
import { projectYields } from '../../../project/project.yields'

@Component({
  selector: 'app-user-project-list',
  template:
    '<case-table [items]="this.projects" [definition]="this.definition" [yields]="yields" *ngIf="this.projects"></case-table>'
})
export class UserProjectListComponent implements OnInit {
  @Input() userId: number
  projects: Project[]
  definition: ResourceDefinition = projectDefinition
  yields: Yield[] = projectYields

  constructor(private resourceService: ResourceService) {}

  ngOnInit(): void {
    this.resourceService
      .list('projects', {
        userIds: [this.userId],
        withoutPagination: true,
        statuses: [ProjectStatus.InProgress]
      })
      .then((projectRes: Project[]) => {
        this.projects = projectRes
      })
  }
}
