export const environment = {
  production: true,
  envName: 'production',
  appName: 'NEO RHEA',
  baseUrl: 'https://neorhea.fr',
  apiBaseUrl: 'https://api.neorhea.fr',
  storagePath: 'https://api.neorhea.fr/storage',
  tokenName: 'NeoRheaCaseToken',
  tokenAllowedDomains: ['api.neorhea.fr'],
  enableBugsnag: true,
  bugsnagApiKey: '9634353d93aef80bbeb274e90e045448'
}
